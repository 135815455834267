import staffAuth from "./axios/staffAuth";
import {
  LabReportDto,
  LabFoodDto,
  LabSummaryDto,
  LabReportUploadResponse,
} from "../types/lab";
import { getCommonAuth } from "./axios/commonAuth";

const API_URL = process.env.REACT_APP_API_URL || "";

export const uploadLabReport = async (
  patientId: string,
  file: File
): Promise<LabReportUploadResponse> => {
  const formData = new FormData();
  formData.append("report", file);
  return staffAuth.post(`${API_URL}/lab/patient/${patientId}/report`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getLabReports = async (
  patientId: string
): Promise<LabReportDto[]> => {
  const response = await staffAuth.get<LabReportDto[]>(
    `${API_URL}/lab/patient/${patientId}/report`
  );
  return response.data;
};

interface LabSummaryResponse {
  success: boolean;
  data?: LabSummaryDto;
  message?: string;
}

export const getLabSummary = async (
  patientId: string
): Promise<LabSummaryResponse> => {
  const response = await staffAuth.get<LabSummaryResponse>(
    `${API_URL}/lab/patient/${patientId}/summary`
  );
  return response.data;
};

export const generateLabSummary = async (
  patientId: string
): Promise<LabSummaryResponse> => {
  const axios = getCommonAuth();
  const response = await axios.post<LabSummaryResponse>(
    `${API_URL}/lab/patient/${patientId}/summary`
  );
  return response.data;
};

export const getLabFoods = async (patientId: string): Promise<LabFoodDto[]> => {
  const axios = getCommonAuth();
  const response = await axios.get<LabFoodDto[]>(
    `${API_URL}/lab/patient/${patientId}/foods`
  );
  return response.data;
};

export const deleteLabReport = async (
  patientId: string,
  reportId: string
): Promise<boolean> => {
  const response = await staffAuth.delete<boolean>(
    `${API_URL}/lab/patient/${patientId}/report/${reportId}`
  );
  return response.data;
};
