import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControl,
  HStack,
  Heading,
  IconButton,
  Input,
  Select,
  Spacer,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { createProtocol } from "../../api/protocol/organizationProtocol";
import { getAllTags } from "../../api/protocol/tag";
import { ROUTES, theme } from "../../constants";
import {
  Operation,
  Property,
  TagDto,
  OrganizationCondition,
  isOrganizationCondition,
  CreateOrUpdateOrganizationProtocolDto,
} from "../../types/protocol";
import { formatEnumValue } from "../../utils/strings";
import Header from "../../components/Head";
import {
  AiOutlineMessage,
  AiOutlineQuestionCircle,
  AiOutlineSync,
  AiOutlineTag,
} from "react-icons/ai";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { tagColorMap, TagPill } from "../../components/Tags/TagPill";
import axios from "axios";

export const CreateProtocolPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  // editing protocol name
  const [isEditingName, setIsEditingName] = useState<boolean>(false);

  // editing protocol tags
  const [allTags, setAllTags] = useState<TagDto[]>([]);
  const [tags, setTags] = useState<TagDto[]>([]);

  // Initial state for protocol details
  const [sendMessage, setSendMessage] = useState<boolean>(false);
  const [protocol, setProtocol] =
    useState<CreateOrUpdateOrganizationProtocolDto>({
      id: "",
      name: "",
      description: "",
      removeWhenNotMet: true,
      conditions: {
        type: "AND",
        conditions: [] as OrganizationCondition[],
      },
      existingTagIds: [],
      newTags: [],
    });

  // Load all tags and set them
  useEffect(() => {
    async function getTags() {
      const tagsResp = await getAllTags();
      setAllTags(tagsResp.data ?? []);
    }
    getTags();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type } = e.target;
    setProtocol((prevDetails) => ({
      ...prevDetails,
      [name]:
        type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
    }));
  };

  const addCondition = () => {
    const newCondition: OrganizationCondition = {
      property: "",
      operation: Operation.GT,
      value: "",
    };
    setProtocol((prevDetails) => ({
      ...prevDetails,
      conditions: {
        type: "AND",
        conditions: [...prevDetails.conditions.conditions, newCondition],
      },
    }));
  };

  const handleConditionChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const conditions = protocol.conditions.conditions;
    const condition = conditions[index];

    if (!isOrganizationCondition(condition)) {
      return;
    }

    const newConditions = [...conditions];
    newConditions[index] = {
      ...condition,
      [field]: value,
    };

    setProtocol((prevDetails) => ({
      ...prevDetails,
      conditions: {
        type: "AND",
        conditions: newConditions,
      },
    }));
  };

  const removeCondition = (index: number) => {
    const newConditions = [...protocol.conditions.conditions];
    newConditions.splice(index, 1);
    setProtocol((prevDetails) => ({
      ...prevDetails,
      conditions: {
        type: "AND",
        conditions: newConditions,
      },
    }));
  };

  const handleSelectTag = (tag: TagDto, index: number) => {
    setTags((prevTags) => {
      const newTags = [...prevTags];
      newTags[index] = tag;
      return newTags;
    });
  };

  const handleAddCustomTag = () => {
    setTags([...tags, { name: "", tagColor: "" }]);
  };

  const handleTagInputChange = (value: string, index: number) => {
    setTags((prevTags) => {
      const newTags = [...prevTags];
      newTags[index] = { ...newTags[index], name: value };
      return newTags;
    });
  };

  const handlePriorityChange = (value: string, index: number) => {
    setTags((prevTags) => {
      const newTags = [...prevTags];
      newTags[index] = { ...newTags[index], tagColor: value };
      return newTags;
    });
  };

  const removeTag = (index: number) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  const handleDeleteMessage = () => {
    setSendMessage(false);
    setProtocol({ ...protocol, message: undefined });
  };

  const handleAddMessage = () => {
    setSendMessage(true);
  };

  const handleSave = async () => {
    try {
      const updatedProtocol: CreateOrUpdateOrganizationProtocolDto = {
        ...protocol,
        existingTagIds: [],
        newTags: [],
      };

      // Convert tags to protocol DTO format
      tags.forEach((tag) => {
        if (tag.id != null) {
          updatedProtocol.existingTagIds.push(tag.id);
        } else {
          updatedProtocol.newTags.push(tag);
        }
      });

      await createProtocol(updatedProtocol);
      toast({
        title: "Protocol created successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate(ROUTES.PROTOCOLS);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast({
          title: "Error",
          description:
            error.response?.data?.message || "Failed to create protocol",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "An unexpected error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Container maxW={"9xl"}>
        <Header description="Create Tag" />

        <VStack align={"start"} spacing={6}>
          <Breadcrumb mb={2}>
            <BreadcrumbItem>
              <BreadcrumbLink fontWeight={"light"} href={ROUTES.PROTOCOLS}>
                Protocols
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <Text>Create New Protocol</Text>
            </BreadcrumbItem>
          </Breadcrumb>

          <HStack>
            <Box
              w="min"
              border={"1px dashed"}
              borderColor={`gray.300`}
              borderRadius={"md"}
              p={1}
            >
              <AiOutlineTag size={32} color="gray" />
            </Box>
            <Heading
              fontSize={{ base: "3xl", "2xl": "4xl" }}
              textTransform={"uppercase"}
            >
              Create new protocol
            </Heading>
          </HStack>

          <Divider />

          <VStack
            mt={16}
            spacing={4}
            align={"start"}
            alignSelf={"center"}
            w="70%"
          >
            <HStack>
              {isEditingName ? (
                <Input
                  value={protocol.name}
                  name="name"
                  onChange={handleChange}
                  onBlur={() => setIsEditingName(false)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsEditingName(false);
                    }
                  }}
                  autoFocus
                  fontSize={{ base: "3xl", "2xl": "4xl" }}
                  fontWeight="bold"
                  variant="unstyled"
                  fontFamily={"heading"}
                  minW={{ base: "xl", "2xl": "2xl" }}
                  color={`${theme}.700`}
                />
              ) : (
                <>
                  <Heading
                    fontSize={{ base: "3xl", "2xl": "4xl" }}
                    onDoubleClick={() => setIsEditingName(true)}
                    color={`${theme}.700`}
                  >
                    {protocol.name || "Name your Protocol"}
                  </Heading>
                  <FaPencilAlt
                    size={16}
                    style={{ marginLeft: "8px", cursor: "pointer" }}
                    onClick={() => setIsEditingName(true)}
                  />
                </>
              )}
            </HStack>
            <Card width="full" p={3}>
              <HStack align={"start"} justify={"start"} w="full" spacing={4}>
                <Box w="min" bg={`gray.100`} borderRadius={"md"} py={1} px={2}>
                  <AiOutlineSync size={24} />
                </Box>
                <VStack align={"start"} w="full" spacing={3}>
                  <HStack w="full" spacing={3}>
                    <Text
                      fontSize={{ base: "md", "2xl": "lg" }}
                      color={`${theme}.800`}
                      fontWeight={"medium"}
                    >
                      Add protocol with description:
                    </Text>
                    <Input
                      placeholder="Protocol Description"
                      name="description"
                      size={{ base: "sm", "2xl": "md" }}
                      fontSize={{ base: "md", "2xl": "lg" }}
                      color={`${theme}.800`}
                      w={"65%"}
                      onChange={handleChange}
                    />
                  </HStack>
                </VStack>
              </HStack>
            </Card>
            {tags.length > 0 &&
              tags.map((tag, index) => (
                <Card width="full" p={3}>
                  <HStack
                    align={"start"}
                    justify={"start"}
                    w="full"
                    spacing={4}
                  >
                    <Box
                      w="min"
                      bg={`gray.100`}
                      borderRadius={"md"}
                      py={1}
                      px={2}
                    >
                      <AiOutlineTag size={24} />
                    </Box>
                    <VStack align={"start"} w="full" spacing={3}>
                      <HStack w="full" spacing={3}>
                        <Text
                          fontSize={{ base: "md", "2xl": "lg" }}
                          color={`${theme}.800`}
                          fontWeight={"medium"}
                        >
                          Add tag(s) to client:
                        </Text>
                        {tag.id ? (
                          <TagPill tagColor={tag.tagColor} name={tag.name} />
                        ) : (
                          <>
                            <FormControl w="30%" isRequired>
                              <AutoComplete openOnFocus freeSolo>
                                <AutoCompleteInput
                                  placeholder="Find or add tag"
                                  fontSize={{ base: "md", "2xl": "lg" }}
                                  size={{ base: "sm", "2xl": "md" }}
                                  color={`${theme}.800`}
                                  onChange={(e) =>
                                    handleTagInputChange(e.target.value, index)
                                  }
                                />
                                <AutoCompleteList>
                                  {allTags.length > 0 &&
                                    allTags.map((tag, idx) => (
                                      <AutoCompleteItem
                                        key={`option-${idx}`}
                                        value={tag.id}
                                        label={tag.name}
                                        onClick={() =>
                                          handleSelectTag(
                                            {
                                              id: tag.id!,
                                              name: tag.name,
                                              tagColor: tag.tagColor,
                                            },
                                            index
                                          )
                                        }
                                      >
                                        <TagPill
                                          tagColor={tag.tagColor}
                                          name={tag.name}
                                        />
                                      </AutoCompleteItem>
                                    ))}
                                </AutoCompleteList>
                              </AutoComplete>
                            </FormControl>
                            <Text
                              fontSize={{ base: "md", "2xl": "lg" }}
                              color={`${theme}.800`}
                              fontWeight={"medium"}
                            >
                              with
                            </Text>
                            <FormControl w="20%" isRequired>
                              <Select
                                placeholder="Priority"
                                size={{ base: "sm", "2xl": "md" }}
                                fontSize={{ base: "md", "2xl": "lg" }}
                                color={`${theme}.800`}
                                onChange={(e) =>
                                  handlePriorityChange(e.target.value, index)
                                }
                              >
                                {Object.entries(tagColorMap).map(
                                  ([key, value]) => (
                                    <option key={key} value={key}>
                                      {value.label}
                                    </option>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </>
                        )}
                        <IconButton
                          colorScheme="gray"
                          onClick={() => removeTag(index)}
                          icon={<FaTrash />}
                          aria-label={"delete"}
                          isDisabled={
                            tags.length <= 1 && protocol.message == null
                          }
                        />
                      </HStack>
                    </VStack>
                  </HStack>
                </Card>
              ))}
            <Button
              onClick={handleAddCustomTag}
              leftIcon={<FaPlus />}
              variant={"ghost"}
              color={`${theme}.700`}
              size={{ base: "md", "2xl": "lg" }}
            >
              Add Tags
            </Button>

            {sendMessage ? (
              <Card w="full" p={3}>
                <VStack alignItems={"flex-start"} w="full">
                  <HStack mb={3} w="full">
                    <Box
                      w="min"
                      bg={`gray.100`}
                      borderRadius={"md"}
                      py={1}
                      px={2}
                    >
                      <AiOutlineMessage size={24} />
                    </Box>
                    <Text
                      fontSize={{ base: "md", "2xl": "lg" }}
                      color={`${theme}.800`}
                      fontWeight={"medium"}
                    >
                      Send message
                    </Text>
                    <Spacer />
                    <IconButton
                      colorScheme="gray"
                      onClick={handleDeleteMessage}
                      aria-label="deleteMessage"
                      icon={<FaTrash />}
                      isDisabled={tags.length == 0}
                    />
                  </HStack>
                  <Textarea
                    placeholder="Enter message..."
                    mb={3}
                    fontSize={{ base: "md", "2xl": "lg" }}
                    fontWeight="normal"
                    color={`${theme}.700`}
                    value={protocol.message}
                    rows={4}
                    resize="vertical"
                    onChange={(e) =>
                      setProtocol({ ...protocol, message: e.target.value })
                    }
                  />
                </VStack>
              </Card>
            ) : (
              <Button
                onClick={handleAddMessage}
                leftIcon={<FaPlus />}
                variant={"ghost"}
                color={`${theme}.700`}
                size={{ base: "md", "2xl": "lg" }}
              >
                Add Message
              </Button>
            )}

            <Text
              fontSize={{ base: "lg", "2xl": "xl" }}
              color={`${theme}.700`}
              fontWeight={"medium"}
            >
              when
            </Text>

            {/* Dynamic conditions */}
            {protocol.conditions.conditions.map((condition, index) => (
              <Card width="full" p={3} key={index}>
                <HStack>
                  {index === 0 ? (
                    <Box
                      w="min"
                      bg={`gray.100`}
                      borderRadius={"md"}
                      py={1}
                      px={2}
                    >
                      <AiOutlineQuestionCircle size={24} />
                    </Box>
                  ) : (
                    <Text w={200}>And</Text>
                  )}
                  {isOrganizationCondition(condition) && (
                    <>
                      <Select
                        placeholder="Property"
                        required
                        value={condition.property}
                        onChange={(e) =>
                          handleConditionChange(
                            index,
                            "property",
                            e.target.value
                          )
                        }
                        size={{ base: "sm", "2xl": "md" }}
                        fontSize={{ base: "md", "2xl": "lg" }}
                        color={`${theme}.800`}
                      >
                        {Object.keys(Property)
                          .sort()
                          .map((key: string) => (
                            <option
                              key={key}
                              value={Property[key as keyof typeof Property]}
                            >
                              {formatEnumValue(
                                Property[key as keyof typeof Property]
                              )}
                            </option>
                          ))}
                      </Select>

                      <Select
                        required
                        value={condition.operation}
                        onChange={(e) =>
                          handleConditionChange(
                            index,
                            "operation",
                            e.target.value
                          )
                        }
                        size={{ base: "sm", "2xl": "md" }}
                        fontSize={{ base: "md", "2xl": "lg" }}
                        color={`${theme}.800`}
                      >
                        <option value={Operation.LT}>Is Less Than</option>
                        <option value={Operation.LTE}>
                          Is Less Than or Equal To
                        </option>
                        <option value={Operation.EQ}>Is Equal To</option>
                        <option value={Operation.GTE}>
                          Is Greater Than or Equal To
                        </option>
                        <option value={Operation.GT}>Is Greater Than</option>
                      </Select>

                      <Input
                        required
                        placeholder="Value"
                        value={condition.value}
                        onChange={(e) =>
                          handleConditionChange(index, "value", e.target.value)
                        }
                        size={{ base: "sm", "2xl": "md" }}
                        fontSize={{ base: "md", "2xl": "lg" }}
                        color={`${theme}.800`}
                      />
                    </>
                  )}

                  <IconButton
                    colorScheme="gray"
                    onClick={() => removeCondition(index)}
                    icon={<FaTrash />}
                    aria-label={"delete"}
                    isDisabled={index === 0}
                  />
                </HStack>
              </Card>
            ))}

            <Button
              onClick={addCondition}
              leftIcon={<FaPlus />}
              variant={"ghost"}
              color={`${theme}.700`}
              size={{ base: "md", "2xl": "lg" }}
            >
              Add Condition
            </Button>

            <Checkbox
              isChecked={true}
              name="removeWhenNotMet"
              onChange={handleChange}
              size={{ base: "md", "2xl": "lg" }}
              color={`${theme}.700`}
              isDisabled={true}
            >
              Remove tag when conditions are no longer met
            </Checkbox>

            <Button
              onClick={handleSave}
              mt={4}
              type="submit"
              w="full"
              size={{ base: "md", "2xl": "lg" }}
            >
              Save
            </Button>
          </VStack>
        </VStack>
      </Container>
    </>
  );
};
