import staffAuth from "../axios/staffAuth";
import { ProtocolActivityDto } from "../../types/protocol";
import { ApiResponse } from "../../types/api";

const API_URL = process.env.REACT_APP_API_URL || "";

// Fetches all protocol activity by protocol ID
export const getProtocolActivitiesByProtocolId = async (
  id: string
): Promise<ApiResponse<ProtocolActivityDto[]>> => {
  return staffAuth.get(`${API_URL}/protocol/activity/${id}`);
};

// Fetches all protocol activity by patient ID
export const getProtocolActivitiesByPatientId = async (
  id: string
): Promise<ApiResponse<ProtocolActivityDto[]>> => {
  return staffAuth.get(`${API_URL}/protocol/activity/patient/${id}`);
};
