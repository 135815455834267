import {
  Box,
  HStack,
  Input,
  Switch,
  Text,
  VStack,
  Button,
  FormControl,
} from "@chakra-ui/react";
import { useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PatientMetricConfig } from "../../../types/patientMetricConfig";
import { MetricDefinition } from "../../../types/metricDefinition";

interface ConfigurationSectionProps {
  metricDefinition: MetricDefinition;
  config?: PatientMetricConfig;
  patientId: string;
  onUpdateConfig: (config: PatientMetricConfig) => void;
  onCreateConfig: (
    metricDefinitionId: string,
    config: Omit<
      PatientMetricConfig,
      | "id"
      | "metricDefinitionId"
      | "createdBy"
      | "updatedBy"
      | "metricDisplayName"
    >
  ) => void;
}

interface FormValues {
  isActive: boolean;
  normalMin: string;
  normalMax: string;
  nonAdherenceDays: string;
}

export const ConfigurationSection = ({
  metricDefinition,
  config,
  patientId,
  onUpdateConfig,
  onCreateConfig,
}: ConfigurationSectionProps) => {
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      isActive: Yup.boolean(),
      normalMin: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("isActive", {
          is: true,
          then: (schema) =>
            schema
              .required("Required")
              .min(
                metricDefinition.validationRules.min ?? 0,
                `Must be at least ${metricDefinition.validationRules.min}`
              )
              .max(
                metricDefinition.validationRules.max ?? Infinity,
                `Must be at most ${metricDefinition.validationRules.max}`
              )
              .test(
                "less-than-max",
                "Must be less than maximum value",
                function (value) {
                  const { normalMax } = this.parent;
                  if (!value || !normalMax) return true;
                  return Number(value) < Number(normalMax);
                }
              ),
        }),
      normalMax: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("isActive", {
          is: true,
          then: (schema) =>
            schema
              .required("Required")
              .min(
                metricDefinition.validationRules.min ?? 0,
                `Must be at least ${metricDefinition.validationRules.min}`
              )
              .max(
                metricDefinition.validationRules.max ?? Infinity,
                `Must be at most ${metricDefinition.validationRules.max}`
              )
              .test(
                "greater-than-min",
                "Must be greater than minimum value",
                function (value) {
                  const { normalMin } = this.parent;
                  if (!value || !normalMin) return true;
                  return Number(value) > Number(normalMin);
                }
              ),
        }),
      nonAdherenceDays: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("isActive", {
          is: true,
          then: (schema) =>
            schema
              .required("Required")
              .min(1, "Must be at least 1")
              .integer("Must be a whole number"),
        }),
    });
  }, [metricDefinition]);

  const formik = useFormik<FormValues>({
    initialValues: {
      isActive: config?.isActive ?? false,
      normalMin: config?.customValues?.normalMin?.toString() || "",
      normalMax: config?.customValues?.normalMax?.toString() || "",
      nonAdherenceDays: config?.nonAdherenceDays?.toString() || "",
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values) => {
      const configData = {
        isActive: values.isActive,
        customValues: {
          normalMin: Number(values.normalMin),
          normalMax: Number(values.normalMax),
        },
        nonAdherenceDays: Number(values.nonAdherenceDays),
      };

      if (config) {
        onUpdateConfig({
          ...config,
          ...configData,
        });
      } else {
        onCreateConfig(metricDefinition.id, {
          ...configData,
          patientId,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
    },
  });

  // Reset form when config changes
  useEffect(() => {
    formik.resetForm({
      values: {
        isActive: config?.isActive ?? false,
        normalMin: config?.customValues?.normalMin?.toString() || "",
        normalMax: config?.customValues?.normalMax?.toString() || "",
        nonAdherenceDays: config?.nonAdherenceDays?.toString() || "",
      },
    });
  }, [config]);

  return (
    <Box as="form" onSubmit={formik.handleSubmit}>
      <VStack spacing={4} align="stretch">
        <HStack justify="space-between">
          <VStack spacing={1} align="flex-start">
            <Text fontSize="lg" fontWeight="medium" color="blue.900">
              {metricDefinition.displayName}
            </Text>

            <HStack align="center">
              <Text
                color={formik.values.isActive ? "green.500" : "gray.500"}
                fontSize="xs"
              >
                {`You have ${
                  formik.values.isActive ? "activated" : "deactivated"
                } monitoring for your client to enter readings`}
              </Text>
              <Switch
                name="isActive"
                isChecked={formik.values.isActive}
                onChange={formik.handleChange}
                colorScheme="green"
              />
            </HStack>
          </VStack>
          <Button
            type="submit"
            size="sm"
            isDisabled={!formik.dirty || !formik.isValid}
            bg={formik.dirty && formik.isValid ? "blue.600" : "gray.400"}
            color="white"
            _hover={{
              bg: formik.dirty && formik.isValid ? "blue.700" : "gray.400",
            }}
            _disabled={{
              bg: "gray.400",
              cursor: "not-allowed",
            }}
          >
            {config ? "Save Changes" : "Add Config"}
          </Button>
        </HStack>

        <VStack spacing={1} align="stretch">
          <HStack spacing={2} align="center">
            <Text
              color="blue.900"
              fontWeight="medium"
              minW="120px"
              fontSize="sm"
            >
              Target Range:
            </Text>
            <HStack spacing={2} align="center">
              <FormControl
                isInvalid={
                  !!formik.errors.normalMin && formik.touched.normalMin
                }
              >
                <Input
                  name="normalMin"
                  type="number"
                  value={formik.values.normalMin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={metricDefinition.defaultValues.normalMin.toString()}
                  size="sm"
                  width="100px"
                  borderColor={
                    formik.errors.normalMin && formik.touched.normalMin
                      ? "red.500"
                      : "inherit"
                  }
                />
              </FormControl>
              <Text>-</Text>
              <FormControl
                isInvalid={
                  !!formik.errors.normalMax && formik.touched.normalMax
                }
              >
                <Input
                  name="normalMax"
                  type="number"
                  value={formik.values.normalMax}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={metricDefinition.defaultValues.normalMax.toString()}
                  size="sm"
                  width="100px"
                  borderColor={
                    formik.errors.normalMax && formik.touched.normalMax
                      ? "red.500"
                      : "inherit"
                  }
                />
              </FormControl>
              <Text fontSize="sm">{metricDefinition.unit}</Text>
            </HStack>
          </HStack>
          {(formik.errors.normalMin && formik.touched.normalMin) ||
          (formik.errors.normalMax && formik.touched.normalMax) ? (
            <Text color="red.500" fontSize="xs" pl="120px">
              {formik.errors.normalMin || formik.errors.normalMax}
            </Text>
          ) : null}
        </VStack>

        <VStack spacing={1} align="stretch">
          <HStack spacing={2} align="center">
            <Text
              color="blue.900"
              fontWeight="medium"
              minW="120px"
              fontSize="sm"
            >
              Frequency:
            </Text>
            <HStack spacing={2} align="center">
              <Text fontSize="sm">Every</Text>
              <FormControl
                isInvalid={
                  !!formik.errors.nonAdherenceDays &&
                  formik.touched.nonAdherenceDays
                }
              >
                <Input
                  name="nonAdherenceDays"
                  type="number"
                  value={formik.values.nonAdherenceDays}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  size="sm"
                  width="100px"
                  borderColor={
                    formik.errors.nonAdherenceDays &&
                    formik.touched.nonAdherenceDays
                      ? "red.500"
                      : "inherit"
                  }
                />
              </FormControl>
              <Text fontSize="sm">Day(s)</Text>
            </HStack>
          </HStack>
          {formik.errors.nonAdherenceDays && formik.touched.nonAdherenceDays ? (
            <Text color="red.500" fontSize="xs" pl="120px">
              {formik.errors.nonAdherenceDays}
            </Text>
          ) : null}
        </VStack>
      </VStack>
    </Box>
  );
};
