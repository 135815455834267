import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Center, Spinner } from "@chakra-ui/react";
import { ROUTES } from "../../constants";
import { checkClientAccess } from "../../api/auth";

interface ClientAccessWrapperProps {
  children: React.ReactNode;
}

/**
 * This component wraps all client routes (including auth routes) and checks
 * if the client has access based on their payment tier before rendering any content.
 */
const ClientAccessWrapper: React.FC<ClientAccessWrapperProps> = ({
  children,
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAccess = async () => {
      if (!clientId) {
        navigate(ROUTES.ACCESS_UNAVAILABLE);
        return;
      }

      try {
        setIsLoading(true);
        const response = await checkClientAccess(clientId);

        if (!response.hasAccess) {
          navigate(ROUTES.ACCESS_UNAVAILABLE);
        }
      } catch (error) {
        navigate(ROUTES.ACCESS_UNAVAILABLE);
      } finally {
        setIsLoading(false);
      }
    };

    verifyAccess();
  }, [clientId, navigate]);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Center>
    );
  }

  return <>{children}</>;
};

export default ClientAccessWrapper;
