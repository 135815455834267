import staffAuth from "./axios/staffAuth";
import { getCommonAuth } from "./axios/commonAuth";
import { MetricDefinition } from "../types/metricDefinition";
import { ApiResponse } from "../types/api";

const API_URL = process.env.REACT_APP_API_URL || "";

export const getMetricDefinitions = async (): Promise<
  ApiResponse<MetricDefinition[]>
> => {
  const axios = getCommonAuth();
  return axios.get(`${API_URL}/metric-definition/all`);
};

export const getMetricDefinitionById = async (
  id: string
): Promise<ApiResponse<MetricDefinition>> => {
  const axios = getCommonAuth();
  return axios.get(`${API_URL}/metric-definition/${id}`);
};

export const createMetricDefinition = async (
  definition: Omit<MetricDefinition, "id" | "createdAt" | "updatedAt">
): Promise<ApiResponse<MetricDefinition>> => {
  return staffAuth.post(`${API_URL}/metric-definition`, definition);
};

export const updateMetricDefinition = async (
  id: string,
  updatedDefinition: MetricDefinition
): Promise<ApiResponse<MetricDefinition>> => {
  return staffAuth.put(`${API_URL}/metric-definition/${id}`, updatedDefinition);
};

// soft-delete
export const deleteMetricDefinition = async (
  id: string
): Promise<ApiResponse<MetricDefinition>> => {
  return staffAuth.delete(`${API_URL}/metric-definition/${id}`);
};
