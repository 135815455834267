import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { StaffProtectedRoute } from "./components/ProtectedRoute/staff";
import { ClientProtectedRoute } from "./components/ProtectedRoute/client";
import ScrollToTop from "./components/ScrollToTop";
import { ROUTES } from "./constants";
import CreateFormPage from "./pages/CreateFormPage";
import FormPage from "./pages/FormPage";
import ForgotPasswordPage from "./pages/ForgotPassword";
import Form from "./pages/Form";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import ResetPasswordPage from "./pages/PasswordResetPage";
import PatientResponseView from "./pages/PatientResponseView";
import Patients from "./pages/Patients";
import ViewPatientInfoPage from "./pages/ViewPatientInfo";
import AllProtocolsPage from "./pages/AllProtocolsPage";
import ClientLogin from "./pages/ClientLogin";
import ClientRegister from "./pages/ClientRegister";
import ClientResetPasscode from "./pages/ClientResetPasscode";
import { CreateProtocolPage } from "./pages/CreateProtocolPage";
import { ViewProtocolPage } from "./pages/ViewProtocolPage";
import ViewFormPage from "./pages/ViewFormPage";
import ClientPass from "./pages/ClientPass";
import ViewMetricData from "./pages/ViewMetricData";
import MetricForm from "./pages/MetricForm";
import AdminPage from "./pages/Admin";
import Labs from "./pages/Labs";
import LabsPatientView from "./pages/LabsPatientView";
import FoodHistory from "./pages/LabsPatientView/FoodHistory";
import NutritionalTrends from "./pages/LabsPatientView/NutritionalTrends";
import { AdminProtectedRoute } from "./components/ProtectedRoute/admin";
import { Box } from "@chakra-ui/react";
import SandboxBanner, {
  SANDBOX_BANNER_HEIGHT,
} from "./components/SandboxBanner";
import FlagsPage from "./pages/Flags";
import ChatsPage from "./pages/Chats";
import { IS_SANDBOX } from "./utils/constants";
import ClientLayout from "./components/ClientLayout";
import ClientForms from "./pages/ClientForms";
import ClientFormHistory from "./pages/ClientFormHistory";
import ClientFormResponseView from "./pages/ClientFormResponseView";
import ClientMetricDetail from "./pages/ClientMetricDetail";
import SettingsPage from "./pages/Settings";
import ClientHome from "./pages/ClientHome";
import ThankYouPage from "./pages/ThankYouPage";
import ClientViewAllMetricData from "./pages/ClientViewAllMetricData";
import ClientViewMetrics from "./pages/ClientViewMetrics";
import ClientRecordMetrics from "./pages/ClientRecordMetrics";
import ClientNutrition from "./pages/ClientNutrition";
import AccessUnavailable from "./pages/AccessUnavailable";
import ClientAccessWrapper from "./components/ClientAccessWrapper";
import { useFlagsmithFeatures } from "./hooks/useFlagsmithFeatures";
import { FeatureToggle, StaffFeatureToggle } from "./components/FeatureToggle";
import { FeatureName } from "./components/FeatureToggle/constants";
import TerraAuthSuccess from "./pages/TerraAuthSuccess";
import TerraAuthError from "./pages/TerraAuthError";

export const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate(ROUTES.CLIENTS);
    }
  }, [navigate]);

  useFlagsmithFeatures();

  return (
    <Box position="relative">
      <SandboxBanner />
      <Box pt={IS_SANDBOX ? { base: "24px", md: SANDBOX_BANNER_HEIGHT } : 0}>
        <ScrollToTop>
          <Routes>
            <Route path={ROUTES.LOGIN} element={<Login />} />
            {/* <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} /> */}
            <Route
              path={ROUTES.FORGOT_PASSWORD}
              element={<ForgotPasswordPage />}
            />
            <Route
              path={ROUTES.RESET_PASSWORD}
              element={<ResetPasswordPage />}
            />
            <Route
              path={ROUTES.ACCESS_UNAVAILABLE}
              element={<AccessUnavailable />}
            />

            {/* Client auth routes */}
            <Route
              path={`${ROUTES.CLIENTS}/:clientId${ROUTES.LOGIN}`}
              element={
                <ClientAccessWrapper>
                  <ClientLogin />
                </ClientAccessWrapper>
              }
            />
            <Route
              path={`${ROUTES.CLIENTS}/:clientId${ROUTES.REGISTER}`}
              element={
                <ClientAccessWrapper>
                  <ClientRegister />
                </ClientAccessWrapper>
              }
            />
            <Route
              path={`${ROUTES.CLIENTS}/:clientId${ROUTES.RESET_PASSCODE}`}
              element={
                <ClientAccessWrapper>
                  <ClientResetPasscode />
                </ClientAccessWrapper>
              }
            />

            {/* Client protected routes */}
            <Route
              element={
                <ClientAccessWrapper>
                  <ClientLayout />
                </ClientAccessWrapper>
              }
            >
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.CLIENT_PASS}`}
                element={
                  <ClientProtectedRoute>
                    <ClientPass />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.VIEW_METRIC_DATA}`}
                element={
                  <ClientProtectedRoute>
                    <ViewMetricData />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.CLIENT_HOME}`}
                element={
                  <ClientProtectedRoute>
                    <ClientHome />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.CLIENT_VIEW_METRICS}/:groupKey`}
                element={
                  <ClientProtectedRoute>
                    <ClientViewMetrics />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.CLIENT_RECORD_METRICS}/:groupKey`}
                element={
                  <ClientProtectedRoute>
                    <ClientRecordMetrics />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.CLIENT_THANK_YOU}`}
                element={
                  <ClientProtectedRoute>
                    <ThankYouPage />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.CLIENT_FORMS}`}
                element={
                  <ClientProtectedRoute>
                    <ClientForms />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.CLIENT_VIEW_ALL_METRIC_DATA}`}
                element={
                  <ClientProtectedRoute>
                    <ClientViewAllMetricData />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.CLIENT_NUTRITION}`}
                element={
                  <ClientProtectedRoute>
                    <ClientNutrition />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.FORM_HISTORY}`}
                element={
                  <ClientProtectedRoute>
                    <ClientFormHistory />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.TERRA_AUTH_SUCCESS}`}
                element={
                  <ClientProtectedRoute>
                    <FeatureToggle name={FeatureName.WEARABLES}>
                      <TerraAuthSuccess />
                    </FeatureToggle>
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.TERRA_AUTH_ERROR}`}
                element={
                  <ClientProtectedRoute>
                    <FeatureToggle name={FeatureName.WEARABLES}>
                      <TerraAuthError />
                    </FeatureToggle>
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.FORM_RESPONSE}/:submissionId/view`}
                element={
                  <ClientProtectedRoute>
                    <ClientFormResponseView />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.CLIENT_METRIC_DETAIL}/:metricId`}
                element={
                  <ClientProtectedRoute>
                    <ClientMetricDetail />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.METRIC_FORM}/:metricId/:clientId`}
                element={
                  <ClientProtectedRoute>
                    <MetricForm />
                  </ClientProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.FORM}/:formId/:clientId`}
                element={
                  <ClientProtectedRoute>
                    <Form />
                  </ClientProtectedRoute>
                }
              />
            </Route>

            <Route element={<Layout />}>
              <Route
                path={ROUTES.CLIENTS}
                element={
                  <StaffProtectedRoute>
                    <Patients />
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={ROUTES.FORMS_LIST}
                element={
                  <StaffProtectedRoute>
                    <FormPage />
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={ROUTES.CREATE_FORM}
                element={
                  <StaffProtectedRoute>
                    <CreateFormPage />
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.FORMS_LIST}/:flowId`}
                element={
                  <StaffProtectedRoute>
                    <ViewFormPage />
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.CLIENTS}/:clientId`}
                element={
                  <StaffProtectedRoute>
                    <ViewPatientInfoPage />
                  </StaffProtectedRoute>
                }
              />

              <Route
                path={`${ROUTES.CLIENTS}/:clientId${ROUTES.FORM_RESPONSE}/:flowId`}
                element={
                  <StaffProtectedRoute>
                    <PatientResponseView isAdminView />
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.PROTOCOLS}`}
                element={
                  <StaffProtectedRoute>
                    <AllProtocolsPage />
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.PROTOCOLS}/:protocolId`}
                element={
                  <StaffProtectedRoute>
                    <ViewProtocolPage />
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.PROTOCOLS}/new`}
                element={
                  <StaffProtectedRoute>
                    <CreateProtocolPage />
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={ROUTES.ADMIN}
                element={
                  <StaffProtectedRoute>
                    <AdminProtectedRoute>
                      <AdminPage />
                    </AdminProtectedRoute>
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={ROUTES.SETTINGS}
                element={
                  <StaffProtectedRoute>
                    <SettingsPage />
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={ROUTES.FLAGS}
                element={
                  <StaffProtectedRoute>
                    <FlagsPage />
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={ROUTES.LABS}
                element={
                  <StaffProtectedRoute>
                    <StaffFeatureToggle name={FeatureName.LABS}>
                      <Labs />
                    </StaffFeatureToggle>
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.LABS}/:clientId`}
                element={
                  <StaffProtectedRoute>
                    <StaffFeatureToggle name={FeatureName.LABS}>
                      <LabsPatientView />
                    </StaffFeatureToggle>
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.LABS}/:clientId/food-history`}
                element={
                  <StaffProtectedRoute>
                    <StaffFeatureToggle name={FeatureName.LABS}>
                      <FoodHistory />
                    </StaffFeatureToggle>
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.LABS}/:clientId/nutritional-trends`}
                element={
                  <StaffProtectedRoute>
                    <StaffFeatureToggle name={FeatureName.LABS}>
                      <NutritionalTrends />
                    </StaffFeatureToggle>
                  </StaffProtectedRoute>
                }
              />
              <Route
                path={ROUTES.CHATS}
                element={
                  <StaffProtectedRoute>
                    <ChatsPage />
                  </StaffProtectedRoute>
                }
              />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ScrollToTop>
      </Box>
    </Box>
  );
};
