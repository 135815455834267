import { PatientDto } from "./patient";
import { FlagSeverity } from "./flag";

export interface ProtocolQueryParams {
  search?: string;
}

export interface TagDto {
  id?: string;
  name: string;
  tagColor: string;
}

// Base types for conditions
export enum Operation {
  LT = "LT",
  LTE = "LTE",
  EQ = "EQ",
  GTE = "GTE",
  GT = "GT",
}

export enum Mode {
  ABOVE_NORMAL = "ABOVE_NORMAL",
  BELOW_NORMAL = "BELOW_NORMAL",
  NON_ADHERENCE = "NON_ADHERENCE",
}

// Organization-specific condition types
export interface OrganizationCondition {
  property: string;
  operation: Operation;
  value: string;
}

export interface OrganizationConditionGroup {
  type: "AND" | "OR";
  conditions: (OrganizationCondition | OrganizationConditionGroup)[];
}

// Patient-specific condition types
export interface PatientCondition {
  metricDefinitionId: string;
  mode: Mode;
}

export interface PatientConditionGroup {
  type: "AND" | "OR";
  conditions: (PatientCondition | PatientConditionGroup)[];
}

// Type guards for runtime type checking
export function isPatientCondition(
  condition: any
): condition is PatientCondition {
  return (
    condition &&
    typeof condition === "object" &&
    "metricDefinitionId" in condition &&
    typeof condition.metricDefinitionId === "string" &&
    "mode" in condition &&
    Object.values(Mode).includes(condition.mode)
  );
}

export function isPatientConditionGroup(
  group: any
): group is PatientConditionGroup {
  if (
    !group ||
    typeof group !== "object" ||
    !("type" in group) ||
    !("conditions" in group)
  ) {
    return false;
  }

  if (!["AND", "OR"].includes(group.type) || !Array.isArray(group.conditions)) {
    return false;
  }

  return group.conditions.every((condition: any) => {
    if ("type" in condition) {
      return isPatientConditionGroup(condition);
    }
    return isPatientCondition(condition);
  });
}

export function isOrganizationCondition(
  condition: any
): condition is OrganizationCondition {
  return (
    condition &&
    typeof condition === "object" &&
    "property" in condition &&
    typeof condition.property === "string" &&
    "operation" in condition &&
    Object.values(Operation).includes(condition.operation) &&
    "value" in condition &&
    typeof condition.value === "string"
  );
}

export function isOrganizationConditionGroup(
  group: any
): group is OrganizationConditionGroup {
  if (
    !group ||
    typeof group !== "object" ||
    !("type" in group) ||
    !("conditions" in group)
  ) {
    return false;
  }

  if (!["AND", "OR"].includes(group.type) || !Array.isArray(group.conditions)) {
    return false;
  }

  return group.conditions.every((condition: any) => {
    if ("type" in condition) {
      return isOrganizationConditionGroup(condition);
    }
    return isOrganizationCondition(condition);
  });
}

// Base Protocol interface with common fields
export interface ProtocolDto {
  id: string;
  name: string;
  description: string;
  removeWhenNotMet: boolean;
  createdBy?: string;
  updatedBy?: string;
  conditions: OrganizationConditionGroup | PatientConditionGroup;
  message?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  deletedBy?: string;
  tenantId?: string;
  organizationId?: string;
  patientId?: string;
  patient?: PatientDto;
  flagSeverity?: FlagSeverity;
}

export interface CreateOrUpdateProtocolDto extends ProtocolDto {
  existingTagIds: string[];
  newTags: TagDto[];
}

export interface GetProtocolDto extends ProtocolDto {
  tags: TagDto[];
}

export interface CustomPatientTagDto extends TagDto {
  assigned: Date;
}

// For backward compatibility
export type ConditionDto = OrganizationCondition;
export type ConditionGroupDto = OrganizationConditionGroup;

export interface PatientTagDto {
  tagId: string;
  patientId: string;
  ruleId?: string;
  createdAt: Date;
  updatedAt: Date;
  patient?: PatientDto;
}

export enum ProtocolStatus {
  ADDED_TAG = "added_tag",
  REMOVED_TAG = "removed_tag",
  SENT_MESSAGE = "sent_message",
}

export interface ProtocolActivityDto {
  createdAt: Date;
  protocol: {
    name: string;
    id: string;
  };
  client: {
    name: string;
    id: string;
  };
  tag?: {
    name: string;
    color: string;
  };
  status: ProtocolStatus;
}

export enum Property {
  PATIENT_AGE = "PATIENT_AGE",
  BLOOD_GLUCOSE_FASTING = "BLOOD_GLUCOSE_FASTING",
  BLOOD_GLUCOSE_RANDOM = "BLOOD_GLUCOSE_RANDOM",
  BLOOD_PRESSURE_SYSTOLIC = "BLOOD_PRESSURE_SYSTOLIC",
  BLOOD_PRESSURE_DIASTOLIC = "BLOOD_PRESSURE_DIASTOLIC",
  HEIGHT = "HEIGHT",
  WEIGHT = "WEIGHT",

  // Lipid Profile properties
  TOTAL_CHOLESTEROL = "TOTAL_CHOLESTEROL",
  HDL_CHOLESTEROL = "HDL_CHOLESTEROL",
  LDL_CHOLESTEROL = "LDL_CHOLESTEROL",
  CHOLESTEROL_HDL_RATIO = "CHOLESTEROL_HDL_RATIO",
  TRIGLYCERIDES = "TRIGLYCERIDES",
  TOTAL_PROTEIN = "TOTAL_PROTEIN",

  // Liver Profile properties
  ALBUMIN = "ALBUMIN",
  GLOBULIN = "GLOBULIN",
  AG_RATIO = "AG_RATIO",
  BILIRUBIN_TOTAL = "BILIRUBIN_TOTAL",
  ALKALINE_PHOSPHATASE = "ALKALINE_PHOSPHATASE",
  SGPT_ALT = "SGPT_ALT",
  SGOT_AST = "SGOT_AST",
  GAMMA_GT = "GAMMA_GT",

  EXERCISE_MINUTES_PER_DAY = "EXERCISE_MINUTES_PER_DAY",
  BMI = "BMI",
  HEART_RATE = "HEART_RATE",
  RESPIRATORY_RATE = "RESPIRATORY_RATE",
  SPO2 = "SPO2",
  TEMPERATURE = "TEMPERATURE",
}

// Organization-specific protocol types
export interface OrganizationProtocolDto
  extends Omit<ProtocolDto, "conditions"> {
  conditions: OrganizationConditionGroup;
}

export interface CreateOrUpdateOrganizationProtocolDto
  extends Omit<CreateOrUpdateProtocolDto, "conditions"> {
  conditions: OrganizationConditionGroup;
}

export interface GetOrganizationProtocolDto
  extends Omit<GetProtocolDto, "conditions"> {
  conditions: OrganizationConditionGroup;
}

// Patient-specific protocol types
export interface CreatePatientProtocolDto {
  name: string;
  description?: string;
  conditions: PatientConditionGroup;
  flagSeverity: FlagSeverity;
  patientId: string; // Required for create
  organizationId?: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface UpdatePatientProtocolDto
  extends Omit<
    CreatePatientProtocolDto,
    "organizationId" | "patientId" | "createdBy"
  > {
  id: string;
}

export interface GetPatientProtocolDto extends Omit<ProtocolDto, "conditions"> {
  conditions: PatientConditionGroup;
  flagSeverity: FlagSeverity;
  patientId: string;
  disabledReason?: string;
}
