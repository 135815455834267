import { FC } from "react";
import {
  Center,
  VStack,
  Heading,
  Text,
  Button,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants";

const ThankYouPage: FC = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const location = useLocation();
  const returnPath = location.state?.returnPath || -1;

  const bgColor = useColorModeValue("gray.50", "gray.900");

  return (
    <Center minH="100vh" bg={bgColor} w="full">
      <VStack spacing={6} mx={5} maxW="md" textAlign="center" py={8}>
        <Heading as="h1" size="lg">
          Complete!
        </Heading>
        <Text>One step goes a long way!</Text>

        <Image src="/thank_you.png" alt="Thank you" width="300" />

        <Button
          variant="outline"
          width="full"
          onClick={() => {
            navigate(returnPath);
          }}
        >
          Record More
        </Button>

        <Button
          width="full"
          colorScheme="blue"
          onClick={() => {
            navigate(
              `${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_VIEW_ALL_METRIC_DATA}`
            );
          }}
        >
          View Metrics
        </Button>
      </VStack>
    </Center>
  );
};

export default ThankYouPage;
