import {
  IconButton,
  Box,
  HStack,
  Text,
  Skeleton,
  useToast,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { PatientDto } from "../../../types/patient";
import { calculateAge } from "../../../utils/date";
import { useEffect, useState } from "react";
import { getAllStaffList } from "../../../api/staff";
import { StaffDto } from "../../../types/staff";
import { useStaffStore } from "../../../store/staffStore";
import { FlagSeverity, FlagStatus } from "../../../types/flag";
import {
  SharedTable,
  SortConfig,
  TableBodyGrid,
  TableBodyGridItem,
} from "../../../components/SharedTable";

type SortColumn = "name" | "lastActivity" | "clientId";
export type DataSorting = SortConfig<SortColumn>;
interface PatientTableProps {
  patients: PatientDto[];
  loading?: boolean;
  sorting: SortConfig<SortColumn>;
  setSorting: React.Dispatch<React.SetStateAction<SortConfig<SortColumn>>>;
}

const FlagBoxGroup = ({ patient }: { patient: PatientDto }) => {
  const activeFlags =
    patient.flags?.filter((f) => f.status !== FlagStatus.RESOLVED) || [];

  const counts = {
    [FlagSeverity.CRITICAL]: activeFlags.filter(
      (f) => f.severity === FlagSeverity.CRITICAL
    ).length,
    [FlagSeverity.WARN]: activeFlags.filter(
      (f) => f.severity === FlagSeverity.WARN
    ).length,
    [FlagSeverity.INFO]: activeFlags.filter(
      (f) => f.severity === FlagSeverity.INFO
    ).length,
  };

  const boxStyles = {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    fontSize: "13px",
    fontWeight: "normal",
  };

  const getBoxProps = (severity: FlagSeverity) => {
    const count = counts[severity];
    const isActive = count > 0;

    const baseProps = {
      ...boxStyles,
      bg: isActive
        ? severity === FlagSeverity.CRITICAL
          ? "red.50"
          : severity === FlagSeverity.WARN
          ? "orange.50"
          : "gray.100"
        : "gray.50",
      color: isActive
        ? severity === FlagSeverity.CRITICAL
          ? "red.600"
          : severity === FlagSeverity.WARN
          ? "orange.600"
          : "gray.600"
        : "gray.600",
    };

    return baseProps;
  };

  return (
    <HStack spacing={3}>
      <Box {...getBoxProps(FlagSeverity.CRITICAL)}>
        {counts[FlagSeverity.CRITICAL] > 0 && counts[FlagSeverity.CRITICAL]}
      </Box>
      <Box {...getBoxProps(FlagSeverity.WARN)}>
        {counts[FlagSeverity.WARN] > 0 && counts[FlagSeverity.WARN]}
      </Box>
      <Box {...getBoxProps(FlagSeverity.INFO)}>
        {counts[FlagSeverity.INFO] > 0 && counts[FlagSeverity.INFO]}
      </Box>
    </HStack>
  );
};

const GRID_TEMPLATE_COLUMNS = "0.8fr 1.5fr 1fr 1.2fr 1fr 1fr";

export const PatientTable = ({
  patients,
  loading,
  sorting,
  setSorting,
}: PatientTableProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [staffMap, setStaffMap] = useState<Record<string, StaffDto>>({});
  const [staffLoading, setStaffLoading] = useState(true);
  const currentStaff = useStaffStore((state) => state.currentStaff);

  useEffect(() => {
    const fetchStaff = async () => {
      if (!currentStaff?.organizationId) return;

      try {
        setStaffLoading(true);
        const staffIds = Object.keys(
          patients.reduce<Record<string, boolean>>((acc, p) => {
            if (p.staffId) acc[p.staffId] = true;
            return acc;
          }, {})
        );

        if (staffIds.length === 0) return;

        const resp = await getAllStaffList(currentStaff.organizationId);
        if (resp.data) {
          setStaffMap(
            resp.data.reduce<Record<string, StaffDto>>((acc, staff) => {
              acc[staff.id] = staff;
              return acc;
            }, {})
          );
        }
      } catch (error) {
        console.error("Error fetching staff:", error);
      } finally {
        setStaffLoading(false);
      }
    };

    fetchStaff();
  }, [patients, currentStaff?.organizationId]);

  const formatClientName = (patient: PatientDto): string => {
    const age = patient.dob ? calculateAge(patient.dob) : null;
    const gender = patient.sex ? patient.sex.charAt(0) : null;

    const parts = [
      age !== null && age.toString(),
      gender !== null && gender,
    ].filter(Boolean);

    return parts.length > 0
      ? `${patient.name} (${parts.join(", ")})`
      : patient.name;
  };

  const copyToClipboard = (text: string, e: React.MouseEvent): void => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    toast({
      title: "Phone number copied",
      status: "info",
      duration: 2000,
      isClosable: true,
      position: "bottom",
    });
  };

  const formatDateTime = (date: Date): string => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
      .format(new Date(date))
      .replace(",", " /");
  };

  const handleSort = (column: SortColumn): void => {
    setSorting((prev) => ({
      column,
      direction:
        prev.column === column && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const viewPatient = (patientId: string) => {
    navigate(`${ROUTES.CLIENTS}/${patientId}`);
  };

  const patientColumns = [
    { id: "flags" as SortColumn, label: "Flags", isSortable: false },
    {
      id: "name" as SortColumn,
      label: "Client (Age, Gender)",
      isSortable: true,
    },
    {
      id: "clientId" as SortColumn,
      label: "Client ID",
      isSortable: true,
    },
    {
      id: "phoneNumber" as SortColumn,
      label: "Phone Number",
      isSortable: false,
    },
    {
      id: "staff" as SortColumn,
      label: "Staff in Charge",
      isSortable: false,
    },
    {
      id: "lastActivity" as SortColumn,
      label: "Last Activity",
      isSortable: true,
    },
  ];

  return (
    <SharedTable
      columns={patientColumns}
      gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
      sorting={sorting}
      onSort={handleSort}
    >
      {patients.map((patient) => (
        <TableBodyGrid
          key={patient.id}
          gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
          boxShadow="none"
          onClick={() => viewPatient(patient.id)}
        >
          {/* Flags Cell */}
          <TableBodyGridItem>
            {loading ? (
              <Skeleton height="20px" />
            ) : (
              <FlagBoxGroup patient={patient} />
            )}
          </TableBodyGridItem>

          {/* Client Name Cell */}
          <TableBodyGridItem>
            <Skeleton isLoaded={!loading}>
              <Text fontSize="sm">{formatClientName(patient)}</Text>
            </Skeleton>
          </TableBodyGridItem>

          {/* Client ID Cell */}
          <TableBodyGridItem>
            <Skeleton isLoaded={!loading}>
              <Text fontSize="sm">{patient.clientId || "-"}</Text>
            </Skeleton>
          </TableBodyGridItem>

          {/* Phone Number Cell */}
          <TableBodyGridItem>
            <Skeleton isLoaded={!loading}>
              <HStack spacing={2} justify="space-between">
                <Text fontSize="sm">{patient.whatsappPhoneNum}</Text>
                <IconButton
                  aria-label="Copy phone number"
                  icon={<CopyIcon />}
                  size="xs"
                  variant="ghost"
                  color="gray.300"
                  _hover={{ color: "gray.500" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(patient.whatsappPhoneNum || "", e);
                  }}
                />
              </HStack>
            </Skeleton>
          </TableBodyGridItem>

          {/* Staff in Charge Cell */}
          <TableBodyGridItem>
            <Skeleton isLoaded={!loading && !staffLoading}>
              <Text fontSize="sm">
                {patient.staffId && staffMap[patient.staffId]
                  ? staffMap[patient.staffId].name
                  : "-"}
              </Text>
            </Skeleton>
          </TableBodyGridItem>

          {/* Last Activity Cell */}
          <TableBodyGridItem>
            <Skeleton isLoaded={!loading}>
              <Text fontSize="sm">
                {patient.lastActivity && formatDateTime(patient.lastActivity)}
              </Text>
            </Skeleton>
          </TableBodyGridItem>
        </TableBodyGrid>
      ))}
    </SharedTable>
  );
};
