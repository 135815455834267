import { FC, useEffect, useState } from "react";
import {
  Heading,
  VStack,
  Text,
  Button,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  AccordionIcon,
  Center,
  Spinner,
  Tabs,
  TabList,
  Tab,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { getPatientMetricListV2 } from "../../api/patientMetric";
import { getMetricDefinitions } from "../../api/metricDefinition";
import { getPatientMetricConfigs } from "../../api/patientMetricConfig";
import { useParams } from "react-router-dom";
import { ROUTES, theme } from "../../constants";
import { useNavigate } from "react-router-dom";
import {
  MetricDefinitionGraph,
  MetricDataPoint,
} from "../../components/MetricDefinitionGraph";
import { TimePeriod } from "../../constants/time";
import { MetricDefinition } from "../../types/metricDefinition";
import { PatientMetricConfig } from "../../types/patientMetricConfig";
import { patientMetricDtoToMetricDataPointsGroupedByTime } from "../../utils/metricDefinition";
import { ShareAndroid, Copy } from "react-coolicons";
import { Logo } from "../../Logo";
import { PatientMetricV2Dto } from "../../types/patientMetric";
import { useMetricDefinitions } from "../../hooks/useMetricDefinitions";

// Define a type for our unified metric group
interface MetricGroup {
  groupKey: string;
  displayName: string;
  metrics: {
    definition: MetricDefinition;
    config: PatientMetricConfig;
  }[];
}

const ClientViewAllMetricData: FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(true);

  const [metricConfigs, setMetricConfigs] = useState<PatientMetricConfig[]>([]);
  const [dataPointsGroupedByTime, setDataPointsGroupsByTime] = useState<
    MetricDataPoint[][]
  >([]);
  const [selectedTimePeriodTab, setSelectedTimePeriodTab] = useState<number>(1);
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

  // Replace grouped and standalone metrics with a unified structure
  const [metricGroups, setMetricGroups] = useState<MetricGroup[]>([]);

  const { metricDefinitions } = useMetricDefinitions();
  const timePeriodOptions = {
    [TimePeriod.PAST_DAY]: "Day",
    [TimePeriod.PAST_WEEK]: "Week",
    [TimePeriod.PAST_MONTH]: "Month",
  };

  const handleShare = () => {
    onOpen();
  };

  const handleCopy = async () => {
    const message =
      "I've started recording my health information on Speedback, my digital health passport. Click here to view my information: " +
      window.location.href;
    try {
      await navigator.clipboard.writeText(message);
      toast({
        title: "Message copied!",
        description: "You can now share this with your trusted caregiver",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to copy message",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleGroupRecord = (groupKey: string) => {
    navigate(
      `${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_RECORD_METRICS}/${groupKey}`
    );
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const metricDefinitionIds = metricDefinitions.map((def) => def.id);

      // Get patient metric configs
      if (clientId) {
        const configsResponse = await getPatientMetricConfigs(
          clientId,
          true,
          metricDefinitionIds
        );
        if (!configsResponse.data) {
          toast({
            title: "Failed to fetch metric configurations",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        setMetricConfigs(configsResponse.data);

        const groupMap = new Map<string, MetricGroup>();
        const definitionsMap = new Map(
          metricDefinitions.map((def) => [def.id, def])
        );

        configsResponse.data.forEach((config) => {
          const definition = definitionsMap.get(config.metricDefinitionId);
          if (definition && config.isActive) {
            const groupKey = definition.metadata.groupKey || definition.key;

            const displayName = groupKey
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

            if (!groupMap.has(groupKey)) {
              groupMap.set(groupKey, {
                groupKey,
                displayName,
                metrics: [],
              });
            }

            const group = groupMap.get(groupKey)!;
            group.metrics.push({
              definition,
              config,
            });
          }
        });

        const groups = Array.from(groupMap.values()).sort((a, b) =>
          a.displayName.localeCompare(b.displayName)
        );

        setMetricGroups(groups);
        setExpandedIndices(Array.from({ length: groups.length }, (_, i) => i));

        // Use the new V2 API
        const metrics = await getPatientMetricListV2({
          patientIds: [clientId],
        });
        if (metrics) {
          // No need to filter for metricDefinitionId since it's required in V2
          const dataPoints =
            patientMetricDtoToMetricDataPointsGroupedByTime(metrics);
          setDataPointsGroupsByTime(dataPoints);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast({
        title: "Error",
        description: "Failed to load data",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [clientId, metricDefinitions]);

  if (loading) {
    return (
      <Center w="full" h="100vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack mt={4}>
      <Logo />
      <Box w="full" position="relative" mt={4}>
        <Center>
          <Heading as="h1" size="lg">
            Trends
          </Heading>
        </Center>
        <IconButton
          aria-label="Share health data"
          icon={<ShareAndroid />}
          onClick={handleShare}
          colorScheme="blue"
          variant="ghost"
          fontSize="24px"
          position="absolute"
          right={0}
          top="50%"
          transform="translateY(-50%)"
        />
      </Box>
      <Tabs
        index={selectedTimePeriodTab}
        onChange={(index) => setSelectedTimePeriodTab(index)}
        variant="line"
        colorScheme="blue"
      >
        <TabList>
          {Object.entries(timePeriodOptions).map(([, value], index) => (
            <Tab key={index}>{value}</Tab>
          ))}
        </TabList>
      </Tabs>
      <Accordion
        allowToggle
        allowMultiple
        w="full"
        mt={4}
        index={expandedIndices}
        onChange={(expandedIndex) => {
          if (Array.isArray(expandedIndex)) {
            setExpandedIndices(expandedIndex);
          }
        }}
      >
        {metricGroups.map((group, index) => {
          const isSingleMetric = group.metrics.length === 1;

          return (
            <AccordionItem key={`group-${group.groupKey}`}>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" py={2}>
                  <Heading
                    fontWeight="medium"
                    color={`${theme}.900`}
                    fontSize={{ base: "lg", md: "xl" }}
                  >
                    {isSingleMetric
                      ? group.metrics[0].definition.displayName
                      : group.displayName}
                  </Heading>
                  {!isSingleMetric && (
                    <Text fontSize="sm" color="gray.600" mt={1}>
                      {group.metrics
                        .map((m) => m.definition.displayName)
                        .join(", ")}
                    </Text>
                  )}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <MetricDefinitionGraph
                  dataPointsGroupedByTime={dataPointsGroupedByTime}
                  metrics={group.metrics.map((metric) => ({
                    id: metric.definition.id,
                    name: metric.definition.displayName,
                  }))}
                  selectedTimePeriod={
                    Object.keys(timePeriodOptions)[
                      selectedTimePeriodTab
                    ] as TimePeriod
                  }
                  endTime={new Date().getTime()}
                />
                <Button
                  onClick={() => handleGroupRecord(group.groupKey)}
                  w="full"
                  mt={4}
                >
                  + Record
                </Button>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share with caregivers</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text mb={4}>
              Invite your caregivers to your health passport so they know how
              you're doing, and can contribute readings on your behalf.
            </Text>
            <Alert status="warning" mb={4}>
              <AlertIcon />
              Please only share this link and your PIN with trusted caregivers.
            </Alert>
            <Button
              leftIcon={<Copy />}
              onClick={handleCopy}
              colorScheme="blue"
              size="lg"
              width="full"
            >
              Copy Link
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ClientViewAllMetricData;
