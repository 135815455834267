import { useFeatureEnabled } from "./useFeatureEnabled";
import { MetricConfWithDetail } from "../types/clientpass";
import { FeatureName } from "../components/FeatureToggle/constants";
import { useMemo } from "react";
import { FeatureData } from "../store/featureStore";
import { MetricDefinition } from "../types/metricDefinition";

const getExcludedMetrics = (featureData: FeatureData[]) => {
  const excludedMetrics: string[] = featureData
    .filter(({ enabled, metadata }) => !enabled && !!metadata?.metrics)
    .map(({ metadata }) => metadata?.metrics)
    .flat()
    .map((metric) => metric.toLowerCase());

  return excludedMetrics;
};

/**
 * Generic hook for filtering metrics based on feature flags
 */
const useFilteredMetricsByFeature = <T>(
  items: T[] | undefined,
  getMetricKey: (item: T) => string
): T[] => {
  const wearableFeature = useFeatureEnabled(FeatureName.WEARABLES);

  return useMemo(() => {
    if (!items) return [];

    const excludedMetrics = getExcludedMetrics([wearableFeature]);
    if (excludedMetrics.length === 0) return items;

    return items.filter((item) => {
      const key = getMetricKey(item).toLowerCase();
      return !excludedMetrics.some((excludedMetric) => {
        return key.includes(excludedMetric);
      });
    });
  }, [items, wearableFeature.enabled]);
};

/**
 * Hook that filters metrics to be displayed based on feature flags
 */
export const useFilteredMetrics = (
  metrics: MetricConfWithDetail[] | undefined
) => {
  return useFilteredMetricsByFeature(metrics, (metric) => metric.detail.title);
};

/**
 * Hook that filters metric definitions based on feature flags
 */
export const useFilteredMetricDefinitions = (
  metricDefinitions: MetricDefinition[]
) => {
  return useFilteredMetricsByFeature(metricDefinitions, (metric) => metric.key);
};
