import { Flag, FlagSeverity } from "./flag";
import { MetricDefinition } from "./metricDefinition";
import { PatientMetricConfig } from "./patientMetricConfig";

export enum AuditLogActor {
  SYSTEM = "SYSTEM",
  STAFF = "STAFF",
}

export enum AuditLogSubject {
  FLAG = "FLAG",
  PROTOCOL = "PROTOCOL",
  PATIENT_METRIC_CONFIG = "PATIENT_METRIC_CONFIG",
}

export enum AuditLogAction {
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  DELETED = "DELETED",
}

export interface AuditLogMetadata {
  previousState?: Record<string, unknown>;
  newState?: Record<string, unknown>;
  affectedFields?: string[];
}

export interface AuditLog {
  id: string;
  actorId: string | null;
  actor: AuditLogActor;
  subjectId: string;
  subject: AuditLogSubject;
  action: AuditLogAction;
  patientId: string;
  metadata: AuditLogMetadata;
  createdAt: Date;
  patientName?: string;
  staffName?: string;
  staffId?: string;
}

export type AuditLogStatePatientMetricConfig = Pick<
  PatientMetricConfig,
  "id" | "customValues" | "isActive" | "nonAdherenceDays"
> & {
  metricDefinition: Pick<MetricDefinition, "id" | "displayName">;
};

export type AuditLogStateFlag = Flag;

export type AuditLogStateProtocol = {
  name: string;
  description: string;
  flagSeverity: FlagSeverity;
};
