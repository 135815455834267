import { MetricDataPoint } from "../components/MetricDefinitionGraph";
import { PatientMetricV2Dto } from "../types/patientMetric";

export function patientMetricDtoToMetricDataPointsGroupedByTime(
  metrics: PatientMetricV2Dto[]
): MetricDataPoint[][] {
  const validMetrics = metrics.filter((metric) => !!metric.metricDefinitionId);

  const metricsByTime = groupPatientMetricDtoByTime(validMetrics);
  return groupedPatientMetricDtoToMetricDataPoints(metricsByTime);
}

export function groupedPatientMetricDtoToMetricDataPoints(
  metrics: PatientMetricV2Dto[][]
): MetricDataPoint[][] {
  return metrics.map((metricGroup) => {
    const dataPoints = metricGroup
      .map((metric) => {
        return {
          metricDefinitionId: metric.metricDefinitionId as string,
          value: metric.value,
          timestamp: parseInt(metric.timestamp),
        };
      });
    return dataPoints;
  });
}

export function groupPatientMetricDtoByTime(
  metrics: PatientMetricV2Dto[]
): PatientMetricV2Dto[][] {
  const timeToDataPoints: Record<string, PatientMetricV2Dto[]> = {};
  metrics.forEach((metric) => {
    const timestamp = parseInt(metric.timestamp);
    if (!timeToDataPoints[timestamp]) {
      timeToDataPoints[timestamp] = [];
    }
    timeToDataPoints[timestamp].push(metric);
  });

  return Object.entries(timeToDataPoints)
    .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
    .map(([, value]) => value);
}
