import { Box, Text, VStack, HStack } from "@chakra-ui/react";
import {
  AuditLog,
  AuditLogAction,
  AuditLogStateFlag,
  AuditLogStatePatientMetricConfig,
  AuditLogStateProtocol,
  AuditLogSubject,
} from "../../../types/audit";
import { Flag, FlagTrigger } from "../../../types/flag";
import { ProtocolDto } from "../../../types/protocol";
interface AuditLogContentProps {
  log: AuditLog;
  variant: "header" | "content";
}

const formatValue = (value: unknown): string => {
  if (typeof value === "object" && value !== null) {
    return JSON.stringify(value);
  }
  return String(value);
};

const renderValueChange = (field: string, prev: unknown, next: unknown) => {
  return (
    <Text fontSize="sm" color="gray.600" key={field}>
      • Changed {field} from{" "}
      <Text as="span" fontWeight="medium">
        {formatValue(prev)}
      </Text>{" "}
      to{" "}
      <Text as="span" fontWeight="medium">
        {formatValue(next)}
      </Text>
    </Text>
  );
};

const renderFlagHeader = (log: AuditLog) => {
  const state =
    log.action === AuditLogAction.CREATED
      ? (log.metadata.newState as unknown as AuditLogStateFlag)
      : (log.metadata.previousState as unknown as AuditLogStateFlag);

  if (!state?.metricDisplayName) return "Flag";
  return `${state.metricDisplayName} Flag`;
};

const renderProtocolHeader = (log: AuditLog) => {
  const state =
    log.action === AuditLogAction.CREATED
      ? (log.metadata.newState as unknown as ProtocolDto)
      : (log.metadata.previousState as unknown as ProtocolDto);

  if (!state?.name) return "Protocol";
  return `Protocol: ${state.name}`;
};

const renderMetricConfigHeader = (log: AuditLog) => {
  const state = log.metadata
    .previousState as unknown as AuditLogStatePatientMetricConfig;
  if (!state?.metricDefinition?.displayName) return "Metric Config";
  return `${state.metricDefinition.displayName} Metric Config`;
};

const renderHeader = (log: AuditLog) => {
  let subject: string;
  switch (log.subject) {
    case AuditLogSubject.FLAG:
      subject = renderFlagHeader(log);
      break;
    case AuditLogSubject.PROTOCOL:
      subject = renderProtocolHeader(log);
      break;
    case AuditLogSubject.PATIENT_METRIC_CONFIG:
      subject = renderMetricConfigHeader(log);
      break;
    default:
      subject = "Unknown";
  }

  const action = (() => {
    switch (log.action) {
      case AuditLogAction.CREATED:
        return "Created";
      case AuditLogAction.UPDATED:
        return "Updated";
      case AuditLogAction.DELETED:
        return "Deleted";
    }
  })();

  return (
    <HStack spacing={2}>
      <Text fontFamily={"heading"} fontWeight={"medium"}>
        {subject}
      </Text>
      <Text fontFamily={"heading"} fontWeight={"medium"} color="gray.600">
        {action}
      </Text>
    </HStack>
  );
};

const renderFlagContent = (log: AuditLog) => {
  switch (log.action) {
    case AuditLogAction.CREATED: {
      const newState = log.metadata.newState as unknown as AuditLogStateFlag;
      if (!newState?.severity || !newState?.metricDisplayName) return null;

      return (
        <VStack align="start" spacing={1}>
          <Text fontSize="sm">
            {newState.severity} priority flag created for:
          </Text>
          <Text fontSize="sm" color="gray.600" pl={4}>
            {newState.metricDisplayName}
            {newState.trigger === FlagTrigger.METRIC
              ? ` (${newState.metricValue} ${newState.metricUnit})`
              : ` (${newState.daysSinceSubmission} days non-adherent)`}
          </Text>
        </VStack>
      );
    }
    case AuditLogAction.UPDATED: {
      const prevState = log.metadata.previousState as unknown as Pick<
        Flag,
        "status"
      >;
      const newState = log.metadata.newState as unknown as Pick<Flag, "status">;

      if (prevState?.status && newState?.status) {
        return renderValueChange("status", prevState.status, newState.status);
      }
      return null;
    }
    case AuditLogAction.DELETED: {
      const prevState = log.metadata.previousState as unknown as Flag;
      if (!prevState?.metricDisplayName) return null;

      return (
        <Text fontSize="sm" color="gray.600">
          Deleted flag: {prevState.metricDisplayName}
        </Text>
      );
    }
  }
};

const renderProtocolContent = (log: AuditLog) => {
  switch (log.action) {
    case AuditLogAction.CREATED: {
      const { newState } = log.metadata as any;
      if (!newState?.name) return null;

      return (
        <VStack align="start" spacing={1}>
          <Text fontSize="sm">
            New protocol added:{" "}
            <Text as="span" fontWeight="medium">
              {newState.name}
            </Text>
          </Text>
          {newState.description && (
            <Text fontSize="sm" color="gray.600" pl={4}>
              {newState.description}
            </Text>
          )}
          {newState.flagSeverity && (
            <Text fontSize="sm" color="gray.600" pl={4}>
              {newState.flagSeverity} priority flag is set
            </Text>
          )}
        </VStack>
      );
    }
    case AuditLogAction.UPDATED: {
      const changes: JSX.Element[] = [];
      const prevState = log.metadata
        .previousState as unknown as AuditLogStateProtocol;
      const newState = log.metadata
        .newState as unknown as AuditLogStateProtocol;

      if (prevState?.flagSeverity !== newState?.flagSeverity) {
        changes.push(
          renderValueChange(
            "Flag Severity",
            prevState.flagSeverity,
            newState.flagSeverity
          )
        );
      }

      if (changes.length > 0) {
        return (
          <VStack align="start" spacing={1}>
            {changes}
          </VStack>
        );
      }
      return null;
    }
    case AuditLogAction.DELETED: {
      const prevState = log.metadata.previousState as unknown as ProtocolDto;
      if (!prevState?.name) return null;

      return (
        <Text fontSize="sm" color="gray.600">
          Deleted protocol:{" "}
          <Text as="span" fontWeight="medium">
            {prevState.name}
          </Text>
        </Text>
      );
    }
  }
};

const renderMetricConfigContent = (log: AuditLog) => {
  if (log.action === AuditLogAction.UPDATED) {
    const changes: JSX.Element[] = [];
    const prevState = log.metadata
      .previousState as unknown as AuditLogStatePatientMetricConfig;
    const newState = log.metadata
      .newState as unknown as AuditLogStatePatientMetricConfig;

    if (prevState?.customValues && newState?.customValues) {
      if (
        prevState.customValues.normalMin !== newState.customValues.normalMin
      ) {
        changes.push(
          renderValueChange(
            "Normal Minimum",
            prevState.customValues.normalMin,
            newState.customValues.normalMin
          )
        );
      }

      if (
        prevState.customValues.normalMax !== newState.customValues.normalMax
      ) {
        changes.push(
          renderValueChange(
            "Normal Maximum",
            prevState.customValues.normalMax,
            newState.customValues.normalMax
          )
        );
      }
    }

    // Handle isActive changes
    if (prevState.isActive !== newState.isActive) {
      changes.push(
        <Text fontSize="sm" color="gray.600" key="isActive">
          • Monitoring {newState.isActive ? "enabled" : "disabled"}
        </Text>
      );
    }

    if (
      prevState?.nonAdherenceDays !== undefined &&
      newState?.nonAdherenceDays !== undefined
    ) {
      changes.push(
        renderValueChange(
          "Frequency of days to be considered non-adherent",
          prevState.nonAdherenceDays,
          newState.nonAdherenceDays
        )
      );
    }

    if (changes.length > 0) {
      return (
        <VStack align="start" spacing={1}>
          {changes}
        </VStack>
      );
    }
  }
  return null;
};

export const AuditLogContent: React.FC<AuditLogContentProps> = ({
  log,
  variant,
}) => {
  if (variant === "header") {
    return renderHeader(log);
  }

  switch (log.subject) {
    case AuditLogSubject.FLAG:
      return <Box>{renderFlagContent(log)}</Box>;
    case AuditLogSubject.PROTOCOL:
      return <Box>{renderProtocolContent(log)}</Box>;
    case AuditLogSubject.PATIENT_METRIC_CONFIG:
      return <Box>{renderMetricConfigContent(log)}</Box>;
    default:
      return null;
  }
};

export default AuditLogContent;
