import React from "react";
import {
  Box,
  Text,
  Flex,
  useColorModeValue,
  VStack,
  FlexProps,
} from "@chakra-ui/react";
import { format } from "date-fns";

interface MetricHistoryItemProps extends FlexProps {
  timestamp: string;
  value: number | string;
  unit?: string;
  label?: string;
  additionalContent?: React.ReactNode;
}

const MetricHistoryItem: React.FC<MetricHistoryItemProps> = ({
  timestamp,
  value,
  unit,
  label,
  additionalContent,
  ...flexProps
}) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const dateColor = useColorModeValue("blue.600", "blue.300");

  const formatDate = (timestamp: string) => {
    const date = new Date(parseInt(timestamp));
    return format(date, "MMM d, yyyy 'at' h:mm a");
  };

  return (
    <Box
      p={4}
      bg={bgColor}
      borderRadius="md"
      borderWidth="1px"
      borderColor={borderColor}
      boxShadow="sm"
      w="full"
    >
      <VStack align="stretch" spacing={2}>
        <Text fontSize="sm" color={dateColor}>
          {formatDate(timestamp)}
        </Text>

        <Flex
          direction="row"
          justify="space-between"
          align="center"
          {...flexProps}
        >
          {label && (
            <Text fontWeight="medium" fontSize="md">
              {label}
            </Text>
          )}
          <Text fontWeight="medium" fontSize="md">
            {value} {unit}
          </Text>
        </Flex>

        {additionalContent}
      </VStack>
    </Box>
  );
};

export default MetricHistoryItem;
