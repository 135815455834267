import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Center,
  Spinner,
  Button,
  Flex,
  Badge,
  Grid,
  useColorModeValue,
  useToast,
  Icon,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { format, subDays, eachDayOfInterval } from "date-fns";
import { getLabFoods, generateLabSummary, getLabSummary } from "../../api/lab";
import { LabFoodDto, LabSummaryDto } from "../../types/lab";
import { FaChevronDown, FaChevronUp, FaUtensils } from "react-icons/fa";

type TimeRange = "7" | "14" | "30";

const COLORS = {
  protein: "#3182CE", // blue
  carbs: "#38A169", // green
  fat: "#DD6B20", // orange
  fiber: "#805AD5", // purple
};

const EmptyState = () => (
  <Center py={8}>
    <VStack spacing={4}>
      <Text color="gray.500" fontSize="lg" textAlign="center">
        No nutritional data available yet
      </Text>
      <Text color="gray.400" fontSize="sm" textAlign="center" maxW="md">
        Start logging meals via Whatsapp to see nutritional insights and trends
      </Text>
    </VStack>
  </Center>
);

const ClientNutrition: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [foods, setFoods] = useState<LabFoodDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState<TimeRange>("7");
  const [summary, setSummary] = useState<LabSummaryDto | null>(null);
  const [generatingSummary, setGeneratingSummary] = useState(false);
  const [expandedCardId, setExpandedCardId] = useState<string | null>(null);
  const toast = useToast();
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  useEffect(() => {
    const loadData = async () => {
      if (!clientId) return;
      try {
        setLoading(true);

        const foodResponse = await getLabFoods(clientId);
        setFoods(foodResponse);

        const summaryResponse = await getLabSummary(clientId);
        if (summaryResponse.success && summaryResponse.data) {
          setSummary(summaryResponse.data);
        }
      } catch (error) {
        console.error("Failed to fetch food history:", error);
        toast({
          title: "Error loading food data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [clientId, toast]);

  const handleGenerateSummary = async () => {
    if (!clientId) return;
    try {
      setGeneratingSummary(true);
      const response = await generateLabSummary(clientId);
      if (response.success && response.data) {
        setSummary(response.data);
      } else {
        toast({
          title: response.message || "Failed to generate summary",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Failed to generate summary:", error);
      toast({
        title: "Error generating summary",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setGeneratingSummary(false);
    }
  };

  const stats = useMemo(() => {
    const cutoffDate = subDays(new Date(), parseInt(timeRange));
    const filteredFoods = foods.filter(
      (food) => new Date(food.createdAt) >= cutoffDate
    );

    if (filteredFoods.length === 0) {
      return {
        averageCalories: 0,
        macros: [],
        dailyStats: [],
        totalProtein: 0,
        totalCarbs: 0,
        totalFat: 0,
        totalFiber: 0,
        hasData: false,
      };
    }

    // Calculate total macros
    const totalCalories = filteredFoods.reduce(
      (sum, food) => sum + food.calories,
      0
    );
    const totalProtein = filteredFoods.reduce(
      (sum, food) => sum + food.protein,
      0
    );
    const totalCarbs = filteredFoods.reduce(
      (sum, food) => sum + food.carbohydrates,
      0
    );
    const totalFat = filteredFoods.reduce((sum, food) => sum + food.fat, 0);
    const totalFiber = filteredFoods.reduce((sum, food) => sum + food.fiber, 0);
    const totalGrams = totalProtein + totalCarbs + totalFat;

    // Calculate macro percentages
    const macros = [
      {
        name: "Protein",
        value: totalProtein,
        percentage: Math.round((totalProtein / totalGrams) * 100),
      },
      {
        name: "Carbs",
        value: totalCarbs,
        percentage: Math.round((totalCarbs / totalGrams) * 100),
      },
      {
        name: "Fat",
        value: totalFat,
        percentage: Math.round((totalFat / totalGrams) * 100),
      },
      {
        name: "Fiber",
        value: totalFiber,
        percentage: Math.round((totalFiber / totalGrams) * 25), // Scaled for visibility
      },
    ];

    // Calculate daily stats for the line chart
    const dateRange = eachDayOfInterval({
      start: cutoffDate,
      end: new Date(),
    });

    const dailyStats = dateRange.map((date) => {
      const dayFoods = filteredFoods.filter(
        (food) =>
          format(new Date(food.createdAt), "yyyy-MM-dd") ===
          format(date, "yyyy-MM-dd")
      );

      const dayCalories = dayFoods.reduce(
        (sum, food) => sum + food.calories,
        0
      );
      const dayProtein = dayFoods.reduce((sum, food) => sum + food.protein, 0);
      const dayCarbs = dayFoods.reduce(
        (sum, food) => sum + food.carbohydrates,
        0
      );
      const dayFat = dayFoods.reduce((sum, food) => sum + food.fat, 0);
      const dayFiber = dayFoods.reduce((sum, food) => sum + food.fiber, 0);

      return {
        date: format(date, "MMM d"),
        calories: dayCalories,
        protein: dayProtein,
        carbs: dayCarbs,
        fat: dayFat,
        fiber: dayFiber,
      };
    });

    // Get unique days with data
    const uniqueDays = new Set(
      filteredFoods.map(
        (food) => new Date(food.createdAt).toISOString().split("T")[0]
      )
    );
    const daysWithData = uniqueDays.size;

    return {
      averageCalories: Math.round(totalCalories / daysWithData),
      macros,
      dailyStats,
      totalProtein,
      totalCarbs,
      totalFat,
      totalFiber,
      hasData: true,
      daysWithData,
    };
  }, [foods, timeRange]);

  const renderFoodLog = () => {
    if (loading) {
      return (
        <Center py={8}>
          <Spinner />
        </Center>
      );
    }

    if (foods.length === 0) {
      return <EmptyState />;
    }

    return (
      <VStack spacing={4} align="stretch">
        {foods.map((food) => {
          const isExpanded = expandedCardId === food.id;

          return (
            <Box
              key={food.id}
              p={4}
              borderWidth="1px"
              borderColor={borderColor}
              borderRadius="md"
              bg={bgColor}
              transition="all 0.2s"
              _hover={{ boxShadow: "sm" }}
            >
              <HStack spacing={4} align="flex-start">
                {food.mediaUrl && (
                  <Image
                    src={food.mediaUrl}
                    alt={food.dishName}
                    borderRadius="md"
                    boxSize={isExpanded ? "100px" : "80px"}
                    objectFit="cover"
                  />
                )}
                <VStack align="flex-start" spacing={2} flex={1}>
                  <HStack justify="space-between" width="100%">
                    <Heading size="sm">{food.dishName}</Heading>
                    <Badge colorScheme="blue">{food.calories} kcal</Badge>
                  </HStack>

                  <Text
                    fontSize="sm"
                    color="gray.600"
                    noOfLines={isExpanded ? undefined : 2}
                    cursor="pointer"
                    onClick={() =>
                      setExpandedCardId(isExpanded ? null : food.id)
                    }
                  >
                    {food.description}
                  </Text>

                  {isExpanded ? (
                    <VStack spacing={1} align="stretch" width="100%" mt={1}>
                      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                        <HStack>
                          <Text
                            fontSize="sm"
                            fontWeight="medium"
                            color="blue.500"
                          >
                            Protein:
                          </Text>
                          <Text fontSize="sm">{food.protein}g</Text>
                        </HStack>
                        <HStack>
                          <Text
                            fontSize="sm"
                            fontWeight="medium"
                            color="green.500"
                          >
                            Carbs:
                          </Text>
                          <Text fontSize="sm">{food.carbohydrates}g</Text>
                        </HStack>
                        <HStack>
                          <Text
                            fontSize="sm"
                            fontWeight="medium"
                            color="orange.500"
                          >
                            Fats:
                          </Text>
                          <Text fontSize="sm">{food.fat}g</Text>
                        </HStack>
                        <HStack>
                          <Text
                            fontSize="sm"
                            fontWeight="medium"
                            color="purple.500"
                          >
                            Fiber:
                          </Text>
                          <Text fontSize="sm">{food.fiber}g</Text>
                        </HStack>
                      </Grid>
                    </VStack>
                  ) : (
                    <HStack spacing={3} pt={1}>
                      <Text fontSize="xs">
                        <Text as="span" fontWeight="bold" color="blue.500">
                          P:
                        </Text>{" "}
                        {food.protein}g
                      </Text>
                      <Text fontSize="xs">
                        <Text as="span" fontWeight="bold" color="green.500">
                          C:
                        </Text>{" "}
                        {food.carbohydrates}g
                      </Text>
                      <Text fontSize="xs">
                        <Text as="span" fontWeight="bold" color="orange.500">
                          F:
                        </Text>{" "}
                        {food.fat}g
                      </Text>
                      <Text fontSize="xs">
                        <Text as="span" fontWeight="bold" color="purple.500">
                          Fiber:
                        </Text>{" "}
                        {food.fiber}g
                      </Text>
                    </HStack>
                  )}

                  <HStack justify="space-between" width="100%">
                    <Text fontSize="xs" color="gray.500">
                      {format(new Date(food.createdAt), "MMM d, yyyy • h:mm a")}
                    </Text>
                    <Icon
                      as={isExpanded ? FaChevronUp : FaChevronDown}
                      color="blue.500"
                      boxSize="14px"
                      cursor="pointer"
                      onClick={() =>
                        setExpandedCardId(isExpanded ? null : food.id)
                      }
                      aria-label={isExpanded ? "Show less" : "Show more"}
                    />
                  </HStack>
                </VStack>
              </HStack>
            </Box>
          );
        })}
      </VStack>
    );
  };

  const renderTrends = () => {
    if (loading) {
      return (
        <Center py={8}>
          <Spinner />
        </Center>
      );
    }

    if (!stats.hasData) {
      return <EmptyState />;
    }

    return (
      <VStack spacing={6} align="stretch">
        <HStack spacing={4} justify="center">
          <Button
            size="sm"
            variant={timeRange === "7" ? "solid" : "outline"}
            onClick={() => setTimeRange("7")}
            colorScheme="blue"
          >
            7 Days
          </Button>
          <Button
            size="sm"
            variant={timeRange === "14" ? "solid" : "outline"}
            onClick={() => setTimeRange("14")}
            colorScheme="blue"
          >
            14 Days
          </Button>
          <Button
            size="sm"
            variant={timeRange === "30" ? "solid" : "outline"}
            onClick={() => setTimeRange("30")}
            colorScheme="blue"
          >
            30 Days
          </Button>
        </HStack>

        <Box
          p={4}
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="md"
          bg={bgColor}
        >
          <Heading size="sm" mb={2}>
            Average Daily Calories
          </Heading>
          <Text fontSize="2xl" fontWeight="semibold">
            {stats.averageCalories.toLocaleString()} kcal
          </Text>
          <Text fontSize="xs" color="gray.500">
            Based on {stats.daysWithData} days with recorded meals
          </Text>
        </Box>

        <Box
          p={4}
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="md"
          bg={bgColor}
        >
          <Heading size="sm" mb={4}>
            Macronutrient Breakdown
          </Heading>
          <Box height="200px">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={stats.macros}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={30}
                  outerRadius={60}
                  paddingAngle={5}
                >
                  {stats.macros.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        Object.values(COLORS)[
                          index % Object.values(COLORS).length
                        ]
                      }
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>
          <HStack justify="space-around" mt={2}>
            {stats.macros.map((macro) => (
              <VStack key={macro.name} spacing={0}>
                <Text
                  fontSize="sm"
                  fontWeight="bold"
                  color={
                    COLORS[macro.name.toLowerCase() as keyof typeof COLORS]
                  }
                >
                  {macro.percentage}%
                </Text>
                <Text fontSize="xs">{macro.name}</Text>
              </VStack>
            ))}
          </HStack>
        </Box>

        <Box
          p={4}
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="md"
          bg={bgColor}
        >
          <Heading size="sm" mb={4}>
            Calorie Trend
          </Heading>
          <Box height="200px">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={stats.dailyStats}>
                <XAxis dataKey="date" tick={{ fontSize: 10 }} />
                <YAxis
                  domain={[0, "auto"]}
                  tick={{ fontSize: 10 }}
                  width={30}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="calories"
                  stroke="#3182CE"
                  strokeWidth={2}
                  dot={{ r: 3 }}
                  activeDot={{ r: 5 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </VStack>
    );
  };

  const renderInsights = () => {
    return (
      <VStack spacing={4} align="stretch">
        {!summary && (
          <Box
            textAlign="center"
            p={6}
            borderWidth="1px"
            borderRadius="lg"
            borderColor={borderColor}
            bg={bgColor}
          >
            <VStack spacing={4}>
              <Box
                p={3}
                borderRadius="full"
                bg="blue.50"
                color="blue.500"
                display="inline-flex"
                mb={2}
              >
                <Icon as={FaUtensils} boxSize="24px" />
              </Box>
              <Heading size="md">Nutritional Insights</Heading>
              <Text color="gray.600" fontSize="md" textAlign="center" pb={2}>
                Get personalized analysis of your eating patterns based on your
                food log
              </Text>
              <Button
                colorScheme="blue"
                onClick={handleGenerateSummary}
                isLoading={generatingSummary}
                loadingText="Generating"
                isDisabled={foods.length === 0}
                size="md"
                px={8}
                shadow="md"
              >
                Generate Nutrition Summary
              </Button>
              {foods.length === 0 && (
                <Text fontSize="sm" color="gray.500" mt={2}>
                  Add food entries before generating insights
                </Text>
              )}
            </VStack>
          </Box>
        )}

        {summary && (
          <>
            <Box
              p={4}
              borderWidth="1px"
              borderColor={borderColor}
              borderRadius="md"
              bg={bgColor}
            >
              <Flex justify="space-between" align="center" mb={4}>
                <Heading size="md">Insights</Heading>
                <Button
                  size="sm"
                  onClick={handleGenerateSummary}
                  isLoading={generatingSummary}
                  colorScheme="blue"
                  variant="outline"
                >
                  Regenerate
                </Button>
              </Flex>

              {summary.lastGenerated && (
                <Text fontSize="xs" color="gray.500" mb={4}>
                  Last generated{" "}
                  {format(
                    new Date(summary.lastGenerated),
                    "MMM d, yyyy • h:mm a"
                  )}
                </Text>
              )}

              <Box
                p={4}
                borderWidth="1px"
                borderColor={borderColor}
                borderRadius="md"
                bg={bgColor}
                mb={4}
              >
                <Heading size="sm" mb={2}>
                  Diet Trends
                </Heading>
                <Text fontSize="sm">{summary.trends}</Text>
              </Box>

              <Box
                p={4}
                borderWidth="1px"
                borderColor={borderColor}
                borderRadius="md"
                bg={bgColor}
                mb={4}
              >
                <Heading size="sm" mb={2}>
                  Strengths
                </Heading>
                <Text fontSize="sm">{summary.analysis.strengths}</Text>
              </Box>

              <Box
                p={4}
                borderWidth="1px"
                borderColor={borderColor}
                borderRadius="md"
                bg={bgColor}
                mb={4}
              >
                <Heading size="sm" mb={2}>
                  Areas for Improvement
                </Heading>
                <Text fontSize="sm">{summary.analysis.improvements}</Text>
              </Box>

              <Box
                p={4}
                borderWidth="1px"
                borderColor={borderColor}
                borderRadius="md"
                bg={bgColor}
              >
                <Heading size="sm" mb={2}>
                  Recommendations
                </Heading>
                <Text fontSize="sm">{summary.recommendations}</Text>
              </Box>
            </Box>
          </>
        )}
      </VStack>
    );
  };

  return (
    <Box p={4}>
      <Heading size="lg" mb={4}>
        Nutrition
      </Heading>

      <Tabs isFitted colorScheme="blue" variant="enclosed">
        <TabList mb={4}>
          <Tab>Food Log</Tab>
          <Tab>Trends</Tab>
          <Tab>Insights</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>{renderFoodLog()}</TabPanel>
          <TabPanel p={0}>{renderTrends()}</TabPanel>
          <TabPanel p={0}>{renderInsights()}</TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ClientNutrition;
