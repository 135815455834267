import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { Property } from "../../types/protocol";
import { ResponseType, FormTypeField } from "../../types/form";
import { toTitleCase } from "../../utils";
import { capitalize, wordify } from "../../utils/strings";
import RaidoFormTypeField from "./FormTypeFields/RadioFormTypeField";
import { theme } from "../../constants";
import CheckboxFormTypeField from "./FormTypeFields/CheckboxFormTypeField";
import { MetricDefinition } from "../../types/metricDefinition";

type CreateFlowFieldProps = {
  field: FormTypeField;
  index: number;
  removeField?: () => void;
  updateField?: (field: FormTypeField) => void;
  metricDefinitions: MetricDefinition[];
};

const CreateFlowField = ({
  field,
  index,
  removeField,
  updateField,
  metricDefinitions,
}: CreateFlowFieldProps) => {
  const isNumberRangeInvalid = (field: FormTypeField) => {
    if (
      ![ResponseType.NUMBER, ResponseType.PROPERTY].includes(field.responseType)
    ) {
      return;
    }

    if (field.numberOptions?.max && field.numberOptions?.min) {
      return field.numberOptions.max < field.numberOptions.min;
    }

    return false;
  };
  return (
    <Card width="full" p={4}>
      <HStack align={"start"} justify={"start"} w="full" spacing={4}>
        <Box w="min" bg={`gray.100`} borderRadius={"md"} px={2} my={1}>
          <Text
            fontSize={{ base: "md", "2xl": "lg" }}
            color={`${theme}.800`}
            fontWeight={"medium"}
          >
            {index + 1}
          </Text>
        </Box>
        <VStack align={"start"} w="full" spacing={3}>
          <HStack align={"center"} justify={"start"} w="full" spacing={4}>
            <FormControl w={"25%"} isRequired>
              <Select
                size={{ base: "sm", "2xl": "md" }}
                fontSize={{ base: "md", "2xl": "lg" }}
                fontWeight="bold"
                color={`${theme}.800`}
                value={field.responseType}
                onChange={(e) => {
                  const newField: FormTypeField = {
                    ...field,
                    responseType: e.target.value as ResponseType,
                  };
                  if (newField.responseType !== ResponseType.RADIO) {
                    newField.options = {};
                    newField.rangeLabels = {};
                  }
                  updateField && updateField(newField);
                }}
              >
                <option value={""}>Select response type</option>
                {Object.keys(ResponseType).map((key) => {
                  const label = wordify(key);
                  return (
                    <option key={key} value={key}>
                      {capitalize(label)}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            {field.responseType === ResponseType.PROPERTY && (
              <FormControl
                w={"75%"}
                isRequired
                isInvalid={!field.metricDefinitionId}
              >
                <Select
                  size={{ base: "sm", "2xl": "md" }}
                  fontSize={{ base: "md", "2xl": "lg" }}
                  fontWeight="bold"
                  color={`${theme}.800`}
                  value={field.property}
                  onChange={(e) => {
                    const metricDefinition = metricDefinitions.find(
                      (p) => p.id === e.target.value
                    );
                    const newField: FormTypeField = {
                      ...field,
                      metricDefinitionId: metricDefinition?.id,
                      numberOptions: {
                        min: metricDefinition?.validationRules?.min,
                        max: metricDefinition?.validationRules?.max,
                      },
                    };
                    updateField && updateField(newField);
                  }}
                >
                  <option value={""}>Select a property</option>
                  {metricDefinitions.map((p) => (
                    <option value={p.id}>{toTitleCase(p.displayName)}</option>
                  ))}
                </Select>
              </FormControl>
            )}
            <Spacer />
            {removeField && (
              <FaTrash cursor={"pointer"} color="red" onClick={removeField} />
            )}
          </HStack>

          <HStack w="full">
            <FormControl isRequired isInvalid={field.question === ""}>
              <HStack w={"full"}>
                <Input
                  placeholder="Question"
                  size={{ base: "sm", "2xl": "md" }}
                  fontSize={{ base: "md", "2xl": "lg" }}
                  fontWeight="bold"
                  color={`${theme}.800`}
                  required
                  onChange={(e) => {
                    const newField: FormTypeField = {
                      ...field,
                      question: e.target.value,
                    };
                    updateField && updateField(newField);
                  }}
                  value={field.question}
                />
                <Tooltip
                  shouldWrapChildren
                  label="This is the question displayed on the form."
                >
                  <AiOutlineInfoCircle />
                </Tooltip>
              </HStack>
            </FormControl>
            <FormControl isRequired isInvalid={field.label === ""}>
              <HStack w={"full"}>
                <Input
                  placeholder="Label name"
                  size={{ base: "sm", "2xl": "md" }}
                  fontSize={{ base: "md", "2xl": "lg" }}
                  fontWeight="bold"
                  color={`${theme}.800`}
                  onChange={(e) => {
                    const newField: FormTypeField = {
                      ...field,
                      label: e.target.value,
                    };
                    updateField && updateField(newField);
                  }}
                  value={field.label}
                />
                <Tooltip
                  shouldWrapChildren
                  label="This is used for data visualization"
                >
                  <AiOutlineInfoCircle />
                </Tooltip>
              </HStack>
            </FormControl>
          </HStack>
          {field.responseType === ResponseType.RADIO && (
            <RaidoFormTypeField field={field} updateField={updateField} />
          )}
          {(field.responseType === ResponseType.NUMBER)  && (
            <FormControl isInvalid>
              <HStack align={"center"} spacing={8}>
                <HStack>
                  <FormLabel m={0}>Min</FormLabel>
                  <Tooltip
                    shouldWrapChildren
                    label="Minimum value for the number field."
                  >
                    <AiOutlineInfoCircle />
                  </Tooltip>
                  <NumberInput
                    size={{ base: "sm", "2xl": "md" }}
                    value={field.numberOptions?.min}
                    isInvalid={isNumberRangeInvalid(field)}
                    onChange={(value) => {
                      const newField: FormTypeField = {
                        ...field,
                        numberOptions: {
                          ...field.numberOptions,
                          min: parseInt(value),
                        },
                      };
                      updateField && updateField(newField);
                    }}
                  >
                    <NumberInputField
                      fontSize={{ base: "md", "2xl": "lg" }}
                      fontWeight="bold"
                      color={`${theme}.800`}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </HStack>
                <HStack>
                  <FormLabel m={0}>Max</FormLabel>
                  <Tooltip
                    shouldWrapChildren
                    label="Maximum value for the number field."
                  >
                    <AiOutlineInfoCircle />
                  </Tooltip>
                  <NumberInput
                    size={{ base: "sm", "2xl": "md" }}
                    isInvalid={isNumberRangeInvalid(field)}
                    value={field.numberOptions?.max}
                    onChange={(value) => {
                      const newField: FormTypeField = {
                        ...field,
                        numberOptions: {
                          ...field.numberOptions,
                          max: parseInt(value),
                        },
                      };
                      updateField && updateField(newField);
                    }}
                  >
                    <NumberInputField
                      fontSize={{ base: "md", "2xl": "lg" }}
                      fontWeight="bold"
                      color={`${theme}.800`}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </HStack>
              </HStack>
            </FormControl>
          )}
          {field.responseType == ResponseType.CHECKBOX && (
            <CheckboxFormTypeField field={field} updateField={updateField} />
          )}

          <Checkbox
            mt={2}
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="semibold"
            color={`${theme}.800`}
            onChange={(e) => {
              const newField: FormTypeField = {
                ...field,
                required: e.target.checked,
              };
              updateField && updateField(newField);
            }}
            isChecked={field.required}
          >
            Required?
          </Checkbox>
        </VStack>
      </HStack>
    </Card>
  );
};

export default CreateFlowField;
