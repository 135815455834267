import {
  Box,
  Grid,
  GridItem,
  VStack,
  HStack,
  Text,
  chakra,
} from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { ReactNode } from "react";

export type SortDirection = "asc" | "desc";

export interface SortConfig<T extends string> {
  column: T;
  direction: SortDirection;
}

export interface ColumnConfig<T extends string> {
  id: T;
  label: string;
  isSortable?: boolean;
  width?: string;
}

interface SharedTableProps<T extends string> {
  columns: ColumnConfig<T>[];
  gridTemplateColumns: string;
  sorting?: SortConfig<T>;
  onSort?: (column: T) => void;
  children: ReactNode;
}

// Base Grid Components
const TableGrid = chakra(Grid, {
  baseStyle: {
    minWidth: "900px",
    gap: 2,
    mb: 4,
  },
});

const TableHeaderGridItem = chakra(GridItem, {
  baseStyle: {
    bg: "gray.50",
    py: 3,
    px: 6,
    borderRadius: "4px",
    textTransform: "uppercase",
    fontWeight: "medium",
    fontSize: "xs",
    color: "gray.600",
  },
});

const SortableTableHeaderGridItem = chakra(TableHeaderGridItem, {
  baseStyle: {
    cursor: "pointer",
    _hover: { bg: "gray.100" },
  },
});

export const TableBodyGrid = chakra(Grid, {
  baseStyle: {
    minWidth: "900px",
    gap: 2,
    mb: 3,
    bg: "white",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    borderRadius: "md",
    transition: "all 0.2s ease",
    _hover: {
      bg: "gray.50",
      transform: "translateY(-1px)",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    },
  },
});

export const TableBodyGridItem = chakra(GridItem, {
  baseStyle: {
    py: 4,
    ps: 6,
    wordBreak: "break-all",
    wordWrap: "break-word",
  },
});

export function SharedTable<T extends string>({
  columns,
  gridTemplateColumns,
  sorting,
  onSort,
  children,
}: SharedTableProps<T>) {
  const renderSortArrows = (column: T) => {
    if (!sorting) return null;

    return (
      <VStack spacing={0} align="center">
        <ChevronUpIcon
          w={3}
          h={3}
          color={
            sorting.column === column && sorting.direction === "asc"
              ? "gray.800"
              : "gray.400"
          }
        />
        <ChevronDownIcon
          w={3}
          h={3}
          marginTop="-2px !important"
          color={
            sorting.column === column && sorting.direction === "desc"
              ? "gray.800"
              : "gray.400"
          }
        />
      </VStack>
    );
  };

  return (
    <Box overflowX="auto" width="100%">
      <TableGrid gridTemplateColumns={gridTemplateColumns}>
        {columns.map((column) => {
          const HeaderComponent =
            column.isSortable && onSort
              ? SortableTableHeaderGridItem
              : TableHeaderGridItem;

          return (
            <HeaderComponent
              key={column.id}
              onClick={
                column.isSortable && onSort
                  ? () => onSort(column.id)
                  : undefined
              }
              {...(column.width ? { width: column.width } : {})}
            >
              <HStack spacing={2} justify="space-between" width="full">
                <Text>{column.label}</Text>
                {column.isSortable && renderSortArrows(column.id)}
              </HStack>
            </HeaderComponent>
          );
        })}
      </TableGrid>
      {children}
    </Box>
  );
}
