// Flag data can be accessed in two ways:
// 1. As part of patient data for immediate display
// 2. Through dedicated flag endpoints for detailed operations

import staffAuth from "./axios/staffAuth";
import { Flag, FlagWithPatient, UpdateFlagDto } from "../types/flag";
import { ApiResponse } from "../types/api";

const API_URL = process.env.REACT_APP_API_URL || "";

export const getAllFlagsForPatient = async (
  patientId: string
): Promise<ApiResponse<Flag[]>> => {
  return staffAuth.get(`${API_URL}/flag/list?patientId=${patientId}`);
};

export const getAllFlagsForOrganization = async (
  organizationId: string
): Promise<ApiResponse<FlagWithPatient[]>> => {
  return staffAuth.get(`${API_URL}/flag/list?organizationId=${organizationId}`);
};

export const getFlag = async (id: string): Promise<ApiResponse<Flag>> => {
  return staffAuth.get(`${API_URL}/flag/${id}`);
};

export const updateFlag = async (
  id: string,
  updatedFlag: UpdateFlagDto
): Promise<ApiResponse<Flag>> => {
  return staffAuth.put(`${API_URL}/flag/${id}`, updatedFlag);
};
