import staffAuth from "../axios/staffAuth";
import { TagDto } from "../../types/protocol";
import { ApiResponse } from "../../types/api";

const API_URL = process.env.REACT_APP_API_URL || "";

// Gets all tags for the current staff
export const getAllTags = async (): Promise<ApiResponse<TagDto[]>> => {
  return staffAuth.get(`${API_URL}/protocol/all-tags`);
};
