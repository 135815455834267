import { ProviderType } from "../constants/provider";
import { Property } from "./protocol";

export enum FormStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  PUBLISHED = "PUBLISHED",
  DEPRECATED = "DEPRECATED",
}

export enum ResponseType {
  PROPERTY = "PROPERTY",
  SHORT_TEXT = "SHORT_TEXT",
  LONG_TEXT = "LONG_TEXT",
  NUMBER = "NUMBER",
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",
  LINEAR_SCALE = "LINEAR_SCALE",
}

export enum OptionsLayout {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export type FormTypeField = {
  key: string; // e.g. q_1, q_2
  label: string; // label for table, graph
  question: string; // question asked to user
  responseType: ResponseType;
  property?: Property;
  metricDefinitionId?: string;
  required?: boolean;
  numberOptions?: {
    // for number question
    min?: number;
    max?: number;
  };
  rangeLabels?: {
    // for radio question
    low?: string;
    high?: string;
  };
  // dictionary key string value string
  options?: Record<string, string>;
  layout?: OptionsLayout;
  isMultiSelect: boolean;
};

export enum FormTemplateId {
  CUSTOM = "CUSTOM",
  PATIENT_ACTIVATION_MEASURE = "PATIENT_ACTIVATION_MEASURE",
  EQ_5D = "EQ_5D",
}

export type FormMetaData = {
  waTemplateId?: string;
};

export type CreateFormDto = {
  message: string;
  fields: FormTypeField[];
  metadata?: FormMetaData;
  name: string;
  description?: string;
  templateId: FormTemplateId;
  postSubmitMsg?: {
    header?: string;
    body?: string;
  };
};

export type FormDto = CreateFormDto & {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  status: FormStatus;
  createdBy: {
    id: string;
    name?: string;
  };
};

export type FormCardData = {
  formId: string;
  status: FormStatus;
  title: string;
  numResponses: number;
  createdBy?: string;
};

export type FormDataDto = {
  id: string;
  patientId: string;
  formId: string;
  rawData: Record<string, string>;
  createdAt: Date;
  updatedAt: Date;
  form?: FormDto;
};

export type FormDataResp = FormDataDto & {
  patientName: string;
};

export type PatientMetricDto = {
  id: string;
  patientId: string;
  propertyId: string;
  value: number;
  formId?: string;
  timestamp: string;
  unit?: string;
  category?: string;
};

export type FormDataListReq = {
  patientIds?: string[];
  formIds?: string[];
};

export type CountResp = {
  formId: string;
  count: number;
};

export type CreateFormSendDto = {
  formId: string;
  channel: ProviderType;
  patientIds: string[];
  schedule?: string;
  remainingSends?: number;
};

export type RespondFormDto = {
  patientId: string;
  formId: string;
  rawData: Record<string, string>;
  channel?: ProviderType;
  sendAcknowledge?: boolean;
};

enum Frequency {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

enum RecurringFormStatus {
  ACTIVE = "active",
  TERMINATED = "terminated",
  COMPLETED = "completed",
}

export type PatientDto = {
  id: string;
  name: string;
};

export type PatientFormScheduleResponse = {
  id: string;
  patient: PatientDto;
  formStatus: RecurringFormStatus;
  createdBy: string;
  frequency: Frequency;
  nextSendDate: Date;
  endDate: Date;
};

export type PatientFormSendDataResponse = {
  id: string;
  patient: PatientDto;
  sendDate: Date;
};
