import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  SimpleGrid,
  useToast,
  Box,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { useEffect, useState } from "react";
import { updatePatient } from "../../../api/patients";
import { PatientDto } from "../../../types/patient";
import { getProviderFriendlyName } from "../../../utils";
import { messagingApps, theme } from "../../../constants";
import { useStaffStore } from "../../../store/staffStore";
import CustomClientField from "../CustomClientField/CustomClientField";
import { ProviderConfig, ProviderType } from "../../../constants/provider";
import { PhoneNumberUtil } from "google-libphonenumber";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { StaffSelect } from "../../StaffSelect";
import { InfoIcon, CheckIcon } from "@chakra-ui/icons";
import { FlagsList } from "../../Flags/FlagsList";

// Shared styles
const sharedFormLabelStyles = {
  fontSize: "sm",
  color: `${theme}.700`,
  fontWeight: "medium",
  mb: 1,
};

const sharedInputStyles = {
  fontSize: "sm",
  color: `${theme}.900`,
  variant: "flushed" as const,
  height: "32px",
};

const sectionStyles = {
  borderColor: "gray.100",
  width: "100%",
  mb: 6,
};

const sectionHeaderStyles = {
  fontSize: "xl",
  fontWeight: "medium",
  color: "blue.900",
};

interface ClientDetailProps {
  patient: PatientDto;
}

// Form-related components
const PhoneNumberField: React.FC<{
  countryCode: string;
  phoneNumber: string;
  onCountryCodeChange: (value: string) => void;
  onPhoneNumberChange: (value: string) => void;
}> = ({
  countryCode,
  phoneNumber,
  onCountryCodeChange,
  onPhoneNumberChange,
}) => {
  const countries = getCountries();

  return (
    <FormControl isRequired>
      <FormLabel {...sharedFormLabelStyles}>Phone number</FormLabel>
      <HStack spacing={2}>
        <Select
          value={countryCode}
          placeholder="Code"
          onChange={(e) => onCountryCodeChange(e.target.value)}
          {...sharedInputStyles}
          w="40%"
          textOverflow="ellipsis"
        >
          {countries.map((country) => (
            <option key={country} value={`+${getCountryCallingCode(country)}`}>
              {country} (+{getCountryCallingCode(country)})
            </option>
          ))}
        </Select>
        <Input
          value={phoneNumber}
          placeholder="Enter phone number"
          onChange={(e) => onPhoneNumberChange(e.target.value)}
          {...sharedInputStyles}
          w="60%"
        />
      </HStack>
    </FormControl>
  );
};

const FormSection: React.FC<{
  title: string;
  children: React.ReactNode;
  showSaveButton?: boolean;
  onSave?: () => void;
  isSaveDisabled?: boolean;
}> = ({ title, children, showSaveButton, onSave, isSaveDisabled }) => (
  <Box {...sectionStyles}>
    <HStack justify="space-between" align="center" mb={6}>
      <Text {...sectionHeaderStyles}>{title}</Text>
      {showSaveButton && (
        <Button
          colorScheme={theme}
          size="sm"
          isDisabled={isSaveDisabled}
          onClick={onSave}
          leftIcon={<CheckIcon />}
          px={4}
          h="32px"
        >
          Save Changes
        </Button>
      )}
    </HStack>
    {children}
  </Box>
);

const ClientDetail: React.FC<ClientDetailProps> = ({ patient }) => {
  const { currentStaff } = useStaffStore();
  const [initialPatient, setInitialPatient] = useState<PatientDto>(patient);
  const [updatedPatient, setUpdatedPatient] = useState<PatientDto>(patient);
  const toast = useToast();

  const phoneUtil = PhoneNumberUtil.getInstance();
  const [patientPhoneCountryCode, setPatientPhoneCountryCode] =
    useState<string>(
      `+${phoneUtil.parse(patient.whatsappPhoneNum, "SG").getCountryCode()}`
    );
  const [patientPhoneNumber, setPatientPhoneNumber] = useState<string>(
    phoneUtil
      .parse(patient.whatsappPhoneNum, "SG")
      .getNationalNumber()
      ?.toString() || ""
  );

  useEffect(() => {
    updatedPatient &&
      setUpdatedPatient({
        ...updatedPatient,
        whatsappPhoneNum: patientPhoneCountryCode + patientPhoneNumber,
      });
  }, [patientPhoneCountryCode, patientPhoneNumber]);

  const isSame = (a: PatientDto, b: PatientDto) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const handleCustomFieldChange = (key: string, value: string) => {
    const newMetadata = {
      ...(updatedPatient.metadata || {}),
      [key]: value,
    };

    setUpdatedPatient({
      ...updatedPatient,
      metadata: newMetadata,
    });
  };

  const handleSubmit = async () => {
    try {
      const updated = await updatePatient(updatedPatient.id, updatedPatient);
      if (updated) {
        setInitialPatient(updated.data);
        setUpdatedPatient(updated.data);
        toast({
          title: "Updated client",
          description: `${updatedPatient.name} has been updated successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Failed to update client",
          description: "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      toast({
        title: "Failed to update client",
        description: err?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={2} align="stretch" width="100%" bg="white" pt={2}>
      <FormSection
        title="Client Details"
        showSaveButton
        onSave={handleSubmit}
        isSaveDisabled={isSame(initialPatient, updatedPatient)}
      >
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
          spacing={{ base: 3, md: 4, lg: 5 }}
          alignItems="start"
        >
          <FormControl isRequired>
            <FormLabel {...sharedFormLabelStyles}>Client ID</FormLabel>
            <Input
              value={updatedPatient?.clientId}
              onChange={(e) =>
                updatedPatient &&
                setUpdatedPatient({
                  ...updatedPatient,
                  clientId: e.target.value,
                })
              }
              {...sharedInputStyles}
              _focus={{
                borderColor: `${theme}.500`,
                boxShadow: "none",
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel {...sharedFormLabelStyles}>Preferred Name</FormLabel>
            <Input
              value={updatedPatient?.preferredName}
              onChange={(e) =>
                updatedPatient &&
                setUpdatedPatient({
                  ...updatedPatient,
                  preferredName: e.target.value,
                })
              }
              {...sharedInputStyles}
              _focus={{
                borderColor: `${theme}.500`,
                boxShadow: "none",
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel {...sharedFormLabelStyles}>Date of Birth</FormLabel>
            <Box width="full">
              <SingleDatepicker
                triggerVariant="input"
                name="date-input"
                date={updatedPatient.dob || undefined}
                maxDate={new Date()}
                onDateChange={(newDate: Date) => {
                  updatedPatient &&
                    setUpdatedPatient({
                      ...updatedPatient,
                      dob: new Date(newDate),
                    });
                }}
                configs={{
                  dateFormat: "d/M/y",
                }}
                propsConfigs={{
                  inputProps: {
                    ...sharedInputStyles,
                    _focus: {
                      borderColor: `${theme}.500`,
                      boxShadow: "none",
                    },
                    width: "full",
                  },
                }}
                usePortal={true}
              />
            </Box>
          </FormControl>
          <FormControl>
            <FormLabel {...sharedFormLabelStyles}>Sex</FormLabel>
            <Select
              value={updatedPatient.sex}
              onChange={(e) =>
                updatedPatient &&
                setUpdatedPatient({ ...updatedPatient, sex: e.target.value })
              }
              {...sharedInputStyles}
              _focus={{
                borderColor: `${theme}.500`,
                boxShadow: "none",
              }}
            >
              <option value="">Select one</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel {...sharedFormLabelStyles}>Address</FormLabel>
            <Input
              value={updatedPatient.address}
              onChange={(e) =>
                updatedPatient &&
                setUpdatedPatient({
                  ...updatedPatient,
                  address: e.target.value,
                })
              }
              {...sharedInputStyles}
              _focus={{
                borderColor: `${theme}.500`,
                boxShadow: "none",
              }}
            />
          </FormControl>
          <PhoneNumberField
            countryCode={patientPhoneCountryCode}
            phoneNumber={patientPhoneNumber}
            onCountryCodeChange={setPatientPhoneCountryCode}
            onPhoneNumberChange={setPatientPhoneNumber}
          />
          <FormControl isRequired>
            <FormLabel {...sharedFormLabelStyles}>Preferred Channel</FormLabel>
            <Select
              value={updatedPatient.preferredChannel}
              onChange={(e) =>
                updatedPatient &&
                setUpdatedPatient({
                  ...updatedPatient,
                  preferredChannel: e.target.value as ProviderType,
                })
              }
              {...sharedInputStyles}
              _focus={{
                borderColor: `${theme}.500`,
                boxShadow: "none",
              }}
            >
              {messagingApps.map((provider) => (
                <option
                  disabled={!ProviderConfig[provider].enabled}
                  key={provider}
                  value={provider}
                >
                  {getProviderFriendlyName(provider)}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel {...sharedFormLabelStyles}>Staff In Charge</FormLabel>
            <Box
              minHeight="32px"
              height="32px"
              fontSize="sm"
              color={`${theme}.900`}
              borderBottom="1px solid"
              borderColor="inherit"
              sx={{
                "& > div": {
                  minHeight: "32px",
                  height: "32px",
                },
                "& input": {
                  height: "32px !important",
                  padding: "0 !important",
                },
                "& > div > div": {
                  padding: "0",
                  margin: "0",
                },
              }}
            >
              <StaffSelect
                defaultSelectedId={updatedPatient.staffId}
                onSelectOption={(staffId) => {
                  updatedPatient &&
                    setUpdatedPatient({
                      ...updatedPatient,
                      staffId,
                    });
                }}
                inputVariant="flushed"
              />
            </Box>
          </FormControl>
          {currentStaff?.organizationClientSchema &&
            currentStaff.organizationClientSchema.length > 0 && (
              <>
                <Box gridColumn="1/-1" pt={4}>
                  <HStack spacing={2} align="center">
                    <Text fontWeight="medium" fontSize="md" color="blue.900">
                      Organization Fields
                    </Text>
                    <Tooltip label="These fields are specific to your organization's requirements">
                      <InfoIcon color="gray.400" w={4} h={4} />
                    </Tooltip>
                  </HStack>
                </Box>
                {currentStaff.organizationClientSchema.map((field, index) => (
                  <Box key={index} position="relative">
                    <CustomClientField
                      field={field}
                      initialValue={
                        patient.metadata != null
                          ? patient.metadata[field.key]
                          : undefined
                      }
                      handleInputChange={handleCustomFieldChange}
                    />
                    <Box
                      position="absolute"
                      top={0}
                      right={-2}
                      w={1}
                      h="full"
                      bg={`${theme}.50`}
                      borderRadius="full"
                    />
                  </Box>
                ))}
              </>
            )}
        </SimpleGrid>
      </FormSection>
      <Box {...sectionStyles}>
        <HStack spacing={2} align="center" mb={4}>
          <Text {...sectionHeaderStyles}>Flags</Text>
          <Tooltip label="Flags indicate important events or conditions that require attention">
            <InfoIcon color="gray.400" w={4} h={4} />
          </Tooltip>
        </HStack>
        <FlagsList patientId={patient.id} />
      </Box>
    </VStack>
  );
};

export default ClientDetail;
