import staffAuth from "../axios/staffAuth";
import {
  CreateOrUpdateOrganizationProtocolDto,
  GetOrganizationProtocolDto,
  ProtocolQueryParams,
} from "../../types/protocol";
import { ApiResponse, PaginatedResponse } from "../../types/api";

const API_URL = process.env.REACT_APP_API_URL || "";

export const getProtocols = async (
  params?: ProtocolQueryParams
): Promise<ApiResponse<PaginatedResponse<GetOrganizationProtocolDto[]>>> => {
  return staffAuth.get(`${API_URL}/protocol`, { params });
};

export const getProtocolById = async (
  id: string
): Promise<ApiResponse<GetOrganizationProtocolDto>> => {
  return staffAuth.get(`${API_URL}/protocol/${id}`);
};

export const createProtocol = async (
  protocol: CreateOrUpdateOrganizationProtocolDto
): Promise<ApiResponse<GetOrganizationProtocolDto>> => {
  return staffAuth.post(`${API_URL}/protocol`, protocol);
};

export const updateProtocol = async (
  id: string,
  protocol: CreateOrUpdateOrganizationProtocolDto
): Promise<ApiResponse<GetOrganizationProtocolDto>> => {
  return staffAuth.put(`${API_URL}/protocol/${id}`, protocol);
};

export const deleteProtocol = async (
  id: string
): Promise<ApiResponse<GetOrganizationProtocolDto>> => {
  return staffAuth.delete(`${API_URL}/protocol/${id}`);
};
