import { Box, VStack } from "@chakra-ui/react";
import { PatientMetricConfig } from "../../../types/patientMetricConfig";
import { TrackerList } from "./TrackerList";
import { MetricSearch } from "./MetricSearch";
import { MetricDefinition } from "../../../types/metricDefinition";
import { useEffect, useState } from "react";
import { getPatientProtocols } from "../../../api/protocol/patientProtocol";
import { GetPatientProtocolDto } from "../../../types/protocol";

interface TrackerPanelProps {
  patient: {
    id: string;
  };
  onSelectMetric: (metricId: string) => void;
  selectedMetricId?: string;
  configs: PatientMetricConfig[];
  metricDefinitions: MetricDefinition[];
  onAddMetric: (
    metricDefinitionId: string,
    config: Omit<PatientMetricConfig, "id" | "metricDefinitionId">
  ) => void;
}

export const TrackerPanel = ({
  patient,
  onSelectMetric,
  selectedMetricId,
  configs,
  metricDefinitions,
  onAddMetric,
}: TrackerPanelProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [protocols, setProtocols] = useState<GetPatientProtocolDto[]>([]);

  useEffect(() => {
    fetchProtocols();
  }, [patient.id]);

  const fetchProtocols = async () => {
    try {
      const response = await getPatientProtocols(patient.id);
      if (response.data) {
        setProtocols(response.data);
      }
    } catch (error) {
      console.error("Error fetching protocols:", error);
    }
  };

  const filteredMetricDefinitions = metricDefinitions.filter((metric) =>
    metric.displayName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <VStack spacing={2} align="stretch" flex={1}>
      <Box position="sticky" top={0} bg="white" zIndex={1} pb={4}>
        <MetricSearch
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
        />
      </Box>
      <Box
        overflowY="auto"
        maxH="calc(100vh - 380px)"
        sx={{
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <TrackerList
          configs={configs}
          metricDefinitions={filteredMetricDefinitions}
          protocols={protocols}
          onSelectMetric={onSelectMetric}
          selectedMetricId={selectedMetricId}
        />
      </Box>
    </VStack>
  );
};
