import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  useToast,
  Box,
  Text,
  VStack,
  HStack,
  Icon,
  Progress,
  Divider,
} from "@chakra-ui/react";
import { theme } from "../../../constants";
import {
  validateCSVForBulkUpload,
  downloadClientTemplate,
} from "../../../utils";
import { FiDownload, FiUpload, FiFile } from "react-icons/fi";
import { useStaffStore } from "../../../store/staffStore";

interface BulkPatientUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    file: File
  ) => Promise<{ data: { results: { successCount: number } } }>;
  onSuccess?: () => void; // Callback to refresh patient list
}

const BulkPatientUploadModal = ({
  isOpen,
  onClose,
  onSubmit,
  onSuccess,
}: BulkPatientUploadModalProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [isValidating, setIsValidating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const currentStaff = useStaffStore((state) => state.currentStaff);

  const handleDownloadTemplate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadClientTemplate(currentStaff?.organizationClientSchema || []);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setIsValidating(true);
      if (selectedFile.type === "text/csv") {
        try {
          const validation = await validateCSVForBulkUpload(
            selectedFile,
            currentStaff?.organizationClientSchema || []
          );
          if (validation.isValid) {
            setFile(selectedFile);
            toast({
              title: "File validated successfully",
              description: "Your CSV file is ready to upload",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          } else {
            throw new Error(validation.error || "Invalid CSV format");
          }
        } catch (error) {
          toast({
            title: "Invalid CSV format",
            description:
              error instanceof Error
                ? error.message
                : "Please ensure your CSV file matches the template format",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setFile(null);
        }
      } else {
        toast({
          title: "Invalid file type",
          description: "Please upload a CSV file",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setFile(null);
      }
      setIsValidating(false);
    }
  };

  const handleSubmit = async () => {
    if (!file) return;

    try {
      setIsUploading(true);
      const result = await onSubmit(file);
      const successCount = result.data.results.successCount;

      toast({
        title: "Upload Complete",
        description: `Successfully created ${successCount} client${
          successCount !== 1 ? "s" : ""
        }.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Reset form
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      // Close modal and refresh list
      onClose();
      onSuccess?.();
    } catch (error) {
      toast({
        title: "Upload failed",
        description:
          error instanceof Error
            ? error.message
            : "An error occurred while uploading clients",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <Box bg={"brand"} roundedTop="md">
          <ModalHeader color={"light"}>Bulk Upload Clients</ModalHeader>
          <ModalCloseButton color={"light"} />
        </Box>
        <ModalBody pb={6}>
          <VStack spacing={6} align="stretch">
            <Text fontSize="md" color="gray.600">
              Upload a CSV file containing client information. Please ensure
              your data follows the template format.
            </Text>

            {/* Template download section */}
            <Box
              borderWidth="1px"
              borderRadius="lg"
              p={4}
              bg="blue.50"
              borderColor="blue.200"
            >
              <VStack align="stretch" spacing={3}>
                <HStack>
                  <Icon as={FiDownload} color="blue.500" />
                  <Text fontWeight="medium" color="blue.700">
                    Need the template?
                  </Text>
                </HStack>
                <Text fontSize="sm" color="gray.600">
                  Download our CSV template to ensure your data is formatted
                  correctly
                </Text>
                <Button
                  leftIcon={<FiDownload />}
                  variant="solid"
                  bg="white"
                  color="blue.600"
                  size="sm"
                  onClick={handleDownloadTemplate}
                  _hover={{
                    bg: "blue.600",
                    color: "white",
                    transform: "translateY(-1px)",
                  }}
                  _active={{
                    bg: "blue.700",
                    transform: "translateY(0)",
                  }}
                  transition="all 0.2s"
                  boxShadow="sm"
                >
                  Download Template
                </Button>
              </VStack>
            </Box>

            <Divider />

            {/* File Upload Section */}
            <FormControl>
              <VStack
                spacing={4}
                borderWidth="2px"
                borderRadius="lg"
                borderStyle="dashed"
                borderColor={file ? "green.300" : "gray.300"}
                bg={file ? "green.50" : "gray.50"}
                p={6}
                position="relative"
                transition="all 0.2s"
                _hover={{ borderColor: file ? "green.400" : `${theme}.500` }}
              >
                <Input
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  opacity={0}
                  cursor="pointer"
                  height="100%"
                  width="100%"
                  disabled={isUploading}
                />

                <Icon
                  as={file ? FiFile : FiUpload}
                  boxSize={8}
                  color={file ? "green.500" : "gray.400"}
                />

                {isValidating || isUploading ? (
                  <VStack spacing={2} width="100%">
                    <Text color="gray.600">
                      {isValidating
                        ? "Validating file..."
                        : "Uploading clients..."}
                    </Text>
                    <Progress size="xs" isIndeterminate width="100%" />
                  </VStack>
                ) : (
                  <VStack spacing={1}>
                    <Text
                      fontWeight="medium"
                      color={file ? "green.600" : "gray.700"}
                    >
                      {file ? file.name : "Drop your CSV file here"}
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      {file ? "File ready to upload" : "or click to browse"}
                    </Text>
                  </VStack>
                )}
              </VStack>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter borderTop="1px" borderColor="gray.200" bg="gray.50">
          <Button
            colorScheme={theme}
            mr={3}
            onClick={handleSubmit}
            isDisabled={!file || isUploading}
            leftIcon={<FiUpload />}
            isLoading={isUploading}
            loadingText="Uploading"
          >
            Upload Clients
          </Button>
          <Button variant="ghost" onClick={onClose} isDisabled={isUploading}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BulkPatientUploadModal;
