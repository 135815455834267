import { FC } from "react";
import { ChartData, ChartDataset } from "chart.js";
import LineChartComponent from "../../components/Patients/LineChartComponent";
import {
  getAllLabels,
  TimePeriod,
  TimePeriodToTimeQuanta,
  TimeQunataToLabelFunc,
} from "../../constants/time";
import { getFirstDate } from "../../constants/time";
import { Center, Text } from "@chakra-ui/react";

export type MetricDataPoint = {
  metricDefinitionId: string;
  value: number;
  timestamp: number;
};

export type MetricToDisplay = {
  id: string;
  name: string;
};

export type MetricGraphProps = {
  /**
   * Data point values grouped by recorded time
   * Each element in metricsGroupedByTime is an array of metrics recorded at the same time
   */
  dataPointsGroupedByTime: MetricDataPoint[][];
  metrics: MetricToDisplay[]; // list of metrics to display on the graph
  selectedTimePeriod: TimePeriod;
  endTime: number;
};

const MetricDefinitionGraph: FC<MetricGraphProps> = (
  props: MetricGraphProps
) => {
  const getGraphData = (): ChartData => {
    if (props.dataPointsGroupedByTime.length === 0)
      return { labels: [], datasets: [] };

    let filtered = filterByMetricDefinitionIds(
      props.dataPointsGroupedByTime,
      props.metrics
    );
    filtered = filterByTimePeriod(
      filtered,
      props.selectedTimePeriod,
      props.endTime
    );

    if (filtered.length === 0) return { labels: [], datasets: [] };
    return {
      labels: getAllLabels(props.selectedTimePeriod, props.endTime),
      datasets: generateDataset(
        filtered,
        props.metrics,
        props.selectedTimePeriod,
        props.endTime
      ),
    };
  };

  const data = getGraphData();
  if (data.datasets.length === 0) {
    return (
      <Center p={4}>
        <Text>No records in the chosen time period</Text>
      </Center>
    );
  }
  return <LineChartComponent data={data} />;
};

function filterByMetricDefinitionIds(
  metrics: MetricDataPoint[][],
  metricsToDisplay: MetricToDisplay[]
): MetricDataPoint[][] {
  const filtered: MetricDataPoint[][] = [];
  metrics.forEach((m) => {
    const filteredMetrics = m.filter((d) =>
      metricsToDisplay.map((p) => p.id).includes(d.metricDefinitionId)
    );
    if (filteredMetrics.length > 0) {
      filtered.push(filteredMetrics);
    }
  });
  return filtered;
}

function generateDataset(
  metricsByTime: MetricDataPoint[][],
  metricsToDisplay: MetricToDisplay[],
  selectedTimePeriod: TimePeriod,
  endTime: number
): ChartDataset[] {
  const allLabels = getAllLabels(selectedTimePeriod, endTime);
  const timeQuanta = TimePeriodToTimeQuanta[selectedTimePeriod];
  const timestampToLabelFunc = TimeQunataToLabelFunc[timeQuanta];

  return metricsToDisplay.map((metric, i) => {
    const metricDataPoints = metricsByTime.map((m) => {
      return m.find((d) => metric.id === d.metricDefinitionId);
    });

    let labelToValueMap: {
      [label: string]: { value: number; timestamp: number };
    } = {};

    metricDataPoints.forEach((m) => {
      if (!m) {
        return;
      }
      const label = timestampToLabelFunc(m.timestamp);
      const existingValue = labelToValueMap[label];
      if (!existingValue) {
        labelToValueMap[label] = {
          value: m.value,
          timestamp: m.timestamp,
        };
        return;
      }
      if (m.timestamp > existingValue.timestamp) {
        // take latest value for time quanta
        labelToValueMap[label] = {
          value: m.value,
          timestamp: m.timestamp,
        };
      }
    });

    const values = allLabels.map((label) => {
      return labelToValueMap[label]?.value ?? null;
    });

    return {
      label: metric.name,
      data: values,
      fill: false,
      borderColor: `rgb(${i * 200}, 99, 132)`,
      tension: 0.1,
      spanGaps: true,
    };
  });
}

function filterByTimePeriod(
  metrics: MetricDataPoint[][],
  timePeriod: TimePeriod,
  endTime: number
): MetricDataPoint[][] {
  const startTime = getFirstDate(timePeriod, endTime).getTime();
  return metrics.filter((m) => {
    const metricTime = m[0].timestamp;
    return metricTime >= startTime && metricTime <= endTime;
  });
}

export { MetricDefinitionGraph };
