import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { useEffect, useState } from "react";
import { PatientDto } from "../../../types/patient";
import { getProviderFriendlyName } from "../../../utils";
import { parseISO } from "date-fns";
import { ProviderConfig, ProviderType } from "../../../constants/provider";
import { messagingApps, theme } from "../../../constants";
import { PhoneNumberUtil } from "google-libphonenumber";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { useStaffStore } from "../../../store/staffStore";
import { StaffDto, StaffAuthLevel } from "../../../types/staff";
import CustomClientField from "../CustomClientField/CustomClientField";
import { StaffSelect } from "../../StaffSelect";

interface PatientModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (patient: PatientDto) => void;
  staffList: StaffDto[];
  initialData: PatientDto | null;
}

type PatientFormData = {
  name: string;
  preferredName?: string;
  nationality: string;
  nationalId: string;
  dob?: Date;
  sex: string;
  address?: string;
  email?: string;
  preferredChannel: ProviderType;
  phoneCountryCode: string;
  phoneNum: string;
  clientId?: string;
  staffId: string;
  metadata?: Record<string, string>;
};

const emptyFormData: PatientFormData = {
  name: "",
  preferredName: "",
  nationality: "",
  nationalId: "",
  dob: undefined,
  sex: "",
  address: undefined,
  email: "",
  preferredChannel: ProviderType.WHATSAPP,
  phoneCountryCode: "",
  phoneNum: "",
  clientId: undefined,
  staffId: "",
  metadata: undefined,
};

const PatientModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  staffList,
}: PatientModalProps) => {
  const countries = getCountries();
  const staff = useStaffStore((state) => state.currentStaff);
  const toast = useToast();
  const [formData, setFormData] = useState<PatientFormData>({
    ...emptyFormData,
    staffId: staff?.id as string,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // phone number logic
  const phoneUtil = PhoneNumberUtil.getInstance();
  const handlePhoneNumberChange = (countryCode: string, number: string) => {
    // validate phone number

    setFormData({
      ...formData,
      phoneCountryCode: countryCode,
      phoneNum: number,
    });
  };

  // current staff for storing staff org schema
  const { currentStaff } = useStaffStore();

  const handleCustomFieldChange = (key: string, value: string) => {
    const newMetadata = {
      ...(formData.metadata || {}),
      [key]: value,
    };

    setFormData({
      ...formData,
      metadata: newMetadata,
    });
  };

  // Add function to detect user's country
  const detectUserCountry = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      const countryCode = `+${getCountryCallingCode(data.country)}`;
      setFormData((prev) => ({ ...prev, phoneCountryCode: countryCode }));
    } catch (error) {
      console.error("Error detecting country:", error);
      // Fallback to SG if detection fails
      setFormData((prev) => ({ ...prev, phoneCountryCode: "+65" }));
    }
  };

  useEffect(() => {
    if (initialData) {
      const parsedPhoneNumber = phoneUtil.parse(
        initialData.whatsappPhoneNum,
        "SG"
      );

      setFormData({
        ...initialData,
        dob:
          typeof initialData.dob === "string"
            ? parseISO(initialData.dob)
            : initialData.dob,
        preferredChannel: initialData.preferredChannel,
        email: initialData.email || "",
        phoneCountryCode: parsedPhoneNumber.getCountryCode()
          ? `+${parsedPhoneNumber.getCountryCode()}`
          : "",
        phoneNum: parsedPhoneNumber.getNationalNumber()?.toString() || "",
        staffId: staff?.id as string,
      });
    } else {
      // Reset the form fields if there's no initial data
      let defaultStaffId = staff?.id as string;

      // If current staff is ADMIN, use first BASIC user from staffList
      if (staff?.authLevel === StaffAuthLevel.ADMIN && staffList.length > 0) {
        const firstBasicStaff = staffList.find(
          (s) => s.authLevel === StaffAuthLevel.BASIC
        );
        if (firstBasicStaff) {
          defaultStaffId = firstBasicStaff.id;
        }
      }

      setFormData({
        ...emptyFormData,
        dob:
          typeof emptyFormData.dob === "string"
            ? parseISO(emptyFormData.dob)
            : emptyFormData.dob,
        staffId: defaultStaffId,
      });
    }
  }, [initialData, staffList]);

  useEffect(() => {
    if (!initialData && !formData.phoneCountryCode) {
      detectUserCountry();
    }
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const patientDto: PatientDto = {
      id: initialData?.id || "",
      name: formData.name,
      preferredName: formData.preferredName,
      nationality: formData.nationality,
      nationalId: formData.nationalId,
      dob: formData.dob,
      sex: formData.sex,
      address: formData.address,
      email: formData.email,
      preferredChannel: formData.preferredChannel,
      whatsappPhoneNum: formData.phoneCountryCode + formData.phoneNum,
      clientId: formData.clientId,
      staffId: formData.staffId,
      metadata: formData.metadata || undefined,
    };
    onSubmit(patientDto);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit}>
        <Box bg={"brand"}>
          <ModalHeader color={"light"}>Add Client</ModalHeader>
          <ModalCloseButton color={"light"} />
        </Box>
        <ModalBody pb={6} maxHeight={"70vh"} overflow={"auto"}>
          <Heading size="md" my={6}>
            Client Information
          </Heading>
          <FormControl isRequired mb={4}>
            <FormLabel>Name</FormLabel>
            <Input value={formData?.name} name="name" onChange={handleChange} />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Preferred Name</FormLabel>
            <Input
              value={formData?.preferredName}
              name="preferredName"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Client ID</FormLabel>
            <Input
              value={formData?.clientId}
              name="clientId"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mb={4} isRequired>
            <FormLabel>Staff In Charge</FormLabel>
            <StaffSelect
              defaultSelectedId={formData.staffId}
              onSelectOption={(staffId: string) => {
                setFormData((prev) => ({ ...prev, staffId }));
              }}
              prefetchedStaffList={staffList}
            />
          </FormControl>
          {/* Commented out for now */}
          {/* <FormControl mb={4}>
            <FormLabel>Nationality</FormLabel>
            <Input
              value={formData?.nationality}
              name="nationality"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>National ID</FormLabel>
            <Input
              value={formData?.nationalId}
              name="nationalId"
              onChange={handleChange}
            />
          </FormControl> */}
          <FormControl mb={4}>
            <FormLabel>Date of Birth</FormLabel>
            <SingleDatepicker
              triggerVariant="input"
              name="date-input"
              date={formData.dob}
              maxDate={new Date()}
              onDateChange={(newDate: Date) =>
                setFormData((prev) => ({ ...prev, dob: newDate }))
              }
              configs={{
                dateFormat: "d/M/y",
              }}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Sex</FormLabel>
            <Select value={formData?.sex} name="sex" onChange={handleChange}>
              <option value="">Select one</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Address</FormLabel>
            <Input
              value={formData?.address}
              name="address"
              onChange={handleChange}
            />
          </FormControl>
          <Heading size="md" my={6}>
            Communication
          </Heading>
          <FormControl mb={3} isRequired>
            <FormLabel>Phone number</FormLabel>
            <HStack spacing={2} align="flex-start">
              <Select
                value={formData.phoneCountryCode}
                placeholder="Code"
                onChange={(e) =>
                  handlePhoneNumberChange(e.target.value, formData.phoneNum)
                }
                w="150px"
              >
                {countries.map((country) => (
                  <option
                    key={country}
                    value={`+${getCountryCallingCode(country)}`}
                  >
                    {country} (+{getCountryCallingCode(country)})
                  </option>
                ))}
              </Select>
              <Input
                value={formData.phoneNum}
                placeholder="Enter phone number"
                onChange={(e) =>
                  handlePhoneNumberChange(
                    formData.phoneCountryCode,
                    e.target.value
                  )
                }
                w="250px"
              />
            </HStack>
          </FormControl>
          {/* <FormControl mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              value={formData?.email}
              name="email"
              onChange={handleChange}
            />
          </FormControl> */}
          <FormControl isRequired mb={4}>
            <FormLabel>Preferred communication channel</FormLabel>
            <RadioGroup
              onChange={(newValue) =>
                setFormData((prev) => ({
                  ...prev,
                  preferredChannel: newValue as ProviderType,
                }))
              }
              value={formData.preferredChannel}
            >
              <VStack alignItems="flex-start" spacing={3}>
                {messagingApps.map((provider) => {
                  return ProviderConfig[provider].enabled ? (
                    <Radio key={provider} value={provider}>
                      <HStack>
                        <Text
                          color={`${theme}.800`}
                          fontSize={{ base: "md", "2xl": "lg" }}
                        >
                          {getProviderFriendlyName(provider)}
                        </Text>
                        {ProviderConfig[provider].icon}
                      </HStack>
                    </Radio>
                  ) : (
                    <Tooltip
                      key={provider}
                      label="Contact Speedback support to activate this channel"
                      shouldWrapChildren
                    >
                      <Radio isDisabled value={provider}>
                        <HStack>
                          <Text
                            color={`${theme}.800`}
                            fontSize={{ base: "md", "2xl": "lg" }}
                          >
                            {getProviderFriendlyName(provider)}
                          </Text>
                          {ProviderConfig[provider].icon}
                        </HStack>
                      </Radio>
                    </Tooltip>
                  );
                })}
              </VStack>
            </RadioGroup>
          </FormControl>
          {currentStaff?.organizationClientSchema &&
            currentStaff.organizationClientSchema.map((field, index) => {
              return (
                <CustomClientField
                  key={index}
                  field={field}
                  initialValue={
                    formData.metadata != null
                      ? formData.metadata[field.key]
                      : undefined
                  }
                  handleInputChange={handleCustomFieldChange}
                  mb={4}
                />
              );
            })}
        </ModalBody>

        <ModalFooter borderTop={"1px"} borderColor={"gray.200"} dropShadow={""}>
          <Button mr={3} type="submit">
            Save
          </Button>
          <Button onClick={onClose} variant={"outline"}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PatientModal;
