import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Flex,
  Icon,
  Spinner,
  useToast,
  Divider,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeftMd, AddPlus } from "react-coolicons";
import { ROUTES } from "../../constants";
import { getMetricDefinitions } from "../../api/metricDefinition";
import { getPatientMetricListV2 } from "../../api/patientMetric";
import { getPatientMetricConfigs } from "../../api/patientMetricConfig";
import { MetricDefinition } from "../../types/metricDefinition";
import { PatientMetricV2Dto } from "../../types/patientMetric";
import { PatientMetricConfig } from "../../types/patientMetricConfig";
import { Logo } from "../../Logo";
import {
  MetricDefinitionGraph,
  MetricDataPoint,
  MetricToDisplay,
} from "../../components/MetricDefinitionGraph";
import { TimePeriod } from "../../constants/time";
import { patientMetricDtoToMetricDataPointsGroupedByTime } from "../../utils/metricDefinition";
import MetricHistoryItem from "../../components/MetricHistoryItem";
import { useMetricDefinitions } from "../../hooks/useMetricDefinitions";

const ClientViewMetrics: React.FC = () => {
  const { groupKey = "", clientId } = useParams<{
    groupKey: string;
    clientId: string;
  }>();
  const navigate = useNavigate();
  const toast = useToast();

  const [metrics, setMetrics] = useState<PatientMetricV2Dto[]>([]);
  const [activeMetricDefinitions, setActiveMetricDefinitions] = useState<
    MetricDefinition[]
  >([]);
  const [groupedMetrics, setGroupedMetrics] = useState<
    Record<string, PatientMetricV2Dto[]>
  >({});
  const [isLoading, setIsLoading] = useState(true);
  const [dataPointsGroupedByTime, setDataPointsGroupedByTime] = useState<
    MetricDataPoint[][]
  >([]);
  const [selectedTimePeriodTab, setSelectedTimePeriodTab] = useState<number>(1);
  const [activeMetricDefinitionIds, setActiveMetricDefinitionIds] = useState<
    string[]
  >([]);
  const { metricDefinitions } = useMetricDefinitions();
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const timePeriodOptions = {
    [TimePeriod.PAST_DAY]: "Day",
    [TimePeriod.PAST_WEEK]: "Week",
    [TimePeriod.PAST_MONTH]: "Month",
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const relevantDefinitions = metricDefinitions.filter(
          (def) => def.metadata.groupKey === groupKey
        );

        if (relevantDefinitions.length === 0) {
          toast({
            title: "Group not found",
            description: "No metrics found for this group",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_HOME}`);
          return;
        }

        setActiveMetricDefinitions(relevantDefinitions);

        const metricDefinitionIds = relevantDefinitions.map((def) => def.id);
        const configsResponse = await getPatientMetricConfigs(
          clientId as string,
          true, // Only fetch active configs
          metricDefinitionIds // Filter by metric definition IDs
        );

        if (!configsResponse.data) {
          toast({
            title: "Failed to fetch patient metric configurations",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        console.log(configsResponse.data);

        const activeIds = configsResponse.data.map(
          (config) => config.metricDefinitionId
        );
        setActiveMetricDefinitionIds(activeIds);

        const metricsResponse = await getPatientMetricListV2({
          patientIds: [clientId as string],
        });

        if (metricsResponse && Array.isArray(metricsResponse)) {
          const filteredMetrics = metricsResponse.filter((metric) =>
            activeIds.includes(metric.metricDefinitionId)
          );

          const sortedMetrics = filteredMetrics.sort(
            (a, b) => parseInt(b.timestamp) - parseInt(a.timestamp)
          );

          setMetrics(sortedMetrics);

          const grouped = groupMetricsByTimestamp(sortedMetrics);
          setGroupedMetrics(grouped);

          const dataPoints =
            patientMetricDtoToMetricDataPointsGroupedByTime(filteredMetrics);
          setDataPointsGroupedByTime(dataPoints);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error",
          description: "Failed to load metric data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (clientId && groupKey) {
      fetchData();
    }
  }, [groupKey, clientId, toast, navigate]);

  const groupMetricsByTimestamp = (
    metrics: PatientMetricV2Dto[]
  ): Record<string, PatientMetricV2Dto[]> => {
    const grouped: Record<string, PatientMetricV2Dto[]> = {};

    metrics.forEach((metric) => {
      if (!grouped[metric.timestamp]) {
        grouped[metric.timestamp] = [];
      }
      grouped[metric.timestamp].push(metric);
    });

    return grouped;
  };

  const handleAddClick = () => {
    navigate(
      `${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_RECORD_METRICS}/${groupKey}`
    );
  };

  const handleBackClick = () => {
    navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_HOME}`);
  };

  const getBloodPressureMetricsToDisplay = (): MetricToDisplay[] => {
    const relevantDefs = activeMetricDefinitions.filter(
      (def) =>
        (def.key === "bp_systolic" || def.key === "bp_diastolic") &&
        activeMetricDefinitionIds.includes(def.id)
    );

    return relevantDefs.map((def) => ({
      id: def.id,
      name: def.displayName,
    }));
  };

  const getMetricsToDisplay = (
    metricDefinition: MetricDefinition
  ): MetricToDisplay[] => {
    if (!activeMetricDefinitionIds.includes(metricDefinition.id)) {
      return [];
    }

    return [
      {
        id: metricDefinition.id,
        name: metricDefinition.displayName,
      },
    ];
  };

  const getTitle = (): string => {
    if (!groupKey) return "Measurements";

    return (
      groupKey
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ") || "Measurements"
    );
  };

  const getUnitDescription = (): string => {
    // For blood pressure, use a special format
    if (groupKey === "blood_pressure") {
      return "mmHg";
    }

    // If it's a single metric, use its unit
    if (activeMetricDefinitions.length === 1) {
      return (
        activeMetricDefinitions[0].unitDescription ||
        activeMetricDefinitions[0].unit ||
        ""
      );
    }

    return "";
  };

  const renderGraphs = () => {
    if (dataPointsGroupedByTime.length === 0) {
      return (
        <Flex justify="center" align="center" h="full" p={6}>
          <Text color="gray.500">No data recorded yet</Text>
        </Flex>
      );
    }

    return (
      <VStack spacing={6} mt={4} mb={6} w="full">
        {groupKey === "blood_pressure" && (
          <>
            {getBloodPressureMetricsToDisplay().length > 0 && (
              <Box
                bg={bgColor}
                borderRadius="md"
                borderWidth="1px"
                borderColor={borderColor}
                p={1}
                overflow="hidden"
                w="full"
              >
                <Heading size="sm" mb={2} px={3} pt={2}>
                  Blood Pressure (mmHg)
                </Heading>
                <MetricDefinitionGraph
                  dataPointsGroupedByTime={dataPointsGroupedByTime}
                  metrics={getBloodPressureMetricsToDisplay()}
                  selectedTimePeriod={
                    Object.keys(timePeriodOptions)[
                      selectedTimePeriodTab
                    ] as TimePeriod
                  }
                  endTime={new Date().getTime()}
                />
              </Box>
            )}

            {activeMetricDefinitions.some(
              (def) =>
                def.key === "heart_rate" &&
                activeMetricDefinitionIds.includes(def.id)
            ) && (
              <Box
                bg={bgColor}
                borderRadius="md"
                borderWidth="1px"
                borderColor={borderColor}
                p={1}
                overflow="hidden"
                w="full"
              >
                <Heading size="sm" mb={2} px={3} pt={2}>
                  Heart Rate (bpm)
                </Heading>
                <MetricDefinitionGraph
                  dataPointsGroupedByTime={dataPointsGroupedByTime}
                  metrics={getMetricsToDisplay(
                    activeMetricDefinitions.find(
                      (def) => def.key === "heart_rate"
                    ) as MetricDefinition
                  )}
                  selectedTimePeriod={
                    Object.keys(timePeriodOptions)[
                      selectedTimePeriodTab
                    ] as TimePeriod
                  }
                  endTime={new Date().getTime()}
                />
              </Box>
            )}
          </>
        )}

        {groupKey !== "blood_pressure" &&
          activeMetricDefinitions
            .filter((def) => activeMetricDefinitionIds.includes(def.id))
            .map((definition) => (
              <Box
                key={definition.id}
                bg={bgColor}
                borderRadius="md"
                borderWidth="1px"
                borderColor={borderColor}
                p={1}
                overflow="hidden"
                w="full"
              >
                <Heading size="sm" mb={2} px={3} pt={2}>
                  {definition.displayName} ({definition.unit})
                </Heading>
                <MetricDefinitionGraph
                  dataPointsGroupedByTime={dataPointsGroupedByTime}
                  metrics={getMetricsToDisplay(definition)}
                  selectedTimePeriod={
                    Object.keys(timePeriodOptions)[
                      selectedTimePeriodTab
                    ] as TimePeriod
                  }
                  endTime={new Date().getTime()}
                />
              </Box>
            ))}
      </VStack>
    );
  };

  const renderHistoryList = () => {
    if (Object.keys(groupedMetrics).length === 0) {
      return (
        <Box
          p={6}
          bg={bgColor}
          borderRadius="md"
          borderWidth="1px"
          borderColor={borderColor}
          textAlign="center"
        >
          <Text color="gray.500">No history available</Text>
        </Box>
      );
    }

    // Use a nested list to group metrics by timestamp first, then by metric type
    return (
      <VStack spacing={6} align="stretch" w="full">
        {Object.entries(groupedMetrics).map(([timestamp, metricsInGroup]) => {
          // Filter to only include metrics with active configurations
          const filteredMetrics = metricsInGroup.filter((metric) =>
            activeMetricDefinitionIds.includes(metric.metricDefinitionId)
          );

          // Skip this timestamp group if there are no active metrics
          if (filteredMetrics.length === 0) {
            return null;
          }

          const sortedMetrics = [...filteredMetrics].sort((a, b) => {
            const defA = activeMetricDefinitions.find(
              (def) => def.id === a.metricDefinitionId
            );
            const defB = activeMetricDefinitions.find(
              (def) => def.id === b.metricDefinitionId
            );
            return (defA?.displayName || "").localeCompare(
              defB?.displayName || ""
            );
          });

          // Group blood pressure measurements together (systolic and diastolic)
          const bloodPressureMetrics: PatientMetricV2Dto[] = [];
          const otherMetrics: PatientMetricV2Dto[] = [];

          sortedMetrics.forEach((metric) => {
            const def = activeMetricDefinitions.find(
              (d) => d.id === metric.metricDefinitionId
            );
            if (
              def &&
              (def.key === "bp_systolic" || def.key === "bp_diastolic")
            ) {
              bloodPressureMetrics.push(metric);
            } else {
              otherMetrics.push(metric);
            }
          });

          return (
            <VStack key={timestamp} spacing={3} align="stretch">
              {bloodPressureMetrics.length > 0 && (
                <BloodPressureHistoryItem
                  metrics={bloodPressureMetrics}
                  metricDefinitions={activeMetricDefinitions}
                  timestamp={timestamp}
                />
              )}

              {otherMetrics.map((metric) => {
                const definition = activeMetricDefinitions.find(
                  (def) => def.id === metric.metricDefinitionId
                );

                return (
                  <MetricHistoryItem
                    key={metric.id || metric.metricDefinitionId}
                    timestamp={timestamp}
                    value={metric.value}
                    unit={definition?.unit || ""}
                    label={definition?.displayName}
                  />
                );
              })}
            </VStack>
          );
        })}
      </VStack>
    );
  };

  // Create a specialized component for blood pressure history items
  const BloodPressureHistoryItem: React.FC<{
    metrics: PatientMetricV2Dto[];
    metricDefinitions: MetricDefinition[];
    timestamp: string;
  }> = ({ metrics, metricDefinitions, timestamp }) => {
    const systolicDef = metricDefinitions.find(
      (def) =>
        def.key === "bp_systolic" && activeMetricDefinitionIds.includes(def.id)
    );
    const diastolicDef = metricDefinitions.find(
      (def) =>
        def.key === "bp_diastolic" && activeMetricDefinitionIds.includes(def.id)
    );

    const systolicMetric = metrics.find(
      (m) => m.metricDefinitionId === systolicDef?.id
    );
    const diastolicMetric = metrics.find(
      (m) => m.metricDefinitionId === diastolicDef?.id
    );

    const systolic = systolicMetric?.value;
    const diastolic = diastolicMetric?.value;

    if (systolic === undefined && diastolic === undefined) {
      return null;
    }

    const bpValue = `${systolic !== undefined ? systolic : "-"}/${
      diastolic !== undefined ? diastolic : "-"
    }`;

    return (
      <MetricHistoryItem
        timestamp={timestamp}
        value={bpValue}
        unit="mmHg"
        label="Blood Pressure"
      />
    );
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <VStack spacing={0} minH="100vh" bg="gray.50" my={5}>
      <Logo />
      <Box w="full" px={4} py={3} position="relative">
        <Flex w="full" align="center" position="relative" minH="40px">
          <Box position="absolute" left={0} zIndex={1}>
            <Icon
              as={ArrowLeftMd}
              color="blue.900"
              fontSize="2xl"
              onClick={handleBackClick}
              cursor="pointer"
            />
          </Box>
          <Box w="full" textAlign="center">
            <Heading as="h1" size="lg">
              {getTitle()}
            </Heading>
            <Text fontSize="md" mt={1} color="gray.600">
              {getUnitDescription()}
            </Text>
          </Box>
          <Box position="absolute" right={0} zIndex={1}>
            <Icon
              as={AddPlus}
              color="blue.900"
              fontSize="2xl"
              onClick={handleAddClick}
              cursor="pointer"
            />
          </Box>
        </Flex>
      </Box>

      <Divider />

      <Box w="full" px={4} pb={6}>
        <Tabs
          index={selectedTimePeriodTab}
          onChange={(index) => setSelectedTimePeriodTab(index)}
          variant="line"
          colorScheme="blue"
          mt={2}
        >
          <TabList>
            {Object.entries(timePeriodOptions).map(([, value], index) => (
              <Tab key={index}>{value}</Tab>
            ))}
          </TabList>
        </Tabs>

        {renderGraphs()}

        <Heading size="md" mb={4}>
          History
        </Heading>

        {renderHistoryList()}
      </Box>
    </VStack>
  );
};

export default ClientViewMetrics;
