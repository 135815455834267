import { Box, HStack, Text, Divider, VStack } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { PatientDto } from "../../types/patient";
import { PatientMetricConfig } from "../../types/patientMetricConfig";
import { TrackerPanel } from "./TrackerPanel";
import { SettingsPanel } from "./SettingsPanel";
import {
  getPatientMetricConfigs,
  createPatientMetricConfig,
  updatePatientMetricConfig,
} from "../../api/patientMetricConfig";
import { MetricDefinition } from "../../types/metricDefinition";
import { getMetricDefinitions } from "../../api/metricDefinition";
import { useToast } from "@chakra-ui/react";
import { useFilteredMetricDefinitions } from "../../hooks/useFilteredMetrics";

interface PatientMonitoringProps {
  patient: PatientDto;
  onUpdate?: () => void;
}

export const PatientMonitoring = ({
  patient,
  onUpdate,
}: PatientMonitoringProps) => {
  const [selectedMetricId, setSelectedMetricId] = useState<string | undefined>(
    undefined
  );
  const [configs, setConfigs] = useState<PatientMetricConfig[]>([]);
  const [fetchedMetricDefinitions, setFetchedMetricDefinitions] = useState<
    MetricDefinition[]
  >([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  // Use the hook to filter metric definitions based on feature flags
  const metricDefinitions = useFilteredMetricDefinitions(
    fetchedMetricDefinitions
  );

  useEffect(() => {
    Promise.all([fetchConfigs(), fetchMetricDefinitions()]).then(() => {
      setLoading(false);
    });
  }, [patient.id]);

  const fetchConfigs = async () => {
    try {
      const response = await getPatientMetricConfigs(patient.id);
      setConfigs(response.data || []);
      return response.data;
    } catch (error) {
      console.error("Error fetching patient metric configs:", error);
      toast({
        title: "Error fetching trackers",
        description: "Unable to load your trackers. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchMetricDefinitions = async () => {
    try {
      const response = await getMetricDefinitions();
      setFetchedMetricDefinitions(response.data || []);
      return response.data;
    } catch (error) {
      console.error("Error fetching metric definitions:", error);
      toast({
        title: "Error loading metrics",
        description: "Unable to load available metrics. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCreateConfig = async (
    metricDefinitionId: string,
    configData: Omit<
      PatientMetricConfig,
      | "id"
      | "metricDefinitionId"
      | "createdBy"
      | "updatedBy"
      | "metricDisplayName"
    >
  ) => {
    try {
      // First create the config
      const createResponse = await createPatientMetricConfig(
        patient.id,
        metricDefinitionId
      );

      if (createResponse.data) {
        // Then update it with the actual configuration
        const updatedConfig = {
          ...createResponse.data,
          ...configData,
          patientId: patient.id,
          metricDefinitionId,
        };

        const updateResponse = await updatePatientMetricConfig(
          createResponse.data.id,
          updatedConfig
        );

        if (updateResponse.data) {
          const updatedConfigs = await fetchConfigs();
          if (updatedConfigs) {
            const metric = metricDefinitions.find(
              (m) => m.id === metricDefinitionId
            );
            toast({
              title: "Tracker added",
              description: `${metric?.displayName} has been added to your trackers.`,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            if (onUpdate) {
              onUpdate();
            }
          }
        }
      }
    } catch (error) {
      console.error("Error creating patient metric config:", error);
      toast({
        title: "Error adding tracker",
        description: "Unable to add the tracker. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdateConfig = async (updatedConfig: PatientMetricConfig) => {
    try {
      const response = await updatePatientMetricConfig(
        updatedConfig.id,
        updatedConfig
      );
      if (response.data) {
        const updatedConfigs = await fetchConfigs();
        if (updatedConfigs) {
          const metric = metricDefinitions.find(
            (m) => m.id === updatedConfig.metricDefinitionId
          );
          toast({
            title: "Tracker updated",
            description: `${metric?.displayName} settings have been updated.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          if (onUpdate) {
            onUpdate();
          }
        }
      }
    } catch (error) {
      console.error("Error updating metric config:", error);
      toast({
        title: "Error updating tracker",
        description: "Unable to update the tracker settings. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  const selectedMetric = metricDefinitions.find(
    (m) => m.id === selectedMetricId
  );
  const selectedConfig = configs.find(
    (c) => c.metricDefinitionId === selectedMetricId
  );

  return (
    <Box minHeight="calc(100vh - 380px)" display="flex" flexDirection="column">
      <HStack align="start" spacing={6} flex={1}>
        <VStack flex={1} align="stretch" spacing={4}>
          <Box position="sticky" top={0} bg="white" zIndex={1} pb={2}>
            <VStack spacing={1} align="start">
              <Text fontSize="xl" fontWeight="medium" color="blue.900">
                Trackers
              </Text>
              <Divider />
            </VStack>
          </Box>

          <TrackerPanel
            patient={{ id: patient.id }}
            onSelectMetric={setSelectedMetricId}
            selectedMetricId={selectedMetricId}
            configs={configs}
            metricDefinitions={metricDefinitions}
            onAddMetric={handleCreateConfig}
          />
        </VStack>

        <VStack flex={3} align="stretch" spacing={4}>
          <Box position="sticky" top={0} bg="white" zIndex={1} pb={2}>
            <VStack spacing={1} align="start">
              <Text fontSize="xl" fontWeight="medium" color="blue.900">
                Settings
              </Text>
              <Divider />
            </VStack>
          </Box>
          <Box
            overflowY="auto"
            maxH="calc(100vh - 380px)"
            sx={{
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <SettingsPanel
              selectedMetricId={selectedMetricId}
              selectedMetric={selectedMetric}
              config={selectedConfig}
              patientId={patient.id}
              onUpdateConfig={handleUpdateConfig}
              onCreateConfig={handleCreateConfig}
            />
          </Box>
        </VStack>
      </HStack>
    </Box>
  );
};
