import { FC, useState, useEffect } from "react";
import {
  Box,
  Card,
  Center,
  Flex,
  Heading,
  HStack,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
  Divider,
  useColorModeValue,
  Badge,
} from "@chakra-ui/react";
import { theme } from "../../constants";
import { PatientDto } from "../../types/patient";
import {
  MetricDefinitionGraph,
  MetricDataPoint,
  MetricToDisplay,
} from "../../components/MetricDefinitionGraph";
import {
  getEndOfDayTime,
  getFirstDate,
  TimePeriod,
} from "../../constants/time";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { getMetricDefinitions } from "../../api/metricDefinition";
import { getPatientMetricListV2 } from "../../api/patientMetric";
import { getPatientMetricConfigs } from "../../api/patientMetricConfig";
import { MetricDefinition } from "../../types/metricDefinition";
import { PatientMetricV2Dto } from "../../types/patientMetric";
import { PatientMetricConfig } from "../../types/patientMetricConfig";
import { patientMetricDtoToMetricDataPointsGroupedByTime } from "../../utils/metricDefinition";
import { format } from "date-fns";
import { useMetricDefinitions } from "../../hooks/useMetricDefinitions";

type MetricDataTrackerProps = {
  patient: PatientDto;
};

interface MetricWithLatestValue {
  definition: MetricDefinition;
  latestMetric?: PatientMetricV2Dto;
  range?: { min?: number; max?: number } | undefined;
}

const MetricDataTracker: FC<MetricDataTrackerProps> = ({ patient }) => {
  const timePeriodOptions = {
    [TimePeriod.PAST_DAY]: "Day",
    [TimePeriod.PAST_WEEK]: "Week",
    [TimePeriod.PAST_MONTH]: "Month",
  };

  const [selectedTimePeriodTab, setSelectedTimePeriodTab] = useState<number>(1); // Default to Week
  const [metricValues, setMetricValues] = useState<PatientMetricV2Dto[]>([]);
  const [dataPointsGroupedByTime, setDataPointsGroupedByTime] = useState<
    MetricDataPoint[][]
  >([]);

  const [patientMetricConfigs, setPatientMetricConfigs] = useState<
    Record<string, PatientMetricConfig>
  >({});
  const [loading, setLoading] = useState(true);
  const [endTime, setEndTime] = useState(new Date().getTime());
  const [metricGroups, setMetricGroups] = useState<
    Record<string, MetricDefinition[]>
  >({});

  const { metricDefinitions } = useMetricDefinitions();
  const cardBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const headingColor = useColorModeValue(`${theme}.700`, `${theme}.300`);
  const subTextColor = useColorModeValue("gray.600", "gray.400");
  const rangeColor = useColorModeValue(`${theme}.700`, `${theme}.300`);

  const getSelectedTimePeriod = (): TimePeriod => {
    return Object.keys(timePeriodOptions)[selectedTimePeriodTab] as TimePeriod;
  };

  const handleTimePeriodChange = (index: number) => {
    setSelectedTimePeriodTab(index);
    setEndTime(new Date().getTime()); // Reset to today
  };

  const getFormattedGroupName = (groupKey: string): string => {
    return groupKey
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const groupMetricDefinitionsByGroup = (
    definitions: MetricDefinition[]
  ): Record<string, MetricDefinition[]> => {
    const groups: Record<string, MetricDefinition[]> = {};

    definitions.forEach((def) => {
      const groupKey = def.metadata.groupKey || "other";
      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }
      groups[groupKey].push(def);
    });

    return groups;
  };

  const getLatestMetric = (
    metricDefinitionId: string
  ): PatientMetricV2Dto | undefined => {
    return metricValues
      .filter((m) => m.metricDefinitionId === metricDefinitionId)
      .sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp))[0];
  };

  const getMetricsToDisplay = (
    definitions: MetricDefinition[]
  ): MetricToDisplay[] => {
    return definitions.map((def) => ({
      id: def.id,
      name: def.displayName,
    }));
  };

  const getUnitForDisplay = (definition: MetricDefinition): string => {
    return definition.unit || "";
  };

  const getMetricsWithLatestValues = (
    groupDefinitions: MetricDefinition[]
  ): MetricWithLatestValue[] => {
    return groupDefinitions.map((def) => {
      const latestMetric = getLatestMetric(def.id);
      const range = getPersonalizedRange(def.id);

      return {
        definition: def,
        latestMetric,
        range,
      };
    });
  };

  const getPersonalizedRange = (
    metricDefinitionId: string
  ): { min?: number; max?: number } | undefined => {
    const config = patientMetricConfigs[metricDefinitionId];
    if (!config || !config.customValues) return undefined;

    const hasMinimum = typeof config.customValues.normalMin === "number";
    const hasMaximum = typeof config.customValues.normalMax === "number";

    if (!hasMinimum && !hasMaximum) return undefined;

    return {
      min: hasMinimum ? config.customValues.normalMin : undefined,
      max: hasMaximum ? config.customValues.normalMax : undefined,
    };
  };

  const formatShortDate = (timestamp: string): string => {
    if (!timestamp) return "";
    const date = new Date(parseInt(timestamp));
    return format(date, "d MMM, h:mm a");
  };

  // Load all data - metrics, metric definitions, and patient metric configs
  const loadData = async () => {
    setLoading(true);
    try {
      const groups = groupMetricDefinitionsByGroup(metricDefinitions);
      setMetricGroups(groups);

      if (patient.id) {
        const metricsResponse = await getPatientMetricListV2({
          patientIds: [patient.id],
        });

        if (metricsResponse) {
          setMetricValues(metricsResponse);

          const dataPoints =
            patientMetricDtoToMetricDataPointsGroupedByTime(metricsResponse);
          setDataPointsGroupedByTime(dataPoints);
        }

        const metricDefIds = metricDefinitions.map((def) => def.id);
        const configsResponse = await getPatientMetricConfigs(
          patient.id,
          true,
          metricDefIds
        );

        if (configsResponse.data) {
          const configsMap: Record<string, PatientMetricConfig> = {};
          configsResponse.data.forEach((config) => {
            configsMap[config.metricDefinitionId] = config;
          });
          setPatientMetricConfigs(configsMap);
        }
      }
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [patient.id, metricDefinitions]);

  if (loading) {
    return (
      <Center minH="200px">
        <Spinner size="xl" color={`${theme}.500`} thickness="4px" />
      </Center>
    );
  }

  const formatRange = (min?: number, max?: number, unit?: string): string => {
    if (min !== undefined && max !== undefined) {
      return `${min}-${max}${unit ? ` ${unit}` : ""}`;
    } else if (min !== undefined) {
      return `>${min}${unit ? ` ${unit}` : ""}`;
    } else if (max !== undefined) {
      return `<${max}${unit ? ` ${unit}` : ""}`;
    }
    return "";
  };

  return (
    <Card p={6} mt={4} boxShadow="md" bg={cardBg}>
      <VStack align="stretch" spacing={6}>
        <Flex
          justify="space-between"
          align="center"
          flexDir={{ base: "column", md: "row" }}
          gap={{ base: 4, md: 0 }}
        >
          <Text fontSize="2xl" fontWeight="semibold" color="blue.900">
            Vital Statistics Dashboard
          </Text>

          <Tabs
            index={selectedTimePeriodTab}
            onChange={handleTimePeriodChange}
            colorScheme={theme}
            size="md"
            variant="solid-rounded"
            flex="0 0 auto"
            width="auto"
          >
            <TabList>
              {Object.entries(timePeriodOptions).map(([, value], index) => (
                <Tab
                  key={index}
                  fontWeight="medium"
                  px={6}
                  py={2}
                  _selected={{ bg: `${theme}.900`, color: "white" }}
                >
                  {value}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </Flex>

        <Divider />

        <HStack justify="flex-end" align="center">
          <HStack spacing={4}>
            <Text fontSize="md" color={subTextColor} fontWeight="medium">
              Date Range:
            </Text>

            <HStack>
              <Text fontSize="md" color="gray.700">
                {format(
                  getFirstDate(getSelectedTimePeriod(), endTime),
                  "MMM d, yyyy"
                )}{" "}
                —
              </Text>

              <SingleDatepicker
                triggerVariant="input"
                name="end-date"
                configs={{
                  dateFormat: "MMM d, yyyy",
                }}
                onDateChange={(date) => {
                  setEndTime(getEndOfDayTime(date));
                }}
                date={new Date(endTime)}
                maxDate={new Date()}
              />
            </HStack>
          </HStack>
        </HStack>
        {metricValues.length > 0 ? (
          <Flex wrap="wrap" justify="space-between" align="stretch">
            {Object.entries(metricGroups).map(([groupKey, definitions]) => {
              const hasAnyMetrics = definitions.some(
                (def) => !!getLatestMetric(def.id)
              );

              if (!hasAnyMetrics) return null;

              const metricsWithValues = getMetricsWithLatestValues(definitions);
              const nonEmptyMetrics = metricsWithValues.filter(
                (m) => m.latestMetric
              );

              if (nonEmptyMetrics.length === 0) return null;

              const primaryMetric = nonEmptyMetrics[0];

              return (
                <Box
                  key={groupKey}
                  width={{ base: "100%", lg: "48%" }}
                  mb={6}
                  borderWidth="1px"
                  borderColor={borderColor}
                  borderRadius="lg"
                  overflow="hidden"
                  boxShadow="sm"
                >
                  <Box p={4} bg={`${theme}.50`}>
                    <Flex justify="space-between" align="center" mb={3}>
                      <Heading
                        color={headingColor}
                        fontSize="lg"
                        fontWeight="semibold"
                      >
                        {getFormattedGroupName(groupKey)}
                      </Heading>

                      <Badge
                        bg={`${theme}.100`}
                        color={`${theme}.700`}
                        px={2}
                        py={1}
                        borderRadius="md"
                        fontSize="xs"
                      >
                        Last:{" "}
                        {formatShortDate(
                          primaryMetric.latestMetric?.timestamp || ""
                        )}
                      </Badge>
                    </Flex>

                    <VStack align="stretch" spacing={4}>
                      <Flex wrap="wrap" gap={4}>
                        {nonEmptyMetrics.map((metric, idx) => {
                          if (!metric.latestMetric) return null;
                          const metricUnit = getUnitForDisplay(
                            metric.definition
                          );
                          const metricRange = metric.range;

                          return (
                            <Box
                              key={idx}
                              bg="white"
                              p={3}
                              borderRadius="md"
                              borderWidth="1px"
                              borderColor="gray.100"
                              flex="1"
                              minWidth="180px"
                            >
                              <Text
                                fontSize="sm"
                                fontWeight="medium"
                                color={subTextColor}
                              >
                                {metric.definition.displayName}
                              </Text>
                              <HStack mt={1}>
                                <Text fontSize="2xl" fontWeight="semibold">
                                  {metric.latestMetric.value}
                                </Text>
                                <Text fontSize="sm">{metricUnit}</Text>
                              </HStack>

                              {metricRange && (
                                <HStack mt={1} color={rangeColor} fontSize="xs">
                                  <Text fontWeight="medium">Target:</Text>
                                  <Text>
                                    {formatRange(
                                      metricRange.min,
                                      metricRange.max,
                                      metricUnit
                                    )}
                                  </Text>
                                </HStack>
                              )}
                            </Box>
                          );
                        })}
                      </Flex>
                    </VStack>
                  </Box>

                  <Box p={2}>
                    <MetricDefinitionGraph
                      dataPointsGroupedByTime={dataPointsGroupedByTime}
                      metrics={getMetricsToDisplay(definitions)}
                      selectedTimePeriod={getSelectedTimePeriod()}
                      endTime={endTime}
                    />
                  </Box>
                </Box>
              );
            })}
          </Flex>
        ) : (
          <Center p={12}>
            <VStack spacing={3}>
              <Text fontSize="lg" color={subTextColor}>
                No metrics recorded yet
              </Text>
              <Text fontSize="sm" color={subTextColor}>
                Metrics will appear here once they are recorded for this patient
              </Text>
            </VStack>
          </Center>
        )}
      </VStack>
    </Card>
  );
};

export { MetricDataTracker };
