import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { createNote, getNotesByPatientId } from "../../../api/patients";
import { getFormDataList } from "../../../api/formData";
import { ROUTES, theme } from "../../../constants";
import {
  PatientActivity,
  PatientDto,
  PatientNoteDto,
} from "../../../types/patient";
import NotesSection from "../PatientDetail/NotesSection";
import { getGroupDateHeader, groupActivitiesByMonth } from "../utils";
import UploadModal from "../UploadModal";
import {
  AiOutlineFileText,
  AiOutlineMessage,
  AiOutlinePlus,
  AiOutlineTag,
  AiOutlineInbox,
  AiOutlineAudit,
  AiOutlineFlag,
  AiOutlineProfile,
  AiOutlineLineChart,
  AiOutlineForm,
} from "react-icons/ai";
import { TagPill } from "../../Tags/TagPill";
import { getProtocolActivitiesByPatientId } from "../../../api/protocol/activity";
import { ProtocolActivityDto, ProtocolStatus } from "../../../types/protocol";
import { useStaffStore } from "../../../store/staffStore";
import { getAuditLogsForPatient } from "../../../api/audit";
import { AuditLog, AuditLogActor, AuditLogSubject } from "../../../types/audit";
import AuditLogContent from "./AuditLogContent";

interface ActivityProps {
  patient: PatientDto;
  refetchPatient: () => Promise<boolean>;
}

interface EmptyStateProps {
  message: string;
  icon: React.ElementType;
}

const EmptyState = ({ message, icon: Icon }: EmptyStateProps) => (
  <Box textAlign="center" py={12}>
    <VStack spacing={4}>
      <Icon size={48} color={`var(--chakra-colors-${theme}-400)`} />
      <Text
        fontSize={{ base: "md", "2xl": "lg" }}
        color={`${theme}.700`}
        fontWeight="medium"
      >
        {message}
      </Text>
    </VStack>
  </Box>
);

const ACTIVITY_TABS = {
  ALL: "All",
  NOTES: "Notes",
  PROTOCOL: "Protocol Activity",
  RESPONSES: "Responses",
  AUDIT_LOGS: "Audit Logs",
} as const;

type ActivityTab = (typeof ACTIVITY_TABS)[keyof typeof ACTIVITY_TABS];

const Activity = ({ patient, refetchPatient }: ActivityProps) => {
  const currentStaff = useStaffStore((state) => state.currentStaff);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [notesActivities, setNotesActivities] = useState<PatientActivity[][]>(
    []
  );
  const [protocolsActivities, setProtocolsActivities] = useState<
    PatientActivity[][]
  >([]);
  const [responsesActivities, setResponsesActivities] = useState<
    PatientActivity[][]
  >([]);
  const [auditActivities, setAuditActivities] = useState<PatientActivity[][]>(
    []
  );
  const [activities, setActivities] = useState<PatientActivity[][]>([]);
  const {
    isOpen: isNotesOpen,
    onOpen: onOpenNotes,
    onClose: onCloseNotes,
  } = useDisclosure();
  const {
    isOpen: isReportOpen,
    onOpen: onOpenReport,
    onClose: onCloseReport,
  } = useDisclosure();

  const toast = useToast();
  const [newNote, setNewNote] = useState<PatientNoteDto>({
    content: "",
    id: "",
    patientId: patient.id,
    staffId: currentStaff?.id || "",
  });
  const [selectedTab, setSelectedTab] = useState<ActivityTab>(
    ACTIVITY_TABS.ALL
  );

  useEffect(() => {
    if (!refresh) {
      return;
    }

    const getActivities = async () => {
      const allActivities: PatientActivity[] = [];
      const notesResp = await getNotesByPatientId(patient.id);
      const notesActivities: PatientActivity[] = notesResp.data.map((note) => {
        return {
          icon: <AiOutlineFileText />,
          header: (
            <>
              <Text
                fontFamily={"heading"}
                fontWeight={"bold"}
                display={"inline"}
              >
                Added note
              </Text>
            </>
          ),
          date: new Date(note.createdDate || ""),
          content: (
            <Text fontWeight={"normal"} whiteSpace={"pre-line"}>
              {note.content}
            </Text>
          ),
          person: note.staffName || "Unknown staff",
        };
      });
      const responsesResp = await getFormDataList({
        patientIds: [patient.id],
      });
      const responsesActivities: PatientActivity[] = responsesResp.map(
        (response) => {
          const content = (
            <>
              Submitted response to{" "}
              <Link
                color={`${theme}.500`}
                href={`${ROUTES.FORMS_LIST}/${response.formId}`}
              >
                {response.form?.name}
              </Link>
            </>
          );
          return {
            icon: <AiOutlineForm />,
            header: (
              <Text
                fontFamily={"heading"}
                fontWeight={"bold"}
                display={"inline"}
              >
                Submitted form: {response.form?.name}
              </Text>
            ),
            date: new Date(response.createdAt),
            content: (
              <Text fontWeight={"normal"} display={"inline"}>
                {content}
              </Text>
            ),
            person: response.patientName,
          };
        }
      );
      const protocolsActivitiesResp = await getProtocolActivitiesByPatientId(
        patient.id
      );
      const protocolsActivities: PatientActivity[] =
        protocolsActivitiesResp.data.map((activity: ProtocolActivityDto) => {
          if (activity.status === ProtocolStatus.ADDED_TAG && activity.tag) {
            const content = (
              <>
                Assigned through protocol{" "}
                <Link
                  color={`${theme}.500`}
                  href={`${ROUTES.PROTOCOLS}/${activity.protocol.id}`}
                >
                  {activity.protocol.name}
                </Link>
              </>
            );
            return {
              icon: <AiOutlineTag />,
              header: (
                <>
                  <Text
                    fontFamily={"heading"}
                    fontWeight={"bold"}
                    display={"inline"}
                  >
                    Assigned tag:{" "}
                  </Text>
                  <TagPill
                    tagColor={activity.tag.color}
                    name={activity.tag.name}
                  />
                </>
              ),
              date: activity.createdAt,
              content: (
                <Text fontWeight={"normal"} display={"inline"}>
                  {content}
                </Text>
              ),
              person: "Speedback",
            };
          } else if (
            activity.status === ProtocolStatus.REMOVED_TAG &&
            activity.tag
          ) {
            const content = (
              <>
                Removed through protocol{" "}
                <Link
                  color={`${theme}.500`}
                  href={`${ROUTES.PROTOCOLS}/${activity.protocol.id}`}
                >
                  {activity.protocol.name}
                </Link>
              </>
            );
            return {
              icon: <AiOutlineTag />,
              header: (
                <>
                  <Text
                    fontFamily={"heading"}
                    fontWeight={"bold"}
                    display={"inline"}
                  >
                    Removed tag:{" "}
                  </Text>
                  <TagPill
                    tagColor={activity.tag.color}
                    name={activity.tag.name}
                  />
                </>
              ),
              date: activity.createdAt,
              content: (
                <Text fontWeight={"normal"} display={"inline"}>
                  {content}
                </Text>
              ),
              person: "Speedback",
            };
          } else if (activity.status === ProtocolStatus.SENT_MESSAGE) {
            const content = (
              <>
                Sent as part of protocol{" "}
                <Link
                  color={`${theme}.500`}
                  href={`${ROUTES.PROTOCOLS}/${activity.protocol.id}`}
                >
                  {activity.protocol.name}
                </Link>
              </>
            );
            return {
              icon: <AiOutlineMessage />,
              header: (
                <>
                  <Text
                    fontFamily={"heading"}
                    fontWeight={"bold"}
                    display={"inline"}
                  >
                    Sent message on client's preferred channel
                  </Text>
                </>
              ),
              date: activity.createdAt,
              content: (
                <Text fontWeight={"normal"} display={"inline"}>
                  {content}
                </Text>
              ),
              person: "Speedback",
            };
          } else {
            throw new Error(
              `Unexpected protocol activity status: ${activity.status}`
            );
          }
        }) || [];

      const auditLogsResp = await getAuditLogsForPatient(patient.id, {
        page: 1,
        size: 50,
      });
      const auditActivities: PatientActivity[] = auditLogsResp.data.data.map(
        (log: AuditLog) => {
          const getSubjectIcon = (subject: AuditLogSubject) => {
            switch (subject) {
              case AuditLogSubject.FLAG:
                return <Icon as={AiOutlineFlag} />;
              case AuditLogSubject.PROTOCOL:
                return <Icon as={AiOutlineProfile} />;
              case AuditLogSubject.PATIENT_METRIC_CONFIG:
                return <Icon as={AiOutlineLineChart} />;
            }
          };

          return {
            icon: getSubjectIcon(log.subject),
            header: <AuditLogContent log={log} variant="header" />,
            date: new Date(log.createdAt),
            content: <AuditLogContent log={log} variant="content" />,
            person:
              log.actor === AuditLogActor.SYSTEM
                ? "System"
                : log.staffName ?? "Staff",
          };
        }
      );

      allActivities.push(
        ...notesActivities,
        ...protocolsActivities,
        ...responsesActivities,
        ...auditActivities
      );
      allActivities.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );

      const groupedAll = groupActivitiesByMonth(allActivities);
      const groupedNotes = groupActivitiesByMonth(notesActivities);
      const groupedProtocols = groupActivitiesByMonth(protocolsActivities);
      const groupedResponses = groupActivitiesByMonth(responsesActivities);
      const groupedAudit = groupActivitiesByMonth(auditActivities);
      setActivities(groupedAll);
      setNotesActivities(groupedNotes);
      setProtocolsActivities(groupedProtocols);
      setResponsesActivities(groupedResponses);
      setAuditActivities(groupedAudit);
    };

    getActivities();
    setRefresh(false);
  }, [patient.id, refresh, patient.tags, patient.documents, patient]);

  const handleNotesSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const created = await createNote({
      ...newNote,
      staffId: currentStaff?.id || "",
    });
    if (created) {
      setNewNote({
        content: "",
        id: "",
        patientId: patient.id,
        staffId: currentStaff?.id || "",
      });
      setRefresh(true);
      toast({
        title: "Note created",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    onCloseNotes();
  };

  const showAddNoteButton =
    selectedTab === ACTIVITY_TABS.ALL || selectedTab === ACTIVITY_TABS.NOTES;

  return (
    <div>
      <Modal isOpen={isNotesOpen} onClose={onCloseNotes}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleNotesSubmit}>
          <Box bg={"brand"}>
            <ModalHeader color={"light"}>Add Note</ModalHeader>
            <ModalCloseButton color={"light"} />
          </Box>
          <ModalBody pb={6} maxHeight={"70vh"} overflow={"auto"}>
            <Heading size="md" my={6}>
              Create Note
            </Heading>
            <FormControl isRequired mb={4}>
              <FormLabel>Content</FormLabel>
              <Textarea
                value={newNote?.content}
                onChange={(e) =>
                  setNewNote({ ...newNote, content: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>

          <ModalFooter
            borderTop={"1px"}
            borderColor={"gray.200"}
            dropShadow={""}
          >
            <Button mr={3} type="submit">
              Save
            </Button>
            <Button onClick={onCloseNotes} variant={"outline"}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <UploadModal
        isReportOpen={isReportOpen}
        onOpenReport={onOpenReport}
        onCloseReport={onCloseReport}
        patientId={patient.id}
        onSubmit={async () => {
          await refetchPatient();
          setRefresh(true);
        }}
      />

      <Tabs
        my={4}
        variant={"solid-rounded"}
        onChange={(index) =>
          setSelectedTab(Object.values(ACTIVITY_TABS)[index])
        }
      >
        <HStack justify={"space-between"}>
          <TabList>
            {Object.values(ACTIVITY_TABS).map((tabName) => (
              <Tab
                key={tabName}
                _selected={{
                  bg: `${theme}.800`,
                  color: "white",
                  fontWeight: "bold",
                }}
                bg={`${theme}.50`}
                color="gray.500"
                fontWeight={"regular"}
                mr={4}
                borderRadius="xl"
                fontSize={{ base: "md", "2xl": "lg" }}
              >
                {tabName}
              </Tab>
            ))}
          </TabList>
          <HStack>
            {showAddNoteButton && (
              <Button
                size={{ base: "md", "2xl": "lg" }}
                leftIcon={<Icon as={AiOutlinePlus} />}
                onClick={onOpenNotes}
              >
                Add Note
              </Button>
            )}
          </HStack>
        </HStack>
        <TabPanels>
          <TabPanel>
            {activities.length > 0 ? (
              activities.map((group, index) => (
                <NotesSection
                  key={`all-${index}-${getGroupDateHeader(group)}`}
                  activities={group}
                  header={getGroupDateHeader(group)}
                />
              ))
            ) : (
              <EmptyState message="No activities yet" icon={AiOutlineInbox} />
            )}
          </TabPanel>
          <TabPanel>
            {notesActivities.length > 0 ? (
              notesActivities.map((group, index) => (
                <NotesSection
                  key={`notes-${index}-${getGroupDateHeader(group)}`}
                  activities={group}
                  header={getGroupDateHeader(group)}
                />
              ))
            ) : (
              <EmptyState message="No notes yet" icon={AiOutlineFileText} />
            )}
          </TabPanel>
          <TabPanel>
            {protocolsActivities.length > 0 ? (
              protocolsActivities.map((group, index) => (
                <NotesSection
                  key={`protocols-${index}-${getGroupDateHeader(group)}`}
                  activities={group}
                  header={getGroupDateHeader(group)}
                />
              ))
            ) : (
              <EmptyState
                message="No protocol activities yet"
                icon={AiOutlineTag}
              />
            )}
          </TabPanel>
          <TabPanel>
            {responsesActivities.length > 0 ? (
              responsesActivities.map((group, index) => (
                <NotesSection
                  key={`responses-${index}-${getGroupDateHeader(group)}`}
                  activities={group}
                  header={getGroupDateHeader(group)}
                />
              ))
            ) : (
              <EmptyState message="No responses yet" icon={AiOutlineForm} />
            )}
          </TabPanel>
          <TabPanel>
            {auditActivities.length > 0 ? (
              auditActivities.map((group, index) => (
                <NotesSection
                  key={`audit-${index}-${getGroupDateHeader(group)}`}
                  activities={group}
                  header={getGroupDateHeader(group)}
                />
              ))
            ) : (
              <EmptyState message="No audit logs yet" icon={AiOutlineAudit} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Activity;
