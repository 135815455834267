import staffAuth from "./axios/staffAuth";
import { AuditLog, AuditLogSubject } from "../types/audit";
import { ApiResponse, PaginatedResponse, PaginationParams } from "../types/api";

const API_URL = process.env.REACT_APP_API_URL || "";

export interface AuditLogQueryParams extends PaginationParams {
  subject?: AuditLogSubject;
  subjectId?: string;
  actorId?: string;
  patientId?: string;
  fromDate?: Date;
  toDate?: Date;
}

export const getAuditLogs = async (
  params: AuditLogQueryParams
): Promise<ApiResponse<PaginatedResponse<AuditLog[]>>> => {
  return staffAuth.get(`${API_URL}/audit/list`, { params });
};

export const getAuditLogsForPatient = async (
  patientId: string,
  params: PaginationParams
): Promise<ApiResponse<PaginatedResponse<AuditLog[]>>> => {
  return staffAuth.get(`${API_URL}/audit/list`, {
    params: { ...params, patientId },
  });
};
