export const TEMPLATE_CATEGORIES = [
  "Onboarding",
  "Health Management",
  "Follow-up Appointment",
  "Medication",
  "Sales",
] as const;

export type TemplateCategory = (typeof TEMPLATE_CATEGORIES)[number];

export enum MessagePurpose {
  REGULAR = "regular",
  SPEEDBACK = "speedback",
  BD_INTRO = "bd_intro",
  PAYMENT_LINK = "payment_link",
}

interface MessagePurposeConfig {
  label: string;
  description: string;
  templateId?: string;
  isDisabled?: boolean;
  disabledReason?: string;
  icon?: React.ComponentType;
  badgeText?: string;
  badgeColor?: string;
  requiresInternalAccess?: boolean;
}

export const MESSAGE_PURPOSE_CONFIG: Record<
  MessagePurpose,
  MessagePurposeConfig
> = {
  [MessagePurpose.REGULAR]: {
    label: "Regular Message",
    description: "Send a normal text message",
    isDisabled: false,
    badgeText: "no link",
    badgeColor: "blue",
  },
  [MessagePurpose.SPEEDBACK]: {
    label: "Open Speedback",
    description: "Direct patients to Speedback",
    templateId: "custom_workflow_template_v1",
    badgeText: "with link",
    badgeColor: "purple",
  },
  [MessagePurpose.BD_INTRO]: {
    label: "Sales Outreach",
    description: "Send a message with a calendar link to schedule a meeting",
    templateId: "bd_template",
    badgeText: "CTA",
    badgeColor: "purple",
    requiresInternalAccess: true,
  },
  [MessagePurpose.PAYMENT_LINK]: {
    label: "Payment Link",
    description: "Send a message with a Stripe payment link",
    templateId: "stripe_payment_link",
    badgeText: "CTA",
    badgeColor: "purple",
  },
};
