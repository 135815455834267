import {
  Box,
  Container,
  Heading,
  Stack,
  useToast,
  Text,
  HStack,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import {
  bulkCreatePatients,
  createPatient,
  getPatientList,
  updatePatient,
} from "../../api/patients";
import Header from "../../components/Head";
import PatientModal from "../../components/Patients/PatientModal";
import {
  DataSorting,
  PatientTable,
} from "../../components/Patients/PatientTable";
import TablePagination from "../../components/TablePagination";
import { PatientDto } from "../../types/patient";
import BulkUploadModal from "../../components/Patients/BulkPatientUploadModal";
import { getAllStaffList } from "../../api/staff";
import { StaffDto } from "../../types/staff";
import { StaffSelect } from "../../components/StaffSelect";
import { useStaffStore } from "../../store/staffStore";
import { ClientHeader } from "../../components/ClientHeader";
import { FaSearch } from "react-icons/fa";
import { debounce } from "lodash";
import AddClientMenu from "../../components/Patients/AddClientMenu";

export default function ViewPatientsPage() {
  // State
  const [isModalOpen, setModalOpen] = useState(false);
  const [isBulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allPatients, setAllPatients] = useState<PatientDto[]>([]);
  const [nameFilter, setNameFilter] = useState("");
  const [staffFilter, setStaffFilter] = useState("");
  const [selectedPatient, setSelectedPatient] = useState<PatientDto | null>(
    null
  );
  const [staffList, setStaffList] = useState<StaffDto[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sorting, setSorting] = useState<DataSorting>({
    column: "lastActivity",
    direction: "desc",
  });

  const currentStaff = useStaffStore((state) => state.currentStaff);
  const toast = useToast();

  // Data fetching
  const fetchAllPatients = async () => {
    try {
      setLoading(true);
      const resp = await getPatientList({
        size: 1000,
        page: 1,
      });
      if (resp.data) {
        setAllPatients(resp.data.data);
      }
    } catch (error: any) {
      if (error.response?.status === 401) return;
      toast({
        title: "Failed to fetch patient info",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getStaffDetails = async () => {
    if (!currentStaff?.organizationId) return;

    const resp = await getAllStaffList(currentStaff.organizationId);
    if (resp.message) {
      toast({
        title: "Failed to fetch staff info",
        description: resp.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setStaffList(resp.data || []);
  };

  // Filtering and sorting
  const getFilteredPatients = useCallback(() => {
    return allPatients.filter((patient) => {
      const matchesStaff =
        !staffFilter ||
        staffFilter === "all" ||
        patient.staffId === staffFilter;
      const matchesSearch =
        !nameFilter ||
        patient.name.toLowerCase().includes(nameFilter.toLowerCase());
      return matchesStaff && matchesSearch;
    });
  }, [allPatients, nameFilter, staffFilter]);

  const getSortedPatients = useCallback(() => {
    const filtered = getFilteredPatients();
    return filtered.sort((a, b) => {
      const multiplier = sorting.direction === "asc" ? 1 : -1;

      switch (sorting.column) {
        case "name":
          return multiplier * a.name.localeCompare(b.name);

        case "clientId":
          return (
            multiplier * (a.clientId || "").localeCompare(b.clientId || "")
          );

        case "lastActivity":
          const dateA = new Date(a.lastActivity || 0).getTime();
          const dateB = new Date(b.lastActivity || 0).getTime();
          return multiplier * (dateA - dateB);

        default:
          return 0;
      }
    });
  }, [getFilteredPatients, sorting]);

  const getPaginatedPatients = useCallback(() => {
    const sorted = getSortedPatients();
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return sorted.slice(start, end);
  }, [getSortedPatients, currentPage, itemsPerPage]);

  // Event handlers
  const handleStaffFilter = (staffId: string) => {
    setStaffFilter(staffId);
    setCurrentPage(1);
  };

  const handleModalClose = () => setModalOpen(false);

  const handleSubmitBulkUpload = async (file: File) => {
    try {
      const response = await bulkCreatePatients(file);
      return {
        data: { results: { successCount: response.data.results.created } },
      };
    } catch (error: any) {
      toast({
        title: "Bulk upload failed",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  const handleSubmitPatient = async (patientData: PatientDto) => {
    try {
      if (selectedPatient) {
        await updatePatient(selectedPatient.id, patientData);
        toast({
          title: "Patient updated",
          description: `Patient ${patientData.name} has been updated successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const newPatient = await createPatient(patientData);
        setAllPatients([...allPatients, newPatient.data]);
        toast({
          title: "Created new patient",
          description: `Patient ${newPatient.data.name} has been created successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      setModalOpen(false);
      fetchAllPatients();
    } catch (error: any) {
      toast({
        title: `Failed to ${selectedPatient ? "update" : "create"} patient`,
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Effects
  useEffect(() => {
    fetchAllPatients();
    getStaffDetails();
  }, []);

  const paginatedPatients = getPaginatedPatients();
  const totalFilteredPatients = getFilteredPatients().length;

  return (
    <>
      <Header description="Manage clients" />
      <ClientHeader
        mode="navigate"
        onSearch={(value) => {
          setNameFilter(value);
          setCurrentPage(1);
        }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Heading
            fontSize={{ base: "3xl", "2xl": "4xl" }}
            textTransform="uppercase"
          >
            Clients
          </Heading>
          <AddClientMenu
            onAddSingle={() => {
              setSelectedPatient(null);
              setModalOpen(true);
            }}
            onBulkUpload={() => setBulkUploadModalOpen(true)}
          />
        </Flex>
      </ClientHeader>

      <Container maxW={"9xl"}>
        <Stack spacing={4}>
          <HStack spacing={2} align="center">
            <Text
              fontSize={{ base: "sm", "2xl": "md" }}
              color="gray.600"
              minW="80px"
            >
              Filter By Staff:
            </Text>
            <StaffSelect
              includeAllOption
              onSelectOption={handleStaffFilter}
              prefetchedStaffList={staffList}
              inputWidth="200px"
            />
          </HStack>

          <Box mt={6}>
            <PatientTable
              patients={paginatedPatients}
              loading={loading}
              sorting={sorting}
              setSorting={setSorting}
            />
            <TablePagination
              currentPage={currentPage}
              totalRows={totalFilteredPatients}
              currentPageRows={paginatedPatients.length}
              onPageChange={setCurrentPage}
              rowsPerPage={itemsPerPage}
              onChangeRowsPerPage={setItemsPerPage}
            />
          </Box>
          <PatientModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            onSubmit={handleSubmitPatient}
            initialData={selectedPatient}
            staffList={staffList}
          />

          <BulkUploadModal
            isOpen={isBulkUploadModalOpen}
            onClose={() => setBulkUploadModalOpen(false)}
            onSubmit={handleSubmitBulkUpload}
            onSuccess={fetchAllPatients}
          />
        </Stack>
      </Container>
    </>
  );
}
