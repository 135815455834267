import {
  VStack,
  Text,
  useToast,
  Spinner,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Icon,
  Center,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PatientDto } from "../../types/patient";
import { getPatientById } from "../../api/patients";
import { ShareAndroid, Copy, Camera } from "react-coolicons";
import { ROUTES } from "../../constants";
import { extractImageData } from "../../api/llm";
import { ImageExtractedMeasurement, VitalType } from "../../types/llm";
import _ from "lodash";
import { Logo } from "../../Logo";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { getPatientMetricConfigs } from "../../api/patientMetricConfig";
import { getMetricDefinitions } from "../../api/metricDefinition";
import { MetricDefinition } from "../../types/metricDefinition";
import { PatientMetricConfig } from "../../types/patientMetricConfig";
import { ClientMetricCard } from "../../components/ClientMetricCard";
import { useFeatureEnabled } from "../../hooks/useFeatureEnabled";
import { FeatureName } from "../../components/FeatureToggle/constants";
import { generateAuth } from "../../api/terra";
import { useMetricDefinitions } from "../../hooks/useMetricDefinitions";

// Define a type for our unified metric group
interface MetricGroup {
  groupKey: string;
  displayName: string;
  metrics: {
    definition: MetricDefinition;
    config: PatientMetricConfig;
  }[];
}

const ClientHome = () => {
  const [loading, setLoading] = useState(true);
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  const [patient, setPatient] = useState<PatientDto>();
  const [metricConfigs, setMetricConfigs] = useState<PatientMetricConfig[]>([]);

  // Replace groupedMetrics and standaloneMetrics with a single unified structure
  const [metricGroups, setMetricGroups] = useState<MetricGroup[]>([]);
  const { metricDefinitions } = useMetricDefinitions();
  const toast = useToast();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const imageUploadRef = useRef<HTMLInputElement>(null);

  const { enabled: isWearablesEnabled } = useFeatureEnabled(
    FeatureName.WEARABLES
  );

  const handleShare = async () => {
    onOpen();
  };

  const handleCopy = async () => {
    const viewVitalsUrl = window.location.href.replace(
      ROUTES.CLIENT_HOME,
      ROUTES.CLIENT_VIEW_ALL_METRIC_DATA
    );
    const message =
      "I've started recording my health information on Speedback, my digital health passport. Click here to view my information: " +
      viewVitalsUrl;

    try {
      await navigator.clipboard.writeText(message);
      toast({
        title: "Message copied!",
        description: "You can now share this with your trusted caregiver",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to copy message",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCameraClick = () => {
    imageUploadRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type.startsWith("image/")) {
        await handleImageUpload(file);
      } else {
        toast({
          title: "Invalid file type",
          description: "Please upload a valid image file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleImageUpload = async (file: File) => {
    try {
      setIsImageProcessing(true);
      const imageExtractedMeasurement = (await extractImageData(
        file
      )) as ImageExtractedMeasurement;

      let metricDefToNavigate: MetricDefinition | undefined;

      switch (imageExtractedMeasurement.measurement.type) {
        case VitalType.BLOOD_PRESSURE:
          navigate(
            `${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_RECORD_METRICS}/blood_pressure`,
            { state: { imageExtractedMeasurement } }
          );
          break;

        case VitalType.BLOOD_GLUCOSE:
          metricDefToNavigate = metricDefinitions.find(
            (def) => def.key === "blood_glucose"
          );
          if (metricDefToNavigate) {
            const groupKey =
              metricDefToNavigate.metadata.groupKey || "blood_glucose";
            navigate(
              `${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_RECORD_METRICS}/${groupKey}`,
              { state: { imageExtractedMeasurement } }
            );
          }
          break;

        case VitalType.WEIGHT:
          metricDefToNavigate = metricDefinitions.find(
            (def) => def.key === "weight"
          );
          if (metricDefToNavigate) {
            const groupKey = metricDefToNavigate.metadata.groupKey || "weight";
            navigate(
              `${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_RECORD_METRICS}/${groupKey}`,
              { state: { imageExtractedMeasurement } }
            );
          }
          break;

        default:
          toast({
            title: "Unsupported measurement",
            description: "We couldn't process this type of measurement.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
      }

      toast({
        title: "Scanning complete!",
        description: `We've detected a ${_.startCase(
          imageExtractedMeasurement.measurement.type
        )} reading, which we have auto-filled. Please review it before submitting.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error processing image:", error);
      toast({
        title: "An error occurred",
        description:
          "Unable to process the image. Please fill in the values manually.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsImageProcessing(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        if (clientId) {
          const resp = await getPatientById(clientId);
          if (resp.data) {
            setPatient(resp.data);
          } else {
            toast({
              title: "Failed to fetch patient",
              description: "Patient data not found",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }

        if (clientId) {
          const configsResp = await getPatientMetricConfigs(clientId, true);
          if (configsResp.data) {
            setMetricConfigs(configsResp.data);

            // Organize metrics into groups using a more unified approach
            const groupMap = new Map<string, MetricGroup>();
            const definitionsMap = new Map(
              metricDefinitions.map((def) => [def.id, def])
            );

            configsResp.data.forEach((config) => {
              const definition = definitionsMap.get(config.metricDefinitionId);
              if (definition) {
                // Use the metadata.groupKey if available, otherwise use the definition key
                const groupKey = definition.metadata.groupKey || definition.key;

                // Format group display name
                const displayName = groupKey
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ");

                // Create or update group
                if (!groupMap.has(groupKey)) {
                  groupMap.set(groupKey, {
                    groupKey,
                    displayName,
                    metrics: [],
                  });
                }

                // Add metric to the group
                const group = groupMap.get(groupKey)!;
                group.metrics.push({
                  definition,
                  config,
                });
              }
            });

            // Convert map to array and sort groups alphabetically by displayName
            const groups = Array.from(groupMap.values()).sort((a, b) =>
              a.displayName.localeCompare(b.displayName)
            );

            setMetricGroups(groups);
          }
        }

        setLoading(false);
      } catch (error: any) {
        toast({
          title: "Failed to fetch data",
          description: error?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
    };

    fetchData();
  }, [clientId, toast, metricDefinitions]);

  return (
    <Box minH="100vh" bg="gray.50">
      {isImageProcessing && <LoadingOverlay />}

      <Box pt={4} px={4} pb={0} maxW="container.sm" mx="auto">
        <Logo />

        {loading ? (
          <Center h="80vh">
            <Spinner size="xl" color="blue.500" />
          </Center>
        ) : (
          <>
            <Box w="full" my={6}>
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                w="full"
                mb={2}
              >
                <Box>
                  <Heading fontSize="2xl" fontWeight="semibold" mb={1}>
                    Hello, {patient?.preferredName || patient?.name} 👋
                  </Heading>
                  <Text fontSize="md" color="gray.600">
                    What are you recording today?
                  </Text>
                </Box>
                <Button
                  onClick={handleCameraClick}
                  colorScheme="blue"
                  borderRadius="full"
                  width="40px"
                  height="40px"
                  p={0}
                  boxShadow="md"
                >
                  <Icon as={Camera} boxSize={5} />
                </Button>
              </Flex>
            </Box>

            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              ref={imageUploadRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />

            <VStack spacing={2} w="full" align="stretch">
              {metricGroups.map((group) => (
                <ClientMetricCard
                  key={group.groupKey}
                  metricDefinition={group.metrics[0].definition}
                  metricConfig={group.metrics[0].config}
                  clientId={clientId as string}
                  groupData={{
                    groupKey: group.groupKey,
                    metricsCount: group.metrics.length,
                  }}
                />
              ))}
            </VStack>

            {isWearablesEnabled && (
              <Button
                mt={2}
                w="full"
                onClick={async () => {
                  try {
                    if (clientId) {
                      const successRedirectUrl = `${window.location.origin}${ROUTES.CLIENTS}/${clientId}${ROUTES.TERRA_AUTH_SUCCESS}`;
                      const failureRedirectUrl = `${window.location.origin}${ROUTES.CLIENTS}/${clientId}${ROUTES.TERRA_AUTH_ERROR}`;
                      const terraWidget = await generateAuth(
                        clientId,
                        successRedirectUrl,
                        failureRedirectUrl
                      );
                      console.log(terraWidget);

                      if (
                        terraWidget &&
                        terraWidget.data &&
                        terraWidget.data.url
                      ) {
                        window.location.href = terraWidget.data.url;
                      } else {
                        toast({
                          title: "Error connecting with Terra",
                          description:
                            "Unable to generate authentication URL. Please try again later.",
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                        });
                      }
                    }
                  } catch (error) {
                    console.error("Error redirecting to Terra widget:", error);
                  }
                }}
                colorScheme="blue"
                fontSize="md"
                size="lg"
              >
                Integrate Wearable
              </Button>
            )}

            <Box w="full" textAlign="center" my={4}>
              <Button
                leftIcon={<ShareAndroid />}
                onClick={handleShare}
                colorScheme="blue"
                variant="ghost"
                fontSize="md"
              >
                Share with caregiver
              </Button>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Share with Caregivers</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Text mb={4}>
                    Invite caregivers to your health passport so they know how
                    you're doing, and can add measurements on your behalf.
                  </Text>
                  <Alert status="warning" mb={4}>
                    <AlertIcon />
                    Please only share this link and your PIN with trusted
                    caregivers.
                  </Alert>
                  <Button
                    leftIcon={<Copy />}
                    onClick={handleCopy}
                    colorScheme="blue"
                    size="lg"
                    width="full"
                  >
                    Copy Link
                  </Button>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ClientHome;
