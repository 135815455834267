import { Badge, HStack, Text, Tooltip } from "@chakra-ui/react";
import { ClockIcon } from "@heroicons/react/24/outline";
import {
  FlagSeverity,
  FlagStatus,
  Flag,
  FlagTrigger,
} from "../../../types/flag";
import {
  getRelativeTimeWithTooltip,
  formatFlagDateTime,
} from "../../../utils/date";

export const getSeverityColor = (severity: FlagSeverity) => {
  switch (severity) {
    case FlagSeverity.CRITICAL:
      return "red";
    case FlagSeverity.WARN:
      return "orange";
    case FlagSeverity.INFO:
      return "gray";
    default:
      return "gray";
  }
};

export const formatSeverity = (severity: string): string => {
  return severity.charAt(0).toUpperCase() + severity.slice(1).toLowerCase();
};

export const getSeverityWeight = (severity: FlagSeverity): number => {
  switch (severity) {
    case FlagSeverity.CRITICAL:
      return 3;
    case FlagSeverity.WARN:
      return 2;
    case FlagSeverity.INFO:
      return 1;
    default:
      return 0;
  }
};

export const isResolved = (status: FlagStatus) => {
  return status === FlagStatus.RESOLVED;
};

export const getFlagDescription = (flag: Flag) => {
  let value = "";
  if (flag.trigger === FlagTrigger.METRIC) {
    value = `${flag.metricValue} ${flag.metricUnit}`;
  } else if (flag.trigger === FlagTrigger.NON_ADHERENCE) {
    if (flag.daysSinceSubmission) {
      value = `${flag.daysSinceSubmission} days non-adherent`;
    } else {
      value = `Non-adherence, no submissions yet`;
    }
  }

  return flag.trigger === FlagTrigger.METRIC
    ? `${flag.metricDisplayName} has been reported out of their assigned usual range at ${value}`
    : `${flag.metricDisplayName} reading - ${value}`;
};

export const sortFlags = <T extends Flag>(
  flags: T[],
  sortColumn: string,
  sortDirection: "asc" | "desc"
) => {
  return [...flags].sort((a, b) => {
    if (sortColumn !== "default") {
      const multiplier = sortDirection === "asc" ? 1 : -1;

      switch (sortColumn) {
        case "severity":
          return (
            multiplier *
            (getSeverityWeight(b.severity) - getSeverityWeight(a.severity))
          );
        case "clientName":
          if ("patientName" in a && "patientName" in b) {
            return (
              multiplier *
              (a.patientName as string).localeCompare(b.patientName as string)
            );
          }
          return 0;
        case "time":
          return (
            multiplier *
            (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          );
        default:
          return 0;
      }
    } else {
      if (a.status !== b.status) {
        // First, handle the case where statuses are different
        if (a.status === "RESOLVED") return 1; // Push RESOLVED to the back
        if (b.status === "RESOLVED") return -1; // Keep non-RESOLVED at the front
      }

      // When statuses are the same, sort by date (newest first)
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    }
  });
};

export const renderTimeColumn = (createdAt: Date) => {
  return getRelativeTimeWithTooltip(createdAt).showTooltip ? (
    <Tooltip label={formatFlagDateTime(createdAt)} placement="top">
      <HStack spacing={1} align="center">
        {getRelativeTimeWithTooltip(createdAt).display === "Just Now" && (
          <ClockIcon
            style={{
              width: "14px",
              height: "14px",
              color: "var(--chakra-colors-blue-500)",
            }}
          />
        )}
        <Text fontSize="sm" color="gray.500" whiteSpace="nowrap">
          {getRelativeTimeWithTooltip(createdAt).display}
        </Text>
      </HStack>
    </Tooltip>
  ) : (
    <Text fontSize="sm" color="gray.500" whiteSpace="nowrap">
      {getRelativeTimeWithTooltip(createdAt).display}
    </Text>
  );
};

export const renderSeverityBadge = (severity: FlagSeverity) => (
  <Badge
    colorScheme={getSeverityColor(severity)}
    px={2}
    py={1}
    borderRadius="md"
    textTransform="capitalize"
    fontWeight="medium"
  >
    {formatSeverity(severity)}
  </Badge>
);
