import staffAuth from "../axios/staffAuth";
import {
  CreatePatientProtocolDto,
  UpdatePatientProtocolDto,
  GetPatientProtocolDto,
} from "../../types/protocol";
import { ApiResponse } from "../../types/api";

const API_URL = process.env.REACT_APP_API_URL || "";

export const getPatientProtocols = async (
  patientId: string
): Promise<ApiResponse<GetPatientProtocolDto[]>> => {
  return staffAuth.get(`${API_URL}/patient/protocol`, {
    params: {
      patientId,
    },
  });
};

export const getPatientProtocolById = async (
  protocolId: string
): Promise<ApiResponse<GetPatientProtocolDto>> => {
  return staffAuth.get(`${API_URL}/patient/protocol/${protocolId}`);
};

export const createPatientProtocol = async (
  protocol: CreatePatientProtocolDto
): Promise<ApiResponse<GetPatientProtocolDto>> => {
  return staffAuth.post(`${API_URL}/patient/protocol`, protocol);
};

export const updatePatientProtocol = async (
  protocolId: string,
  protocol: UpdatePatientProtocolDto
): Promise<ApiResponse<GetPatientProtocolDto>> => {
  return staffAuth.put(`${API_URL}/patient/protocol/${protocolId}`, protocol);
};

export const deletePatientProtocol = async (
  protocolId: string
): Promise<ApiResponse<void>> => {
  return staffAuth.delete(`${API_URL}/patient/protocol/${protocolId}`);
};
