export enum FlagTrigger {
  METRIC = "METRIC",
  NON_ADHERENCE = "NON_ADHERENCE",
}

export enum FlagStatus {
  OPEN = "OPEN",
  RESOLVED = "RESOLVED",
  REOPENED = "REOPENED",
}

export enum FlagSeverity {
  INFO = "INFO",
  WARN = "WARN",
  CRITICAL = "CRITICAL",
}

export interface Flag {
  id: string;
  patientId: string;
  protocolId: string;
  patientMetricId?: string;
  trigger: FlagTrigger;
  status: FlagStatus;
  severity: FlagSeverity;
  metricDisplayName: string;
  metricValue?: string;
  metricUnit?: string;
  nonAdherenceDays?: number;
  daysSinceSubmission?: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  updatedBy?: string;
  deletedBy?: string;
}

// Extended type that includes patient information
export interface FlagWithPatient extends Flag {
  patientName: string;
}

export interface UpdateFlagDto {
  id: string;
  trigger: FlagTrigger;
  status: FlagStatus;
  severity: FlagSeverity;
}
