import { useState, useEffect } from "react";
import { MetricDefinition } from "../types/metricDefinition";
import { getMetricDefinitions } from "../api/metricDefinition";
import { useFilteredMetricDefinitions } from "./useFilteredMetrics";

/**
 * Hook to fetch and filter metric definitions
 * @returns An object containing the raw and filtered metric definitions, loading state, and error state
 */
export const useMetricDefinitions = () => {
  const [rawMetricDefinitions, setRawMetricDefinitions] = useState<
    MetricDefinition[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // Use the existing hook to filter the metric definitions
  const filteredMetricDefinitions =
    useFilteredMetricDefinitions(rawMetricDefinitions);

  useEffect(() => {
    const fetchMetricDefinitions = async () => {
      try {
        setLoading(true);
        const response = await getMetricDefinitions();
        setRawMetricDefinitions(response.data || []);
        setError(null);
      } catch (err) {
        console.error("Error fetching metric definitions:", err);
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setLoading(false);
      }
    };

    fetchMetricDefinitions();
  }, []);

  return {
    metricDefinitions: filteredMetricDefinitions,
    rawMetricDefinitions,
    loading,
    error,
  };
};
