import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";

interface MetricSearchProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
}

export const MetricSearch = ({
  searchQuery,
  onSearchChange,
}: MetricSearchProps) => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <FaSearch color="gray.50" />
      </InputLeftElement>
      <Input
        placeholder="Search for Trackers"
        value={searchQuery}
        onChange={(e) => onSearchChange(e.target.value)}
        bg="white"
        borderRadius="md"
        _focus={{
          boxShadow: "none",
          borderColor: "blue.700",
        }}
      />
    </InputGroup>
  );
};
