import { ProviderType } from "./provider";

export const ROUTES = {
  FORMS_LIST: "/forms",
  CLIENTS: "/clients",
  CREATE_FORM: "/create-form",
  RESPONSES: "/responses",
  LOGIN: "/login",
  REGISTER: "/register",
  SETTINGS: "/settings",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSCODE: "/reset-passcode",
  NOT_FOUND: "/not-found",
  FORM_RESPONSE: "/form-response",
  FORM: "/form",
  PROTOCOLS: "/protocols",
  REPORT: "/report",
  EXPERIMENTAL: "/experimental",
  CLIENT_PASS: "/client-pass",
  TERRA_AUTH_SUCCESS: "/client-pass/terra-auth-success",
  TERRA_AUTH_ERROR: "/client-pass/terra-auth-error",
  METRIC_FORM: "/metric-form",
  VIEW_METRIC_DATA: "/view-metric-data",
  ADMIN: "/admin",
  FLAGS: "/flags",
  CHATS: "/chats",
  LABS: "/labs",
  CLIENT_FORMS: "/client-forms",
  FORM_HISTORY: "/form-history",
  CLIENT_METRIC_DETAIL: "/metric-detail",
  CLIENT_THANK_YOU: "/thank-you",
  CLIENT_HOME: "/home",
  CLIENT_VIEW_ALL_METRIC_DATA: "/view-all-metric-data",
  CLIENT_RECORD_METRICS: "/record-metrics",
  CLIENT_VIEW_METRICS: "/view-metrics",
  CLIENT_NUTRITION: "/nutrition",
  ACCESS_UNAVAILABLE: "/access-unavailable",
};

export const theme = "blue";
export const backgroundColor = "white";

export const messagingApps: ProviderType[] = [
  ProviderType.WHATSAPP,
  ProviderType.TELEGRAM,
  ProviderType.FB_MESSENGER,
];
