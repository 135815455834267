import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Image,
  Container,
  useColorModeValue,
} from "@chakra-ui/react";
import { LogoWithWords } from "../../LogoWithWords";

const AccessUnavailable = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Box bg={bgColor} minH="100vh" py={10} px={4}>
      <Container maxW="md">
        <VStack spacing={8} align="center">
          <LogoWithWords />

          <Box
            w="full"
            borderWidth="1px"
            borderRadius="lg"
            borderColor={borderColor}
            bg={cardBgColor}
            p={8}
            boxShadow="md"
          >
            <VStack spacing={6} align="center">
              <Image
                src="https://cdn-icons-png.flaticon.com/512/6195/6195678.png"
                alt="Premium Access Required"
                boxSize="100px"
                opacity={0.8}
              />

              <Heading size="lg" textAlign="center">
                Access Required
              </Heading>

              <Text textAlign="center" color="gray.600">
                This application requires access. Please contact your healthcare
                provider for more information about upgrading your account.
              </Text>
            </VStack>
          </Box>

          <Text fontSize="sm" color="gray.500" textAlign="center">
            If you believe you should have access to this application, please
            contact your healthcare provider for assistance.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};

export default AccessUnavailable;
