import {
  Box,
  useToast,
  Text,
  Spinner,
  Center,
  Checkbox,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { theme } from "../../constants";
import { Flag, FlagSeverity, FlagStatus } from "../../types/flag";
import { getAllFlagsForPatient, updateFlag } from "../../api/flag";
import {
  getSeverityColor,
  renderSeverityBadge,
  renderTimeColumn,
  getFlagDescription,
  isResolved,
  sortFlags,
} from "./utils";
import {
  SharedTable,
  TableBodyGrid,
  TableBodyGridItem,
  SortConfig,
} from "../SharedTable";

interface FlagsListProps {
  patientId: string;
}

type SortColumn = "severity" | "time" | "default";

// Grid template columns for the flags table: Status, Severity, Flag Details, Time
const GRID_TEMPLATE_COLUMNS = "1fr 1.5fr minmax(0, 4fr) 1.5fr";

export const FlagsList: React.FC<FlagsListProps> = ({ patientId }) => {
  const [loading, setLoading] = useState(true);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [sorting, setSorting] = useState<SortConfig<SortColumn>>({
    column: "default",
    direction: "desc",
  });
  const [flags, setFlags] = useState<Flag[]>([]);
  const toast = useToast();

  const fetchFlags = async () => {
    try {
      setLoading(true);
      const response = await getAllFlagsForPatient(patientId);
      if (response.data) {
        if (!initiallyLoaded) {
          const sortedFlags = sortFlags(response.data, "default", "desc");
          setFlags(sortedFlags);
          setInitiallyLoaded(true);
        } else {
          setFlags(response.data);
        }
      }
    } catch (error) {
      toast({
        title: "Error fetching flags",
        description: "Failed to load flags for this patient",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFlags();
  }, [patientId]);

  useEffect(() => {
    if (flags.length > 0 && initiallyLoaded) {
      const sortedFlags = sortFlags(
        [...flags],
        sorting.column,
        sorting.direction
      );
      setFlags(sortedFlags);
    }
  }, [sorting.direction, sorting.column]);

  const handleStatusChange = async (flag: Flag) => {
    try {
      const newStatus = isResolved(flag.status)
        ? FlagStatus.REOPENED
        : FlagStatus.RESOLVED;

      const { id, trigger, severity } = flag;
      const response = await updateFlag(id, {
        id,
        trigger,
        severity,
        status: newStatus,
      });

      if (response.data) {
        setFlags((prevFlags) =>
          prevFlags.map((f) =>
            f.id === flag.id ? { ...f, status: newStatus } : f
          )
        );

        toast({
          title: `Flag ${newStatus.toLowerCase()}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating flag status:", error);
      toast({
        title: "Failed to update flag status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSort = (column: SortColumn) => {
    setSorting((prev) => ({
      column,
      direction:
        prev.column === column && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const sortedFlags = [...flags].sort((a, b) => {
    // First sort by status (resolved flags go to the bottom)
    if (a.status !== b.status) {
      return a.status === FlagStatus.RESOLVED ? 1 : -1;
    }

    if (sorting.column !== "default") {
      const multiplier = sorting.direction === "asc" ? 1 : -1;

      switch (sorting.column) {
        case "severity":
          // Sort by severity (critical > warn > info)
          const severityOrder = {
            [FlagSeverity.CRITICAL]: 0,
            [FlagSeverity.WARN]: 1,
            [FlagSeverity.INFO]: 2,
          };
          return (
            multiplier * (severityOrder[a.severity] - severityOrder[b.severity])
          );

        case "time":
          // Sort by creation time
          return (
            multiplier *
            (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          );

        default:
          return 0;
      }
    } else {
      // Default sorting: resolved flags by time only, unresolved flags by severity then time
      if (a.status === FlagStatus.RESOLVED) {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }

      // For unresolved flags, sort by severity first
      const severityOrder = {
        [FlagSeverity.CRITICAL]: 0,
        [FlagSeverity.WARN]: 1,
        [FlagSeverity.INFO]: 2,
      };
      const severityDiff =
        severityOrder[a.severity] - severityOrder[b.severity];
      if (severityDiff !== 0) return severityDiff;

      // Finally sort by time (newest first)
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    }
  });

  const flagColumns = [
    { id: "status" as SortColumn, label: "Status", isSortable: false },
    { id: "severity" as SortColumn, label: "Severity", isSortable: true },
    { id: "details" as SortColumn, label: "Flag Details", isSortable: false },
    { id: "time" as SortColumn, label: "Time", isSortable: true },
  ];

  return (
    <Box>
      {loading ? (
        <Center py={8}>
          <Spinner color={`${theme}.500`} />
        </Center>
      ) : flags.length === 0 ? (
        <Text color="gray.500" py={4} textAlign="center">
          No flags found for this patient
        </Text>
      ) : (
        <SharedTable
          columns={flagColumns}
          gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
          sorting={sorting}
          onSort={handleSort}
        >
          {flags.map((flag) => (
            <TableBodyGrid
              key={flag.id}
              gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
              opacity={isResolved(flag.status) ? 0.6 : 1}
              textDecoration={isResolved(flag.status) ? "line-through" : "none"}
            >
              <TableBodyGridItem>
                <Checkbox
                  isChecked={isResolved(flag.status)}
                  colorScheme={theme}
                  size="lg"
                  onChange={() => handleStatusChange(flag)}
                />
              </TableBodyGridItem>

              <TableBodyGridItem>
                {renderSeverityBadge(flag.severity)}
              </TableBodyGridItem>

              <TableBodyGridItem>
                <Text
                  fontSize="sm"
                  color={`${getSeverityColor(flag.severity)}.600`}
                  fontWeight="medium"
                >
                  {getFlagDescription(flag)}
                </Text>
              </TableBodyGridItem>

              <TableBodyGridItem>
                {renderTimeColumn(flag.createdAt)}
              </TableBodyGridItem>
            </TableBodyGrid>
          ))}
        </SharedTable>
      )}
    </Box>
  );
};
