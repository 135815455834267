import { PatientMetricV2Dto } from "./../types/patientMetric";
import { PatientMetricDto } from "../types/form";
import { getCommonAuth } from "./axios/commonAuth";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

async function createPatientMetricV2(
  dto: PatientMetricV2Dto
): Promise<PatientMetricV2Dto> {
  const axios = getCommonAuth();
  const res = await axios.post<PatientMetricV2Dto>(
    `${API_URL}/patientMetric`,
    dto
  );
  return res.data;
}

async function createAllPatientMetricsV2(
  dtos: PatientMetricV2Dto[]
): Promise<PatientMetricV2Dto[]> {
  const axios = getCommonAuth();
  const res = await axios.post<PatientMetricV2Dto[]>(
    `${API_URL}/patientMetric/all`,
    dtos
  );
  return res.data;
}

async function getPatientMetricListV2(req?: {
  formIds?: string[];
  patientIds?: string[];
}): Promise<PatientMetricV2Dto[] | null> {
  const axios = getCommonAuth();
  const res = await axios.get<PatientMetricV2Dto[]>(
    `${API_URL}/patientMetric/list`,
    { params: req }
  );
  return res.data;
}

async function createPatientMetric(
  dto: PatientMetricDto
): Promise<PatientMetricDto> {
  const axios = getCommonAuth();
  const res = await axios.post<PatientMetricDto>(
    `${API_URL}/patientMetric`,
    dto
  );
  return res.data;
}

async function createAllPatientMetrics(
  dtos: PatientMetricDto[]
): Promise<PatientMetricDto[]> {
  const axios = getCommonAuth();
  const res = await axios.post<PatientMetricDto[]>(
    `${API_URL}/patientMetric/all`,
    dtos
  );
  return res.data;
}

async function updatePatientMetric(
  dto: PatientMetricDto
): Promise<PatientMetricDto> {
  const axios = getCommonAuth();
  const res = await axios.put<PatientMetricDto>(
    `${API_URL}/patientMetric`,
    dto
  );
  return res.data;
}

async function getPatientMetricList(req?: {
  formIds?: string[];
  patientIds?: string[];
}): Promise<PatientMetricDto[] | null> {
  const axios = getCommonAuth();
  const res = await axios.get<PatientMetricDto[]>(
    `${API_URL}/patientMetric/list`,
    {
      params: req,
    }
  );
  return res.data;
}

async function searchPatientMetrics(req: {
  patientId: string;
  search: string;
}): Promise<PatientMetricDto[] | null> {
  const axios = getCommonAuth();
  const res = await axios.get<PatientMetricDto[]>(
    `${API_URL}/patientMetric/search`,
    {
      params: req,
    }
  );

  return res.data;
}

// Mock data function
function getMockPatientMetrics(req?: {
  formIds?: string[];
  patientIds?: string[];
}): Promise<PatientMetricV2Dto[]> {
  // Create a base timestamp for today and work backward
  const now = new Date().getTime();
  const dayInMs = 24 * 60 * 60 * 1000;
  const hourInMs = 60 * 60 * 1000;

  // Mock data for a test client - using same patientId as in PatientMetricConfig
  const patientId = "0ef7e3c1-b1fe-49fb-886f-14eadfeb41a7";

  const mockPatientMetrics: PatientMetricV2Dto[] = [
    // Blood Pressure Group - 3 days ago
    {
      id: "1",
      patientId: patientId,
      metricDefinitionId: "1",
      value: 125,
      timestamp: (now - 3 * dayInMs).toString(),
      unit: "mmHg",
    },
    {
      id: "2",
      patientId: patientId,
      metricDefinitionId: "2",
      value: 82,
      timestamp: (now - 3 * dayInMs).toString(),
      unit: "mmHg",
    },
    {
      id: "3",
      patientId: patientId,
      metricDefinitionId: "3",
      value: 75,
      timestamp: (now - 3 * dayInMs).toString(),
      unit: "bpm",
    },

    // Blood Pressure Group - 7 days ago
    {
      id: "4",
      patientId: patientId,
      metricDefinitionId: "1",
      value: 130,
      timestamp: (now - 7 * dayInMs).toString(),
      unit: "mmHg",
    },
    {
      id: "5",
      patientId: patientId,
      metricDefinitionId: "2",
      value: 85,
      timestamp: (now - 7 * dayInMs).toString(),
      unit: "mmHg",
    },
    {
      id: "6",
      patientId: patientId,
      metricDefinitionId: "3",
      value: 78,
      timestamp: (now - 7 * dayInMs).toString(),
      unit: "bpm",
    },

    // Weight - several data points
    {
      id: "7",
      patientId: patientId,
      metricDefinitionId: "4",
      value: 75.5,
      timestamp: (now - 1 * dayInMs).toString(),
      unit: "kg",
    },
    {
      id: "8",
      patientId: patientId,
      metricDefinitionId: "4",
      value: 76.0,
      timestamp: (now - 5 * dayInMs).toString(),
      unit: "kg",
    },
    {
      id: "9",
      patientId: patientId,
      metricDefinitionId: "4",
      value: 76.2,
      timestamp: (now - 10 * dayInMs).toString(),
      unit: "kg",
    },
    {
      id: "10",
      patientId: patientId,
      metricDefinitionId: "4",
      value: 77.1,
      timestamp: (now - 15 * dayInMs).toString(),
      unit: "kg",
    },

    // Blood Glucose - several data points
    {
      id: "11",
      patientId: patientId,
      metricDefinitionId: "5",
      value: 5.2,
      timestamp: (now - 1 * dayInMs).toString(),
      unit: "mmol/L",
    },
    {
      id: "12",
      patientId: patientId,
      metricDefinitionId: "5",
      value: 5.7,
      timestamp: (now - 4 * dayInMs).toString(),
      unit: "mmol/L",
    },
    {
      id: "13",
      patientId: patientId,
      metricDefinitionId: "5",
      value: 5.4,
      timestamp: (now - 8 * dayInMs).toString(),
      unit: "mmol/L",
    },

    // SpO2 - a couple of points
    {
      id: "14",
      patientId: patientId,
      metricDefinitionId: "7",
      value: 98,
      timestamp: (now - 2 * dayInMs).toString(),
      unit: "%",
    },
    {
      id: "15",
      patientId: patientId,
      metricDefinitionId: "7",
      value: 97,
      timestamp: (now - 9 * dayInMs).toString(),
      unit: "%",
    },

    // Temperature - one data point
    {
      id: "16",
      patientId: patientId,
      metricDefinitionId: "8",
      value: 37.2,
      timestamp: (now - 3 * dayInMs).toString(),
      unit: "°C",
    },

    // SAME DAY READINGS AT DIFFERENT TIMES

    // Blood Pressure - Morning (8 AM)
    {
      id: "17",
      patientId: patientId,
      metricDefinitionId: "1",
      value: 128,
      timestamp: (now - 12 * hourInMs).toString(), // 12 hours ago
      unit: "mmHg",
    },
    {
      id: "18",
      patientId: patientId,
      metricDefinitionId: "2",
      value: 84,
      timestamp: (now - 12 * hourInMs).toString(), // 12 hours ago
      unit: "mmHg",
    },

    // Blood Pressure - Evening (6 PM)
    {
      id: "19",
      patientId: patientId,
      metricDefinitionId: "1",
      value: 132,
      timestamp: (now - 2 * hourInMs).toString(), // 2 hours ago
      unit: "mmHg",
    },
    {
      id: "20",
      patientId: patientId,
      metricDefinitionId: "2",
      value: 88,
      timestamp: (now - 2 * hourInMs).toString(), // 2 hours ago
      unit: "mmHg",
    },

    // Blood Glucose - Same day, different times
    {
      id: "21",
      patientId: patientId,
      metricDefinitionId: "5",
      value: 4.8, // Fasting (morning)
      timestamp: (now - 14 * hourInMs).toString(), // Early morning
      unit: "mmol/L",
    },
    {
      id: "22",
      patientId: patientId,
      metricDefinitionId: "5",
      value: 5.6, // After lunch
      timestamp: (now - 6 * hourInMs).toString(), // After lunch
      unit: "mmol/L",
    },
    {
      id: "23",
      patientId: patientId,
      metricDefinitionId: "5",
      value: 6.2, // After dinner
      timestamp: (now - 1 * hourInMs).toString(), // After dinner
      unit: "mmol/L",
    },

    // Weight - Same day different times (morning and evening)
    {
      id: "24",
      patientId: patientId,
      metricDefinitionId: "4",
      value: 75.2, // Morning weight
      timestamp: (now - 12 * hourInMs).toString(),
      unit: "kg",
    },
    {
      id: "25",
      patientId: patientId,
      metricDefinitionId: "4",
      value: 75.8, // Evening weight
      timestamp: (now - 3 * hourInMs).toString(),
      unit: "kg",
    },

    // Respiratory Rate - Multiple entries to show pattern
    {
      id: "26",
      patientId: patientId,
      metricDefinitionId: "6",
      value: 15,
      timestamp: (now - 1 * dayInMs).toString(), // Yesterday
      unit: "bpm",
    },
    {
      id: "27",
      patientId: patientId,
      metricDefinitionId: "6",
      value: 17,
      timestamp: (now - 3 * dayInMs).toString(), // 3 days ago
      unit: "bpm",
    },
    {
      id: "28",
      patientId: patientId,
      metricDefinitionId: "6",
      value: 16,
      timestamp: (now - 5 * dayInMs).toString(), // 5 days ago
      unit: "bpm",
    },
    {
      id: "29",
      patientId: patientId,
      metricDefinitionId: "6",
      value: 18,
      timestamp: (now - 7 * dayInMs).toString(), // 7 days ago
      unit: "bpm",
    },

    // Respiratory Rate - Same day, different readings (morning/evening)
    {
      id: "30",
      patientId: patientId,
      metricDefinitionId: "6",
      value: 14,
      timestamp: (now - 10 * hourInMs).toString(), // Morning
      unit: "bpm",
    },
    {
      id: "31",
      patientId: patientId,
      metricDefinitionId: "6",
      value: 16,
      timestamp: (now - 4 * hourInMs).toString(), // Evening
      unit: "bpm",
    },

    // Add respiratory rate to some blood pressure groups to test grouping
    {
      id: "32",
      patientId: patientId,
      metricDefinitionId: "6",
      value: 15,
      timestamp: (now - 12 * hourInMs).toString(), // Same time as BP morning
      unit: "bpm",
    },
    {
      id: "33",
      patientId: patientId,
      metricDefinitionId: "6",
      value: 16,
      timestamp: (now - 2 * hourInMs).toString(), // Same time as BP evening
      unit: "bpm",
    },
  ];

  // Filter by patientIds if provided
  let filtered = mockPatientMetrics;
  if (req?.patientIds && req.patientIds.length > 0) {
    filtered = filtered.filter((metric) =>
      req.patientIds?.includes(metric.patientId)
    );
  }

  // Filter by formIds if provided
  if (req?.formIds && req.formIds.length > 0) {
    filtered = filtered.filter(
      (metric) => metric.formId && req.formIds?.includes(metric.formId)
    );
  }

  return Promise.resolve(filtered);
}

export {
  createPatientMetric,
  createAllPatientMetrics,
  getPatientMetricList,
  searchPatientMetrics,
  updatePatientMetric,
  createPatientMetricV2,
  createAllPatientMetricsV2,
  getPatientMetricListV2,
};
