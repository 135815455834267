import React from "react";
import { Text, Icon, Card, VStack, HStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MetricDefinition } from "../../types/metricDefinition";
import { PatientMetricConfig } from "../../types/patientMetricConfig";
import { ROUTES } from "../../constants";
import { ChartBarVertical01 } from "react-coolicons";
import { FaChevronRight } from "react-icons/fa";

interface ClientMetricCardProps {
  metricDefinition: MetricDefinition;
  metricConfig: PatientMetricConfig;
  clientId: string;
  groupData?: {
    groupKey: string;
    metricsCount: number;
  };
}

export const ClientMetricCard: React.FC<ClientMetricCardProps> = ({
  metricDefinition,
  metricConfig,
  clientId,
  groupData,
}) => {
  const navigate = useNavigate();

  const onCardClick = () => {
    const groupKey = groupData
      ? groupData.groupKey
      : metricDefinition.metadata.groupKey || metricDefinition.key;

    navigate(
      `${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_VIEW_METRICS}/${groupKey}`
    );
  };

  const formatGroupTitle = (groupKey: string) => {
    return groupKey
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const isSingleMetric = !groupData || groupData.metricsCount === 1;

  return (
    <Card variant={"elevated"} onClick={onCardClick} cursor="pointer" mb={2}>
      <VStack minW={"3xs"} m={4} spacing={4} alignItems={"left"}>
        <HStack justifyContent={"space-between"} width="100%">
          <Icon
            as={ChartBarVertical01}
            background={"gray.100"}
            fontSize={"2xl"}
            color={"blue.900"}
          />
          <Icon as={FaChevronRight} color={"gray.400"} fontSize={"md"} />
        </HStack>
        <HStack justifyContent={"space-between"}>
          <VStack spacing={0} alignItems={"left"} textAlign={"left"}>
            {!isSingleMetric ? (
              <>
                <Text fontSize={"xl"} color={"blue.900"}>
                  {formatGroupTitle(groupData.groupKey)}
                </Text>
                <Text fontSize={"md"} color={"gray.600"}>
                  {groupData.metricsCount} measurements
                </Text>
              </>
            ) : (
              <>
                <Text fontSize={"xl"} color={"blue.900"}>
                  {metricDefinition.displayName}
                </Text>
                <Text fontSize={"md"} color={"blue.900"}>
                  {`in ${metricDefinition.unit}`}
                </Text>
              </>
            )}
          </VStack>
        </HStack>
      </VStack>
    </Card>
  );
};
