import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { PatientActivity } from "../../../types/patient";
import { theme } from "../../../constants";

interface NotesSectionProps {
  header: string;
  activities: PatientActivity[];
}
const NotesSection = ({ header, activities }: NotesSectionProps) => {
  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  return (
    <VStack align={"left"} my={4}>
      <HStack>
        <Text
          as="span"
          fontSize={{ base: "md", "2xl": "lg" }}
          fontWeight="semibold"
          color={`${theme}.700`}
        >
          {header}
        </Text>
      </HStack>
      {activities.map((a, index) => (
        <Card key={index}>
          <Accordion allowToggle>
            <AccordionItem>
              {() => (
                <>
                  <AccordionButton>
                    <CardHeader w="full">
                      <HStack w="full">
                        <HStack w="full" justifyContent={"space-between"}>
                          <HStack>
                            <Text
                              as="span"
                              fontSize={{ base: "md", "2xl": "lg" }}
                              color={`${theme}.800`}
                            >
                              {a.icon}
                            </Text>
                            <Text
                              as="span"
                              ml={4}
                              fontSize={{ base: "md", "2xl": "lg" }}
                              fontWeight={"bold"}
                              color={`${theme}.800`}
                            >
                              {a.header}
                            </Text>
                          </HStack>
                          {a.date && (
                            <Text
                              as="span"
                              fontSize={{ base: "md", "2xl": "lg" }}
                              fontWeight={"normal"}
                              color={`${theme}.800`}
                            >
                              {formatDate(new Date(a.date))}
                              {" by "}
                              {a.person}
                            </Text>
                          )}
                        </HStack>
                        <AccordionIcon
                          fontSize={{ base: "2xl", "2xl": "3xl" }}
                        />
                      </HStack>
                    </CardHeader>
                  </AccordionButton>
                  <AccordionPanel>
                    <CardBody>
                      <Text
                        as="div"
                        fontSize={{ base: "md", "2xl": "lg" }}
                        fontWeight={"normal"}
                        color={`${theme}.700`}
                      >
                        {a.content}
                      </Text>
                    </CardBody>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Card>
      ))}
    </VStack>
  );
};

export default NotesSection;
