import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "../constants";

export const clientRedirectAfterAuth = (
  navigate: NavigateFunction,
  clientId: string,
  redirectPath: string
) => {
  if (!redirectPath) {
    navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_HOME}`);
    return;
  }
  navigate(redirectPath);
};
