import { FC, useEffect, useRef, useState } from "react";
import {
  useToast,
  Spinner,
  Center,
  VStack,
  Divider,
  Box,
  Button,
  Text,
  Heading,
  Icon,
  FormLabel,
  FormControl,
  Input,
  Flex,
  Container,
  SimpleGrid,
} from "@chakra-ui/react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftMd, Camera } from "react-coolicons";
import { ROUTES } from "../../constants";
import { getMetricDefinitions } from "../../api/metricDefinition";
import { getPatientMetricConfigs } from "../../api/patientMetricConfig";
import { createAllPatientMetricsV2 } from "../../api/patientMetric";
import { PatientMetricV2Dto } from "../../types/patientMetric";
import { MetricDefinition } from "../../types/metricDefinition";
import { convDateTimeToISO } from "../../utils/date";
import { Logo } from "../../Logo";
import { InfoIcon } from "@chakra-ui/icons";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { extractImageData } from "../../api/llm";
import { convertMetricDetailTitleToVitalType } from "../../utils/metricDetail";
import {
  BloodPressureMeasurement,
  BloodSugarMeasurement,
  ConversionInfo,
  ImageExtractedMeasurement,
  VitalType,
  WeightMeasurement,
} from "../../types/llm";
import { useMetricDefinitions } from "../../hooks/useMetricDefinitions";

const ClientRecordMetrics: FC = () => {
  const toast = useToast();
  const { groupKey = "", clientId } = useParams<{
    groupKey: string;
    clientId: string;
  }>();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const [activeMetricDefinitions, setActiveMetricDefinitions] = useState<
    MetricDefinition[]
  >([]);
  const [activeMetricDefinitionIds, setActiveMetricDefinitionIds] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [metricValues, setMetricValues] = useState<Record<string, string>>({});
  const [recordedDate, setRecordedDate] = useState(new Date());

  // Image upload related states
  const [imageExtractionIsLoading, setImageExtractionIsLoading] =
    useState(false);
  const imageUploadRef = useRef<HTMLInputElement | null>(null);
  const [conversionInfo, setConversionInfo] = useState<ConversionInfo | null>(
    null
  );
  const { metricDefinitions } = useMetricDefinitions();
  useEffect(() => {
    const fetchMetricDefinitions = async () => {
      setLoading(true);
      try {
        // Filter by groupKey
        const definitions = metricDefinitions.filter(
          (def) => def.metadata.groupKey === groupKey
        );

        if (definitions.length === 0) {
          toast({
            title: "Group not found",
            description: "No metrics found for this group",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_HOME}`);
          return;
        }

        // Fetch active patient metric configs
        const metricDefinitionIds = definitions.map((def) => def.id);
        const configsResponse = await getPatientMetricConfigs(
          clientId as string,
          true, // Only fetch active configs
          metricDefinitionIds // Filter by metric definition IDs
        );

        if (!configsResponse.data) {
          toast({
            title: "Failed to fetch patient metric configurations",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        // Get the list of active metric definition IDs
        const activeIds = configsResponse.data.map(
          (config) => config.metricDefinitionId
        );
        setActiveMetricDefinitionIds(activeIds);

        // Check if there are any active metric configs for this group
        if (activeIds.length === 0) {
          toast({
            title: "No active metrics",
            description:
              "There are no active metrics configured for this group",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
          navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_HOME}`);
          return;
        }

        // Filter definitions to only include those with active configs
        const activeDefinitions = definitions.filter((def) =>
          activeIds.includes(def.id)
        );

        setActiveMetricDefinitions(activeDefinitions);

        // Initialize metric values
        const initialValues: Record<string, string> = {};
        activeDefinitions.forEach((def) => {
          initialValues[def.id] = "";
        });
        setMetricValues(initialValues);
      } catch (error) {
        console.error("Error fetching metric definitions:", error);
        toast({
          title: "Error",
          description: "Failed to load metric definitions",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    if (clientId && groupKey) {
      fetchMetricDefinitions();
    }
  }, [groupKey, clientId, toast, navigate, metricDefinitions]);

  useEffect(() => {
    // Set initial values if coming from image extraction
    const imageExtractedMeasurement =
      locationState?.imageExtractedMeasurement as ImageExtractedMeasurement;
    if (imageExtractedMeasurement && activeMetricDefinitions.length > 0) {
      setValuesFromImageExtractedMeasurement(imageExtractedMeasurement);
    }
  }, [locationState, activeMetricDefinitions]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleInputChange = (id: string, value: string) => {
    setMetricValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const showImageUploadButton = (): boolean => {
    if (activeMetricDefinitions.length === 0) return false;

    // For blood pressure group, always show image upload if we have active metrics for it
    if (groupKey === "blood_pressure") return true;

    // For single metric, check if it supports image upload
    if (activeMetricDefinitions.length === 1) {
      return !!convertMetricDetailTitleToVitalType(
        activeMetricDefinitions[0].displayName
      );
    }

    // For other groups, check if any active metric type supports image upload
    return activeMetricDefinitions.some(
      (def) => !!convertMetricDetailTitleToVitalType(def.displayName)
    );
  };

  const handleButtonClick = () => {
    imageUploadRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type.startsWith("image/")) {
        await handleImageUpload(file);
      } else {
        toast({
          title: "Invalid file type",
          description: "Please upload a valid image file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleImageUpload = async (file: File) => {
    if (activeMetricDefinitions.length === 0) return;

    try {
      setImageExtractionIsLoading(true);

      // Determine which vital type to use for extraction
      let vitalType: VitalType | undefined;

      // For blood pressure group, use blood pressure vital type
      if (groupKey === "blood_pressure") {
        vitalType = VitalType.BLOOD_PRESSURE;
      } else if (activeMetricDefinitions.length === 1) {
        // For single metric, use its vital type
        vitalType = convertMetricDetailTitleToVitalType(
          activeMetricDefinitions[0].displayName
        );
      } else {
        // For other groups, use the first metric's vital type
        vitalType = convertMetricDetailTitleToVitalType(
          activeMetricDefinitions[0].displayName
        );
      }

      if (!vitalType) {
        toast({
          title: "Unsupported measurement type",
          description: "This type of measurement doesn't support image upload.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const imageExtractedMeasurement = (await extractImageData(
        file,
        vitalType
      )) as ImageExtractedMeasurement;

      // For blood pressure specifically, check if we got the right type
      if (
        groupKey === "blood_pressure" &&
        imageExtractedMeasurement.measurement.type !== VitalType.BLOOD_PRESSURE
      ) {
        toast({
          title: "Unexpected measurement",
          description:
            "The image you have uploaded does not appear to be a blood pressure reading.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // For single metric, check if the extracted type matches
      if (
        activeMetricDefinitions.length === 1 &&
        vitalType !== imageExtractedMeasurement.measurement.type
      ) {
        toast({
          title: "Unexpected measurement",
          description:
            "The image you have uploaded does not match the type of measurement you selected.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      setValuesFromImageExtractedMeasurement(imageExtractedMeasurement);

      toast({
        title: "Scanning complete!",
        description:
          "Your readings were auto-filled. Please review before submitting.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "An error occurred",
        description:
          "Unable to process the image. Please fill in the values manually.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setImageExtractionIsLoading(false);
    }
  };

  const setValuesFromImageExtractedMeasurement = (
    imageExtractedMeasurement: ImageExtractedMeasurement
  ) => {
    // Store conversion info if exists
    setConversionInfo(imageExtractedMeasurement.conversion || null);

    // Create a new values object
    const newValues = { ...metricValues };

    switch (imageExtractedMeasurement.measurement.type) {
      case VitalType.BLOOD_PRESSURE:
        const bpMeasurement =
          imageExtractedMeasurement.measurement as BloodPressureMeasurement;

        // Find systolic, diastolic, and heart rate definitions
        const systolicDef = activeMetricDefinitions.find(
          (def) => def.key === "bp_systolic"
        );
        const diastolicDef = activeMetricDefinitions.find(
          (def) => def.key === "bp_diastolic"
        );
        const heartRateDef = activeMetricDefinitions.find(
          (def) => def.key === "heart_rate"
        );

        // Set values if definitions exist and corresponding values are present
        // We only need to check if the definition exists, since metricDefinitions now only contains active ones
        if (systolicDef && bpMeasurement.systolic !== undefined) {
          newValues[systolicDef.id] = bpMeasurement.systolic.toString();
        }
        if (diastolicDef && bpMeasurement.diastolic !== undefined) {
          newValues[diastolicDef.id] = bpMeasurement.diastolic.toString();
        }
        if (heartRateDef && bpMeasurement.heartRate !== undefined) {
          newValues[heartRateDef.id] = bpMeasurement.heartRate.toString();
        }

        // Show a more specific toast message if we detected partial information
        const hasSystolic =
          bpMeasurement.systolic !== undefined && systolicDef !== undefined;
        const hasDiastolic =
          bpMeasurement.diastolic !== undefined && diastolicDef !== undefined;

        if (hasSystolic && !hasDiastolic) {
          toast({
            title: "Partial reading detected",
            description:
              "We only detected the systolic value. Please enter the diastolic value manually.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (!hasSystolic && hasDiastolic) {
          toast({
            title: "Partial reading detected",
            description:
              "We only detected the diastolic value. Please enter the systolic value manually.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        }
        break;

      case VitalType.WEIGHT:
        const weightMeasurement =
          imageExtractedMeasurement.measurement as WeightMeasurement;
        const weightDef = activeMetricDefinitions.find(
          (def) => def.key === "weight"
        );
        if (weightDef && weightMeasurement.weight !== undefined) {
          newValues[weightDef.id] = weightMeasurement.weight.toString();
        }
        break;

      case VitalType.BLOOD_GLUCOSE:
        const bloodSugarMeasurement =
          imageExtractedMeasurement.measurement as BloodSugarMeasurement;
        const glucoseDef = activeMetricDefinitions.find(
          (def) => def.key === "blood_glucose"
        );
        if (glucoseDef && bloodSugarMeasurement.glucoseLevel !== undefined) {
          newValues[glucoseDef.id] =
            bloodSugarMeasurement.glucoseLevel.toString();
        }
        break;

      default:
        // For other types, no special handling
        break;
    }

    setMetricValues(newValues);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate inputs
    const hasEmptyRequiredValues = activeMetricDefinitions
      .filter((def) => def.validationRules.required)
      .some(
        (def) => !metricValues[def.id] || metricValues[def.id].trim() === ""
      );

    if (hasEmptyRequiredValues) {
      toast({
        title: "Missing required values",
        description: "Please fill in all required fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Create patient metrics using V2 Dto format (no propertyId)
    const patientMetrics: PatientMetricV2Dto[] = [];

    for (const def of activeMetricDefinitions) {
      const value = metricValues[def.id];
      if (value && value.trim() !== "") {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
          toast({
            title: "Invalid input",
            description: `Please enter a valid number for ${def.displayName}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        patientMetrics.push({
          id: "", // empty id for creating new patient metric
          patientId: clientId as string,
          metricDefinitionId: def.id, // Only metricDefinitionId is needed in V2
          value: parsedValue,
          timestamp: recordedDate.getTime().toString(),
          unit: def.unit,
        });
      }
    }

    if (patientMetrics.length === 0) {
      toast({
        title: "No data to save",
        description: "Please enter at least one measurement",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Use the V2 API to create metrics
      await createAllPatientMetricsV2(patientMetrics);
      toast({
        title: "Success",
        description: "Data saved successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_THANK_YOU}`, {
        state: { returnPath: -1 },
      });
    } catch (error: any) {
      toast({
        title: "Failed to save data",
        description: error?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getTitle = (): string => {
    if (!groupKey) return "Record Measurements";

    return (
      groupKey
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ") || "Record Measurements"
    );
  };

  const getSubtitle = (): string => {
    if (activeMetricDefinitions.length === 1) {
      return (
        activeMetricDefinitions[0].unitDescription ||
        activeMetricDefinitions[0].unit ||
        ""
      );
    }
    return "Record your measurements";
  };

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" color="blue.500" />
      </Center>
    );
  }

  return (
    <>
      {imageExtractionIsLoading && <LoadingOverlay />}

      <VStack spacing={0} minH="100vh" bg="gray.50" my={5}>
        <Logo />
        <Box w="full" px={4} py={3} position="relative">
          <Flex w="full" align="center" position="relative" minH="40px">
            <Box position="absolute" left={0} zIndex={1}>
              <Icon
                as={ArrowLeftMd}
                color="blue.900"
                fontSize="2xl"
                onClick={handleBackClick}
                cursor="pointer"
              />
            </Box>
            <Box w="full" textAlign="center">
              <Heading as="h1" size="lg">
                {getTitle()}
              </Heading>
              <Text fontSize="md" mt={1} color="gray.600">
                {getSubtitle()}
              </Text>
            </Box>
          </Flex>
        </Box>

        <Divider my={4} />

        <Container maxW="full" textAlign="left">
          <Box as="form" onSubmit={handleSubmit}>
            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={6} mb={6}>
              {activeMetricDefinitions.map((def) => (
                <FormControl
                  key={def.id}
                  isRequired={def.validationRules.required}
                  mb={0}
                >
                  <FormLabel>{def.displayName}</FormLabel>
                  <Input
                    type="number"
                    step={0.01}
                    min={def.validationRules.min}
                    max={def.validationRules.max}
                    value={metricValues[def.id] || ""}
                    onChange={(e) => handleInputChange(def.id, e.target.value)}
                    placeholder={`Enter ${def.displayName}`}
                  />
                  <Text fontSize="sm" color="gray.600" mt={1}>
                    {def.unit}
                  </Text>
                </FormControl>
              ))}
            </SimpleGrid>

            {conversionInfo && <ConversionInfoDisplay info={conversionInfo} />}

            <FormControl isRequired mb={6}>
              <FormLabel>Date & Time of recording</FormLabel>
              <Input
                width="full"
                type="datetime-local"
                onChange={(e) => {
                  setRecordedDate(new Date(e.target.value));
                }}
                value={convDateTimeToISO(recordedDate)}
              />
            </FormControl>

            <Divider mb={4} />

            {showImageUploadButton() && (
              <FormControl mb={4}>
                <FormLabel>Auto-fill from picture (optional)</FormLabel>
                <input
                  type="file"
                  id="imageUpload"
                  ref={imageUploadRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <Button
                  rightIcon={<Camera />}
                  onClick={handleButtonClick}
                  colorScheme="blue"
                  variant="outline"
                  fontSize="md"
                  size="lg"
                  width="full"
                >
                  {locationState?.imageExtractedMeasurement
                    ? "Take another photo"
                    : "Take photo to auto-fill"}
                </Button>
              </FormControl>
            )}

            <Button mt={4} colorScheme="blue" type="submit" width="full">
              Submit
            </Button>
          </Box>
        </Container>
      </VStack>
    </>
  );
};

const ConversionInfoDisplay: FC<{ info: ConversionInfo }> = ({ info }) => (
  <Box p={3} bg="blue.50" borderRadius="md" mb={4}>
    <Text fontSize="sm" color="blue.600">
      <Icon as={InfoIcon} mr={2} />
      We detected {info.originalValue}
      {info.originalUnit} and converted it to {info.convertedValue}
      {info.convertedUnit}
    </Text>
  </Box>
);

export default ClientRecordMetrics;
