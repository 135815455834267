import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { CreatePatientProtocolDto, Mode } from "../../../types/protocol";
import { FlagSeverity } from "../../../types/flag";
import {
  getPatientProtocols,
  createPatientProtocol,
  deletePatientProtocol,
  updatePatientProtocol,
} from "../../../api/protocol/patientProtocol";
import { ChevronDownIcon, DeleteIcon, InfoIcon } from "@chakra-ui/icons";
import { FlagIcon } from "@heroicons/react/24/outline";

interface FlagSectionProps {
  metricDefinitionId: string;
  metricDisplayName: string;
  patientId: string;
}

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
}: ConfirmationModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button variant="ghost" mr={3} onClick={onClose}>
          Cancel
        </Button>
        <Button colorScheme="blue" onClick={onConfirm}>
          Confirm
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const SeverityChip = ({
  severity,
  isSelected,
  onClick,
  isDisabled,
}: {
  severity: FlagSeverity;
  isSelected: boolean;
  onClick: () => void;
  isDisabled?: boolean;
}) => {
  const getChipStyle = (
    severity: FlagSeverity,
    isSelected: boolean,
    isDisabled?: boolean
  ) => {
    const baseStyle = {
      borderRadius: "md",
      fontSize: "sm",
      fontWeight: "medium",
      px: 2,
      cursor: isDisabled ? "not-allowed" : "pointer",
      bg: isDisabled ? "gray.100" : isSelected ? "red.100" : "white",
      color: isDisabled ? "gray.400" : isSelected ? "red.800" : "gray.500",
      borderColor: isDisabled ? "gray.50" : isSelected ? "red.200" : "gray.300",
    };

    const severityColors = {
      [FlagSeverity.CRITICAL]: "red",
      [FlagSeverity.WARN]: "orange",
      [FlagSeverity.INFO]: "gray",
    };
    if (!severityColors[severity]) {
      return baseStyle;
    }
    return {
      ...baseStyle,
      bg: isDisabled
        ? "gray.50"
        : isSelected
        ? `${severityColors[severity]}.100`
        : "white",
      color: isDisabled
        ? "gray.400"
        : isSelected
        ? `${severityColors[severity]}.800`
        : "gray.500",
      borderColor: isDisabled
        ? "gray.50"
        : isSelected
        ? `${severityColors[severity]}.200`
        : "gray.300",
      _hover: isDisabled
        ? {}
        : {
            bg: isSelected
              ? `${severityColors[severity]}.500`
              : `${severityColors[severity]}.300`,
            color: "white",
          },
    };
  };

  const style = getChipStyle(severity, isSelected, isDisabled);

  return (
    <Tooltip
      label={
        isDisabled
          ? "Cannot change severity for a disabled flag"
          : `Change flag severity to ${severity}`
      }
      hasArrow
      placement="top"
    >
      <Button
        size="sm"
        variant="outline"
        onClick={isDisabled ? undefined : onClick}
        {...style}
        opacity={isDisabled ? 0.6 : 1}
      >
        {severity === FlagSeverity.CRITICAL
          ? "Critical"
          : severity === FlagSeverity.WARN
          ? "Warning"
          : "Info"}
      </Button>
    </Tooltip>
  );
};

const EmptyState = () => (
  <VStack
    spacing={3}
    p={8}
    align="center"
    bg="gray.50"
    borderRadius="md"
    border="1px dashed"
    borderColor="gray.200"
    w="full"
    h="full"
  >
    <Box color="gray.400">
      <FlagIcon width="24px" height="24px" />
    </Box>
    <VStack spacing={1} w="full" align="center">
      <Text
        fontSize="sm"
        fontWeight="medium"
        color="gray.700"
        textAlign="center"
      >
        No flags configured yet
      </Text>
      <Text fontSize="xs" color="gray.500" textAlign="center" maxW="70%">
        Flags help you monitor your client's readings. Create flags to get
        alerts when readings are outside target ranges or when readings are
        missed.
      </Text>
    </VStack>
  </VStack>
);

export const FlagSection = ({
  metricDefinitionId,
  metricDisplayName,
  patientId,
}: FlagSectionProps) => {
  const [protocols, setProtocols] = useState<any[]>([]);
  const [selectedProtocol, setSelectedProtocol] = useState<any>(null);
  const [selectedSeverity, setSelectedSeverity] = useState<FlagSeverity | null>(
    null
  );
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const {
    isOpen: isSeverityModalOpen,
    onOpen: onSeverityModalOpen,
    onClose: onSeverityModalClose,
  } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    fetchProtocols();
  }, [patientId, metricDefinitionId]);

  const fetchProtocols = async () => {
    try {
      const response = await getPatientProtocols(patientId);
      if (response.data) {
        const relevantProtocols = response.data.filter((protocol) =>
          protocol.conditions.conditions.some(
            (condition: any) =>
              condition.metricDefinitionId === metricDefinitionId
          )
        );
        setProtocols(relevantProtocols);
      }
    } catch (error) {
      console.error("Error fetching protocols:", error);
    }
  };

  const handleAddFlag = async (mode: Mode) => {
    try {
      const newProtocol: CreatePatientProtocolDto = {
        name: `${metricDisplayName} ${mode} Alert`,
        description: `Alert for ${mode.toLowerCase()} condition`,
        conditions: {
          type: "AND",
          conditions: [
            {
              metricDefinitionId,
              mode,
            },
          ],
        },
        flagSeverity: FlagSeverity.INFO,
        patientId,
      };

      await createPatientProtocol(newProtocol);
      fetchProtocols();
      toast({
        title: "Flag created",
        description: `${getModeDisplay(
          mode
        )} flag has been created successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error creating protocol:", error);
      toast({
        title: "Error creating flag",
        description: "Unable to create the flag. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteConfirm = async () => {
    if (!selectedProtocol) return;
    try {
      await deletePatientProtocol(selectedProtocol.id);
      const modeName = getModeDisplay(
        selectedProtocol.conditions.conditions[0].mode
      );
      fetchProtocols();
      onDeleteModalClose();
      toast({
        title: "Flag deleted",
        description: `${modeName} flag has been removed successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting protocol:", error);
      onDeleteModalClose();

      const errorMessage =
        error instanceof Error
          ? error.message
          : "Unable to delete the flag. Please try again.";
      toast({
        title: "Error deleting flag",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSeverityConfirm = async () => {
    if (!selectedProtocol || !selectedSeverity) return;
    try {
      const updatedProtocol = {
        ...selectedProtocol,
        flagSeverity: selectedSeverity,
      };
      await updatePatientProtocol(selectedProtocol.id, updatedProtocol);
      fetchProtocols();
      onSeverityModalClose();

      const modeName = getModeDisplay(
        selectedProtocol.conditions.conditions[0].mode
      );
      const severityName =
        selectedSeverity === FlagSeverity.CRITICAL
          ? "Critical"
          : selectedSeverity === FlagSeverity.WARN
          ? "Warning"
          : "Info";

      toast({
        title: "Severity updated",
        description: `${modeName} flag severity has been changed to ${severityName}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating protocol severity:", error);
      onSeverityModalClose();

      const errorMessage =
        error instanceof Error
          ? error.message
          : "Unable to update the flag severity. Please try again.";
      toast({
        title: "Error updating severity",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getModeDisplay = (mode: Mode) => {
    switch (mode) {
      case Mode.ABOVE_NORMAL:
        return "Above Target Range";
      case Mode.BELOW_NORMAL:
        return "Below Target Range";
      case Mode.NON_ADHERENCE:
        return "Non-Adherence";
      default:
        return mode;
    }
  };

  const getModeDescription = (mode: Mode) => {
    switch (mode) {
      case Mode.ABOVE_NORMAL:
        return "Alert when reading is above your set target";
      case Mode.BELOW_NORMAL:
        return "Alert when reading is below your set target";
      case Mode.NON_ADHERENCE:
        return "Alert when reading is not recorded per intended schedule";
      default:
        return "";
    }
  };

  const existingModes = protocols.map(
    (protocol) => protocol.conditions.conditions[0].mode
  );

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <HStack justify="space-between">
          <HStack spacing={2}>
            <Box color={"blue.900"}>
              <FlagIcon width="16px" height="16px" />
            </Box>
            <Text fontSize="lg" fontWeight="medium" color="blue.900">
              Flags
            </Text>
          </HStack>

          <Popover placement="bottom-end">
            <PopoverTrigger>
              <Button
                size="sm"
                variant="solid"
                rightIcon={<ChevronDownIcon />}
                isDisabled={existingModes.length === Object.keys(Mode).length}
              >
                Add Flag
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                w="350px"
                boxShadow="lg"
                border="1px solid"
                borderColor="gray.200"
                _focus={{ outline: "none" }}
              >
                <PopoverBody p={0}>
                  <VStack spacing={0} align="stretch" py={2}>
                    {Object.values(Mode).map((mode) => {
                      const isExisting = existingModes.includes(mode);
                      return (
                        <Box
                          key={mode}
                          as="button"
                          onClick={() => !isExisting && handleAddFlag(mode)}
                          py={2}
                          px={4}
                          _hover={!isExisting ? { bg: "gray.50" } : {}}
                          cursor={isExisting ? "default" : "pointer"}
                          opacity={isExisting ? 0.5 : 1}
                          position="relative"
                        >
                          <HStack spacing={3} align="start">
                            <Box color="blue.600" mt={1}>
                              <FlagIcon width="16px" height="16px" />
                            </Box>
                            <VStack align="start" spacing={0}>
                              <Text
                                color="gray.900"
                                fontSize="sm"
                                fontWeight="medium"
                                textAlign="left"
                              >
                                {getModeDisplay(mode)}
                              </Text>
                              <Text
                                color="gray.500"
                                fontSize="xs"
                                textAlign="left"
                              >
                                {getModeDescription(mode)}
                              </Text>
                            </VStack>
                          </HStack>
                        </Box>
                      );
                    })}
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </HStack>

        {protocols.length === 0 ? (
          <EmptyState />
        ) : (
          <VStack spacing={4} align="stretch">
            {protocols.map((protocol) => {
              const isDisabled = !!protocol.disabledReason;
              return (
                <Tooltip
                  key={protocol.id}
                  label={protocol.disabledReason || ""}
                  isDisabled={!isDisabled}
                  hasArrow
                  placement="top"
                  bg="gray.700"
                >
                  <VStack
                    align="stretch"
                    spacing={2}
                    opacity={isDisabled ? 0.7 : 1}
                    bg={isDisabled ? "gray.50" : "white"}
                    p={3}
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor={isDisabled ? "gray.200" : "transparent"}
                    position="relative"
                  >
                    {isDisabled && (
                      <Badge
                        position="absolute"
                        top={2}
                        right={2}
                        colorScheme="gray"
                        fontSize="xs"
                        display="flex"
                        alignItems="center"
                        gap={1}
                      >
                        <InfoIcon boxSize={3} />
                        Disabled
                      </Badge>
                    )}
                    <HStack justify="space-between" align="center">
                      <VStack align="start" spacing={0}>
                        <Text
                          fontSize="sm"
                          fontWeight="medium"
                          color={isDisabled ? "gray.500" : "blue.900"}
                        >
                          {getModeDisplay(
                            protocol.conditions.conditions[0].mode
                          )}
                        </Text>
                        <Text
                          fontSize="xs"
                          color={isDisabled ? "gray.400" : "gray.500"}
                        >
                          {getModeDescription(
                            protocol.conditions.conditions[0].mode
                          )}
                        </Text>
                        {isDisabled && (
                          <Text
                            fontSize="xs"
                            color="gray.500"
                            mt={1}
                            fontStyle="italic"
                          >
                            {protocol.disabledReason}
                          </Text>
                        )}
                      </VStack>

                      <HStack spacing={2}>
                        {[
                          FlagSeverity.CRITICAL,
                          FlagSeverity.WARN,
                          FlagSeverity.INFO,
                        ].map((severity) => (
                          <SeverityChip
                            key={severity}
                            severity={severity}
                            isSelected={protocol.flagSeverity === severity}
                            isDisabled={isDisabled}
                            onClick={() => {
                              if (
                                !isDisabled &&
                                protocol.flagSeverity !== severity
                              ) {
                                setSelectedProtocol(protocol);
                                setSelectedSeverity(severity);
                                onSeverityModalOpen();
                              }
                            }}
                          />
                        ))}
                        <Tooltip
                          label={
                            isDisabled
                              ? "Cannot delete a disabled flag"
                              : "Delete flag"
                          }
                          hasArrow
                          placement="top"
                        >
                          <IconButton
                            aria-label="Delete flag"
                            icon={<DeleteIcon />}
                            variant="ghost"
                            color={isDisabled ? "gray.300" : "gray.400"}
                            size="sm"
                            isDisabled={isDisabled}
                            _hover={isDisabled ? {} : { color: "gray.900" }}
                            onClick={() => {
                              if (!isDisabled) {
                                setSelectedProtocol(protocol);
                                onDeleteModalOpen();
                              }
                            }}
                          />
                        </Tooltip>
                      </HStack>
                    </HStack>
                  </VStack>
                </Tooltip>
              );
            })}
          </VStack>
        )}
      </VStack>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        onConfirm={handleDeleteConfirm}
        title="Delete Flag"
        message={`Are you sure you want to delete the ${
          selectedProtocol
            ? getModeDisplay(selectedProtocol.conditions.conditions[0].mode)
            : ""
        } flag?`}
      />

      <ConfirmationModal
        isOpen={isSeverityModalOpen}
        onClose={onSeverityModalClose}
        onConfirm={handleSeverityConfirm}
        title="Change Severity"
        message={`Are you sure you want to change the severity of ${
          selectedProtocol
            ? getModeDisplay(selectedProtocol.conditions.conditions[0].mode)
            : ""
        } flag to ${
          selectedSeverity === FlagSeverity.CRITICAL
            ? "Critical"
            : selectedSeverity === FlagSeverity.WARN
            ? "Warning"
            : "Info"
        }?`}
      />
    </Box>
  );
};
