import {
  Container,
  Heading,
  Text,
  Checkbox,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllFlagsForOrganization, updateFlag } from "../../api/flag";
import { FlagWithPatient, FlagStatus, FlagTrigger } from "../../types/flag";
import { ROUTES, theme } from "../../constants";
import { useStaffStore } from "../../store/staffStore";
import TablePagination from "../../components/TablePagination";
import {
  SharedTable,
  TableBodyGrid,
  TableBodyGridItem,
  SortConfig,
} from "../../components/SharedTable";
import {
  getSeverityColor,
  isResolved,
  renderSeverityBadge,
  renderTimeColumn,
  sortFlags,
} from "../../components/Flags/utils";

type SortColumn = "severity" | "clientName" | "time" | "default";

const GRID_TEMPLATE_COLUMNS = "1fr 1fr 1.4fr minmax(0, 2.2fr) 1.4fr 1fr";

export default function FlagsPage() {
  const [loading, setLoading] = useState(true);

  const [sorting, setSorting] = useState<SortConfig<SortColumn>>({
    column: "default",
    direction: "desc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();
  const currentStaff = useStaffStore((state) => state.currentStaff);
  const toast = useToast();

  /*
   * OPEN flags appear before RESOLVED flags  by default, when it is initially loaded
   * Flags can be sorted by date, client name, and severity
   * When a flag status changes (reopened or resolved), it stays in its current position (row stability)
   *
   * Hence when we first load the flags, we need to set a stable order
   */
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [flags, setFlags] = useState<FlagWithPatient[]>([]);

  useEffect(() => {
    fetchFlags();
  }, [currentStaff?.organizationId]);

  const fetchFlags = async () => {
    if (!currentStaff?.organizationId) return;

    try {
      setLoading(true);
      const response = await getAllFlagsForOrganization(
        currentStaff.organizationId
      );
      if (response.data) {
        // Only set the stable order on initial load
        if (!initiallyLoaded) {
          const sortedFlags = sortFlags(response.data, "default", "desc");
          setFlags(sortedFlags);
          setInitiallyLoaded(true);
        } else {
          setFlags(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching flags:", error);
    } finally {
      setLoading(false);
    }
  };

  // When sorting changes, update the stable order
  useEffect(() => {
    if (flags.length > 0 && initiallyLoaded) {
      // Update the stable order when sort criteria changes
      const sortedFlags = sortFlags(
        [...flags], // Create a new array to preserve the original
        sorting.column,
        sorting.direction
      );
      setFlags(sortedFlags);
      setCurrentPage(1); // Reset to first page when sorting changes
    }
  }, [sorting.column, sorting.direction]);

  // Get paginated data from stableFlags
  const getPaginatedFlags = () => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return flags.slice(start, end);
  };

  const paginatedFlags = getPaginatedFlags();
  const totalFlags = flags.length;

  const handleStatusChange = async (flag: FlagWithPatient) => {
    try {
      const newStatus = isResolved(flag.status)
        ? FlagStatus.REOPENED
        : FlagStatus.RESOLVED;

      const { id, trigger, severity } = flag;
      const response = await updateFlag(id, {
        id,
        trigger,
        severity,
        status: newStatus,
      });

      if (response.data) {
        // Only update stableFlags, no need for redundant setFlags
        setFlags((prevFlags) =>
          prevFlags.map((f) =>
            f.id === flag.id ? { ...f, status: newStatus } : f
          )
        );

        toast({
          title: `Flag ${newStatus.toLowerCase()}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating flag status:", error);
      toast({
        title: "Failed to update flag status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const viewPatient = (patientId: string) => {
    navigate(`${ROUTES.CLIENTS}/${patientId}`);
  };

  const handleSort = (column: SortColumn) => {
    setSorting((prev) => ({
      column,
      direction:
        prev.column === column && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const flagColumns = [
    { id: "status" as SortColumn, label: "Status", isSortable: false },
    { id: "severity" as SortColumn, label: "Severity", isSortable: true },
    { id: "clientName" as SortColumn, label: "Client Name", isSortable: true },
    { id: "details" as SortColumn, label: "Flag Details", isSortable: false },
    { id: "time" as SortColumn, label: "Time", isSortable: true },
    { id: "action" as SortColumn, label: "Action", isSortable: false },
  ];

  return (
    <>
      <Container maxW="9xl" py={6}>
        <VStack align="stretch" spacing={2} mb={6}>
          <Heading fontSize="4xl" textTransform="uppercase">
            Flags
          </Heading>
          <Text color="gray.600" fontSize="md">
            View all active flags and resolved flags from the last 7 days across
            your clients
          </Text>
        </VStack>

        <SharedTable
          columns={flagColumns}
          gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
          sorting={sorting}
          onSort={handleSort}
        >
          {paginatedFlags.map((flag) => (
            <TableBodyGrid
              key={flag.id}
              gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
              opacity={isResolved(flag.status) ? 0.6 : 1}
              textDecoration={isResolved(flag.status) ? "line-through" : "none"}
            >
              <TableBodyGridItem>
                <Checkbox
                  isChecked={isResolved(flag.status)}
                  colorScheme={theme}
                  size="lg"
                  onChange={() => handleStatusChange(flag)}
                />
              </TableBodyGridItem>

              <TableBodyGridItem>
                {renderSeverityBadge(flag.severity)}
              </TableBodyGridItem>

              <TableBodyGridItem>
                <Text fontSize="sm">{flag.patientName}</Text>
              </TableBodyGridItem>

              <TableBodyGridItem>
                <Text
                  fontSize="sm"
                  color={`${getSeverityColor(flag.severity)}.600`}
                >
                  {flag.metricDisplayName}
                  {flag.trigger === FlagTrigger.METRIC ? (
                    <Text as="span" ml={1}>
                      ({flag.metricValue} {flag.metricUnit})
                    </Text>
                  ) : flag.trigger === FlagTrigger.NON_ADHERENCE &&
                    flag.daysSinceSubmission ? (
                    <Text as="span" ml={1}>
                      ({flag.daysSinceSubmission} days non-adherent)
                    </Text>
                  ) : (
                    <Text as="span" ml={1}>
                      (Non-adherence, no submissions yet)
                    </Text>
                  )}
                </Text>
              </TableBodyGridItem>

              <TableBodyGridItem>
                {renderTimeColumn(flag.createdAt)}
              </TableBodyGridItem>

              <TableBodyGridItem>
                <Text
                  fontSize="sm"
                  color={`${theme}.600`}
                  fontWeight="medium"
                  cursor="pointer"
                  _hover={{
                    color: `${theme}.700`,
                    textDecoration: "underline",
                  }}
                  onClick={() => viewPatient(flag.patientId)}
                >
                  View Profile
                </Text>
              </TableBodyGridItem>
            </TableBodyGrid>
          ))}

          <TablePagination
            currentPage={currentPage}
            totalRows={totalFlags}
            currentPageRows={paginatedFlags.length}
            onPageChange={setCurrentPage}
            rowsPerPage={itemsPerPage}
            onChangeRowsPerPage={setItemsPerPage}
          />
        </SharedTable>
      </Container>
    </>
  );
}
