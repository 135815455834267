import { Box, VStack, Text, HStack, Icon } from "@chakra-ui/react";
import { ChartBarIcon, FlagIcon } from "@heroicons/react/24/outline";
import { PatientMetricConfig } from "../../../types/patientMetricConfig";
import { MetricDefinition } from "../../../types/metricDefinition";
import { GetPatientProtocolDto } from "../../../types/protocol";

interface TrackerListProps {
  metricDefinitions: MetricDefinition[];
  configs: PatientMetricConfig[];
  protocols: GetPatientProtocolDto[];
  onSelectMetric: (metricId: string) => void;
  selectedMetricId?: string;
}

export const TrackerList = ({
  metricDefinitions,
  configs,
  protocols,
  onSelectMetric,
  selectedMetricId,
}: TrackerListProps) => {
  const getProtocolCount = (metricId: string): number => {
    return protocols.filter((protocol) =>
      protocol.conditions.conditions.some(
        (condition: any) => condition.metricDefinitionId === metricId
      )
    ).length;
  };

  const getConfig = (metricId: string): PatientMetricConfig | undefined => {
    return configs.find((c) => c.metricDefinitionId === metricId);
  };

  return (
    <VStack spacing={4} align="stretch">
      {metricDefinitions.map((metric) => {
        const config = getConfig(metric.id);
        const protocolCount = getProtocolCount(metric.id);

        return (
          <Box
            key={metric.id}
            px={4}
            py={3}
            bg="white"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => onSelectMetric(metric.id)}
            borderWidth={selectedMetricId === metric.id ? "2px" : "1px"}
            borderColor={
              selectedMetricId === metric.id ? "blue.500" : "gray.200"
            }
            _hover={{
              borderColor: "blue.500",
            }}
            position="relative"
          >
            <HStack spacing={3}>
              <Icon
                as={ChartBarIcon}
                boxSize={5}
                color={config?.isActive ? "blue.500" : "gray.400"}
              />
              <VStack align="start" spacing={0}>
                <Text fontWeight="medium" color="gray.700" fontSize="sm">
                  {metric.displayName}
                </Text>
                <Text
                  fontSize="xs"
                  color={config?.isActive ? "green.500" : "gray.400"}
                >
                  {config
                    ? config.isActive
                      ? "Actively Monitoring"
                      : "Not Monitoring"
                    : "Not Monitoring"}
                </Text>
              </VStack>
            </HStack>
            {protocolCount > 0 && (
              <HStack
                position="absolute"
                bottom={3}
                right={4}
                spacing={1}
                color="gray.500"
              >
                <Text fontSize="xs">{protocolCount}</Text>
                <Icon as={FlagIcon} boxSize={3} />
              </HStack>
            )}
          </Box>
        );
      })}
    </VStack>
  );
};
