import {
  Avatar,
  Box,
  BoxProps,
  Flex,
  Text,
  VStack,
  Tag,
  TagProps,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useStaffStore } from "../../store/staffStore";
import NavItem from "./NavItem";
import {
  AiOutlineForm,
  AiOutlineSync,
  AiOutlineTeam,
  AiOutlineSetting,
  AiOutlineUnlock,
  AiOutlineLogout,
  AiOutlineFlag,
  AiOutlineMessage,
  AiOutlineExperiment,
  AiOutlineInfo,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { StaffAuthLevel } from "../../types/staff";
import { SANDBOX_BANNER_HEIGHT } from "../SandboxBanner";
import { ReactComponent as SpeedbackLogo } from "../../assets/speedback_logo_only.svg";

import { IS_SANDBOX } from "../../utils/constants";
import { useStaffFeatureEnabled } from "../../hooks/useFeatureEnabled";
import { FeatureName } from "../FeatureToggle/constants";

// Types
interface LinkItemProps {
  readonly name: string;
  readonly path: string;
  readonly icon: IconType;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

// Constants
const LAYOUT = {
  COLLAPSED_WIDTH: "72px",
  EXPANDED_WIDTH: "240px",
  SIDE_PADDING: "16px",
  GROUP_SPACING: "32px",
} as const;

const BASE_NAVIGATION_ITEMS: ReadonlyArray<LinkItemProps> = [
  {
    name: "Chats",
    path: ROUTES.CHATS,
    icon: AiOutlineMessage,
  },
  { name: "Flags", path: ROUTES.FLAGS, icon: AiOutlineFlag },
  { name: "Clients", path: ROUTES.CLIENTS, icon: AiOutlineTeam },
  { name: "Forms", path: ROUTES.FORMS_LIST, icon: AiOutlineForm },
  { name: "Protocols", path: ROUTES.PROTOCOLS, icon: AiOutlineSync },
];

const LABS_NAVIGATION_ITEM: LinkItemProps = {
  name: "Labs",
  path: ROUTES.LABS,
  icon: AiOutlineExperiment,
};

// Get navigation items based on internal access
const getNavigationItems = (isLabsEnabled: boolean) => {
  const items = [];

  const [flags, clients, forms, protocols] = BASE_NAVIGATION_ITEMS;
  items.push(flags, clients, forms, protocols);

  if (isLabsEnabled) items.push(LABS_NAVIGATION_ITEM);

  return items;
};

// Component-specific styles
const internalTagStyles: TagProps = {
  size: "xs",
  ml: 3,
  px: 2,
  py: "2px",
  height: "18px",
  bg: "blue.50",
  color: "blue.700",
  fontSize: "10px",
  fontWeight: "medium",
  textTransform: "uppercase",
  letterSpacing: "0.5px",
  borderRadius: "4px",
  className: "nav-text",
  display: "flex",
  alignItems: "center",
};

const ProfileSection = ({ name, email }: { name: string; email: string }) => (
  <Box px="10px">
    <Flex alignItems="center" width="full">
      <Avatar size="sm" name={name} boxSize="24px" />
      <Box className="profile-section" ml={3} opacity={0}>
        <Text fontSize="14px" fontWeight="medium" noOfLines={1}>
          {name}
        </Text>
        <Text fontSize="12px" color="gray.500" noOfLines={1}>
          {email}
        </Text>
      </Box>
    </Flex>
  </Box>
);

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { currentStaff, logout } = useStaffStore();
  const isAdmin = currentStaff?.authLevel === StaffAuthLevel.ADMIN;
  const { enabled: isLabsEnabled } = useStaffFeatureEnabled(FeatureName.LABS);

  const sidebarHeight = IS_SANDBOX
    ? `calc(100vh - ${SANDBOX_BANNER_HEIGHT})`
    : "100vh";

  const navigationItems = getNavigationItems(isLabsEnabled);

  return (
    <Box
      bg="white"
      borderRight="1px"
      borderRightColor="gray.200"
      w={LAYOUT.COLLAPSED_WIDTH}
      pos="fixed"
      h={sidebarHeight}
      transition="all 0.2s ease"
      role="group"
      zIndex={2}
      _hover={{
        width: LAYOUT.EXPANDED_WIDTH,
        "& .nav-text": { opacity: 1 },
        "& .profile-section": { opacity: 1 },
        boxShadow: "lg",
      }}
      {...rest}
    >
      <Flex direction="column" h="full" py={8}>
        {/* Logo and Organization Section */}
        <Box position="relative" mb={16}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            transition="all 0.2s"
          >
            <SpeedbackLogo height="28px" width="28px" />
          </Box>
          <Box
            className="profile-section"
            opacity={0}
            position="absolute"
            top="calc(100% + 8px)"
            left={0}
            right={0}
            px={4}
            pt={2}
          >
            <Text
              fontSize="13px"
              color="blue.600"
              fontWeight="medium"
              textAlign="center"
              noOfLines={2}
              lineHeight="1.4"
            >
              {currentStaff?.organizationName}
            </Text>
          </Box>
        </Box>

        {/* Navigation Items */}
        <VStack spacing={4} align="stretch" px={LAYOUT.SIDE_PADDING}>
          {navigationItems.map((link) => (
            <Link to={link.path} key={link.name} onClick={onClose}>
              <NavItem icon={link.icon} path={link.path}>
                {link.name}
              </NavItem>
            </Link>
          ))}
        </VStack>

        {/* Profile & Admin Section */}
        <VStack spacing={4} align="stretch" mt="auto" px={LAYOUT.SIDE_PADDING}>
          <ProfileSection
            name={currentStaff?.name || ""}
            email={currentStaff?.email || ""}
          />

          <Link to={ROUTES.SETTINGS} key={"Settings"} onClick={onClose}>
            <NavItem icon={AiOutlineSetting} path={ROUTES.SETTINGS}>
              Settings
            </NavItem>
          </Link>

          <Link to={"https://www.spbk.co/"} target="_blank">
            <NavItem icon={AiOutlineInfoCircle} path={"https://www.spbk.co/"}>
              Info
            </NavItem>
          </Link>

          {isAdmin && (
            <Link to={ROUTES.ADMIN} onClick={onClose}>
              <NavItem icon={AiOutlineUnlock} path={ROUTES.ADMIN}>
                <Flex align="center" width="full">
                  <Text>Admin</Text>
                  <Tag {...internalTagStyles}>Internal</Tag>
                </Flex>
              </NavItem>
            </Link>
          )}

          <NavItem icon={AiOutlineLogout} onClick={() => logout()}>
            Logout
          </NavItem>
        </VStack>
      </Flex>
    </Box>
  );
};

export default SidebarContent;
