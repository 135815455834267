import { Box, HStack, VStack, Text, Divider } from "@chakra-ui/react";
import { PatientMetricConfig } from "../../../types/patientMetricConfig";
import { ConfigurationSection } from "./ConfigurationSection";
import { FlagSection } from "./FlagSection";
import { ReactComponent as Illustration1 } from "../../../assets/illustration_1.svg";
import { theme } from "../../../constants";
import { MetricDefinition } from "../../../types/metricDefinition";
import { FlagIcon } from "@heroicons/react/24/outline";

const EmptyFlagState = () => (
  <VStack spacing={4} align="stretch">
    <HStack spacing={2}>
      <Box color={"blue.900"}>
        <FlagIcon width="16px" height="16px" />
      </Box>
      <Text fontSize="lg" fontWeight="medium" color="blue.900">
        Flags
      </Text>
    </HStack>

    <VStack
      spacing={3}
      p={8}
      align="center"
      bg="gray.50"
      borderRadius="md"
      border="1px dashed"
      borderColor="gray.200"
      w="full"
    >
      <Box color="gray.400">
        <FlagIcon width="24px" height="24px" />
      </Box>
      <VStack spacing={1} w="full" align="center">
        <Text
          fontSize="sm"
          fontWeight="medium"
          color="gray.700"
          textAlign="center"
        >
          Add Configuration First
        </Text>
        <Text fontSize="xs" color="gray.500" textAlign="center" maxW="70%">
          You need to add and save a configuration before you can set up flags
          for this metric.
        </Text>
      </VStack>
    </VStack>
  </VStack>
);

interface SettingsPanelProps {
  selectedMetricId?: string;
  selectedMetric?: MetricDefinition;
  config?: PatientMetricConfig;
  patientId: string;
  onUpdateConfig: (config: PatientMetricConfig) => void;
  onCreateConfig: (
    metricDefinitionId: string,
    config: Omit<
      PatientMetricConfig,
      | "id"
      | "metricDefinitionId"
      | "createdBy"
      | "updatedBy"
      | "metricDisplayName"
    >
  ) => void;
}

export const SettingsPanel = ({
  selectedMetricId,
  selectedMetric,
  config,
  patientId,
  onUpdateConfig,
  onCreateConfig,
}: SettingsPanelProps) => {
  if (!selectedMetric) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        h="full"
        gap={4}
      >
        <Illustration1 width="200px" height="200px" />
        <VStack spacing={1}>
          <Text fontSize="lg" fontWeight="medium" color={`${theme}.900`}>
            Click on any tab under "Trackers"
          </Text>
          <Text fontSize="md" color="gray.500">
            You'll get to make further customisations!
          </Text>
        </VStack>
      </Box>
    );
  }

  return (
    <VStack spacing={4} align="stretch">
      <ConfigurationSection
        metricDefinition={selectedMetric}
        config={config}
        patientId={patientId}
        onUpdateConfig={onUpdateConfig}
        onCreateConfig={onCreateConfig}
      />
      {config ? (
        <>
          <Divider />
          <FlagSection
            metricDefinitionId={config.metricDefinitionId}
            metricDisplayName={config.metricDisplayName}
            patientId={config.patientId}
          />
        </>
      ) : (
        <>
          <Divider />
          <EmptyFlagState />
        </>
      )}
    </VStack>
  );
};
