import staffAuth from "./axios/staffAuth";
import { PatientMetricConfig } from "../types/patientMetricConfig";
import { ApiResponse } from "../types/api";
import { getCommonAuth } from "./axios/commonAuth";

const API_URL = process.env.REACT_APP_API_URL || "";

export const getPatientMetricConfigs = async (
  patientId: string,
  isActive?: boolean,
  metricDefinitionIds?: string[]
): Promise<ApiResponse<PatientMetricConfig[]>> => {
  const axios = getCommonAuth();
  return axios.get(`${API_URL}/patient/metric-config/list`, {
    params: { patientId, isActive, metricDefinitionIds },
  });
};

export const getPatientMetricConfig = async (
  configId: string
): Promise<ApiResponse<PatientMetricConfig>> => {
  const axios = getCommonAuth();
  return axios.get(`${API_URL}/patient/metric-config/${configId}`);
};

export const createPatientMetricConfig = async (
  patientId: string,
  metricDefinitionId: string
): Promise<ApiResponse<PatientMetricConfig>> => {
  return staffAuth.post(`${API_URL}/patient/metric-config/create`, {
    patientId,
    metricDefinitionId,
  });
};

export const createAllPatientMetricConfigs = async (
  patientId: string
): Promise<ApiResponse<PatientMetricConfig[]>> => {
  return staffAuth.post(`${API_URL}/patient/metric-config/create-all`, {
    patientId,
  });
};

export const updatePatientMetricConfig = async (
  configId: string,
  updatedConfig: PatientMetricConfig
): Promise<ApiResponse<PatientMetricConfig>> => {
  return staffAuth.put(
    `${API_URL}/patient/metric-config/${configId}`,
    updatedConfig
  );
};
